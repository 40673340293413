import axios from 'axios';

const api = axios.create({
    baseURL: 'https://kreedabackend.vercel.app',
    // baseURL: 'http://localhost:8080',
});

api.interceptors.request.use(
    async config => {
        try {
            const token = localStorage.getItem("userToken");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            console.error('Failed to retrieve token:', error);
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

export default api;
