import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

function Cancellationandreturn() {
  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);

  return (
    <div>
      <Navbar city={city} setcity={setcity} />
      <br />
      <div className="policy-container">
        <h1>Cancellation and Return Policies</h1>

        {/* Users Section */}
        <div className="policy-section">
          <h2>For Users Booking Turf Slots:</h2>

          <div className="policy-item">
            <h3>1. Cancellation Time Frame:</h3>
            <ul className='alphabet-list'>
              <li>a.  If a user cancels their booking before 6 hours of the booked slot, a 100% refund will be initiated.              </li>
              <li>b.  If a user cancels their booking within a 6-hour to 4-hour time gap before the booked slot, only 70% of the refund will be awarded.</li>
              <li>c.  If a user cancels their booking within a 4-hour to 2-hour time gap before the booked slot, only 50% of the refund will be awarded.              </li>
              <li>d.  No refund will be provided if a user cancels their booking within 2 hours or less before the booked slot.              </li>
            </ul>
          </div>

          <div className="policy-item" >
            <h3>2. Convenience Fee:</h3>
            <ul>
              <li>
                a.   The convenience fee is non-refundable for any cancellation
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h3>3. Process for Cancellation:</h3>
            <ol>
              <li>a. Users must log in to their account and navigate to the 'Booking History' section.              </li>
              <li>b.  Select the booking and click on the 'Cancel' button.              </li>
              <li>c.  Follow the instructions to initiate the cancellation process.              </li>
            </ol>
          </div>

          <div className="policy-item">
            <h3>4. Refund Process:</h3>
            <ul>
              <li>
                a.  Refunds will be processed to the original payment method within 4-5 business days.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h3>5. Contact Information:</h3>
            <ul>
              <li>
                a.   For any assistance with cancellations, users can contact our customer service team.
              </li>
            </ul>
            <p></p>
          </div>
        </div>

        {/* Owners Section */}
        <div className="policy-section">
          <h2>For Turf Owners:</h2>

          <div className="policy-item">
            <h3>1. Cancellation Time Frame:</h3>
            <ul className='alphabet-list'>
              <li>a.  <strong> Before 6 hours: </strong> If the owner cancels the booking before 6 hours of the scheduled slot, no charges will be applied.              </li>
              <li>b.  <strong> 6 to 4 hours before:</strong>  If the owner cancels the booking between 6 to 4 hours before the scheduled slot, the convenience fee will be deducted from the owner's Buffer Wallet.              </li>
              <li>c.  <strong> 4 to 2 hours before: </strong>  If the owner cancels the booking between 4 to 2 hours before the scheduled slot, the convenience fee along with 2% of the total refund amount requested will be deducted from the owner's Buffer Wallet.</li>
              <li>d.  <strong> Within 2 hours: </strong>  If the owner cancels the booking within 2 hours of the scheduled slot, the convenience fee along with 5% of the total refund amount requested will be deducted from the owner's Buffer Wallet.</li>
            </ul>
          </div>

          <div className="policy-item">
            <h3>2. Convenience Fee:</h3>
            <ul>
              <li>
                a.   The convenience fee is non-refundable for any cancellation initiated by the owner, except when the cancellation occurs before 6 hours of the scheduled slot.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h3>3. Self-Bookings:</h3>
            <ul>
              <li>
                a.   Turf owners can also book their own slots. The aforementioned time frames and policies apply to self-booked slots as well.
              </li>
            </ul>
          </div>

          <div className="policy-item">
            <h3>4. Cancellation Process:</h3>
            <ol>
              <li>a. Owners must log in to their account and navigate to the 'Booking History' section.              </li>
              <li>b. Select the booking and click the 'Cancel' button.              </li>
              <li>c. Follow the instructions to initiate the cancellation process.              </li>
            </ol>
          </div>

          <div className="policy-item">
            <h3>5. Contact Information:</h3>
            <ul>
              <li>
                a. For any assistance with cancellations, users can contact our customer service team.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  )
}

export default Cancellationandreturn