import React, { useState } from 'react';
import SelectLocation from './SelectLocation';
import location from '../Assets/location.svg';

const UpdateInstituteModel = ({
    show, onClose, Name, Location, Game, InstituteDescription, startTime, endTime, Features, About, Services, Achievements, FaceBookLink, InstagramLink, TwitterLink,
    setName, setLocation, setGame, setInstituteDescription, setstartTime, setendTime, setFeatures, setAbout, setServices, setAchievements, setFaceBookLink, setInstagramLink, setTwitterLink, UpdateInstitute
    , latitude, longitude, setlongitude, setlatitude, position, setPosition, selectedImages, setSelectedImages, Images, setImages, handleImageChange, removeImage,
}) => {


    const [selectLocation, setselectLocation] = useState(false);

    const OpenLocation = () => {
        setselectLocation(true)
    }

    const CloseLocation = () => {
        setselectLocation(false)
    }

    if (!show) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Update Institute</h2>
                <br />
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Name</label>
                            <input
                                placeholder='Name'
                                value={Name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Location</label>
                            <input
                                placeholder='Location'
                                value={Location}
                                onChange={(e) => { setLocation(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Game</label>
                            <input
                                placeholder='Game'
                                value={Game}
                                onChange={(e) => { setGame(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Institute Description</label>
                            <input
                                placeholder='Institute Description'
                                value={InstituteDescription}
                                onChange={(e) => { setInstituteDescription(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Start Time</label>
                            <input
                                placeholder='Start Time'
                                type='time'
                                value={startTime}
                                onChange={(e) => { setstartTime(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>End Time</label>
                            <input
                                placeholder='End Time'
                                type='time'
                                value={endTime}
                                onChange={(e) => { setendTime(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Achievements</label>
                            <input
                                placeholder='Achievements'
                                value={Achievements}
                                onChange={(e) => { setAchievements(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Features</label>
                            <input
                                placeholder='Features'
                                value={Features}
                                onChange={(e) => { setFeatures(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>About</label>
                            <input
                                placeholder='About'
                                value={About}
                                onChange={(e) => { setAbout(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Services</label>
                            <input
                                placeholder='Services'
                                value={Services}
                                onChange={(e) => { setServices(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Facebook Link</label>
                            <input
                                placeholder='Facebook Link'
                                value={FaceBookLink}
                                onChange={(e) => { setFaceBookLink(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Instagram Link</label>
                            <input
                                placeholder='Instagram Link'
                                value={InstagramLink}
                                onChange={(e) => { setInstagramLink(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Twitter Link</label>
                            <input
                                placeholder='Twitter Link'
                                value={TwitterLink}
                                onChange={(e) => { setTwitterLink(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='addtruf-ip'>
                    <label>Institute  Images</label>
                    <div>
                        <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                            {selectedImages.map((image, index) => (
                                <div key={index} style={{ position: 'relative', margin: '10px' }}>
                                    <img
                                        src={image}
                                        alt={`Selected ${index + 1}`}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                    <button
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            right: '0',
                                            background: 'red',
                                            color: 'white',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => removeImage(index)}
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                <br />
                <div>
                    <h4 onClick={OpenLocation} style={{ display: 'flex', alignItems: 'center' }}>
                        Select a Location on the Map
                        <img src={location} className='sport-icon' style={{ marginLeft: '8px' }} />
                    </h4>
                    <div>
                        <p>Latitude: {latitude}</p>
                        <p>Longitude: {longitude}</p>
                    </div>
                </div>
                <br />
                <button onClick={UpdateInstitute}>UpdateInstitute</button>
                <button onClick={onClose}>Close</button>
            </div>
            <SelectLocation
                isOpen={selectLocation}
                setIsOpen={setselectLocation}
                latitude={latitude}
                longitude={longitude}
                position={position}
                setlatitude={setlatitude}
                setlongitude={setlongitude}
                setPosition={setPosition}
            />
        </div>
    );
};

export default UpdateInstituteModel;
