import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { apiPost } from '../../ApiUrls';

function AddReview({rating,reviewText,CreateReview,setRating,setReviewText }) {
    const [hover, setHover] = useState(null);



    return (
        <div className="add-review-container">
            <h3>Add a Review</h3>
            <div className="star-rating">
                {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    return (
                        <label key={index}>
                            <input
                                type="radio"
                                name="rating"
                                value={starValue}
                                onClick={() => setRating(starValue)}
                                style={{ display: 'none' }}
                            />
                            <FaStar
                                className="star"
                                color={starValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                size={30}
                                onMouseEnter={() => setHover(starValue)}
                                onMouseLeave={() => setHover(null)}
                            />
                        </label>
                    );
                })}
            </div>
            <textarea
                rows="4"
                placeholder="Write your review here..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
            />
            <button onClick={() => { CreateReview() }}>Submit Review</button>
        </div>
    );
}

export default AddReview;
