import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Banner({ Allturfs }) {
  const navigate = useNavigate();
  const [Name, setName] = useState('');
  const [filteredTurfs, setFilteredTurfs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSearch = (e) => {
    const value = e.target.value;
    setName(value);
    if (value) {
      const filtered = Allturfs.filter(turf =>
        turf.Name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTurfs(filtered);
      setShowDropdown(true);
    } else {
      setFilteredTurfs([]);
      setShowDropdown(false);
    }
  };

  const TurfDetailsdetails = (id) => {
    console.log('Navigating to turf:', id);
    navigate(`/TurfDetails/${id}`);
    setShowDropdown(false);
  };

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 150); 
  };

  return (
    <div className='banner'>
      <h2>PLAN YOUR SPORTS<br /> ACTIVITIES TO BE THE BEST</h2>
      <p>Website is Under Development</p>
      <p>Plan your sports activities to be the best with state of the <br />
        art and best venue facilities</p>
      
      <div className='opac'>
        <div className='opacinput'>
          <i className="bi bi-search"></i>
          <input
            value={Name}
            onChange={handleSearch}
            onFocus={() => setShowDropdown(true)}
            onBlur={handleBlur}
            placeholder='Search'
          />
          
          {showDropdown && (
            <div className="dropdown">
              <div className="dropdown-content">
                {filteredTurfs.map((turf) => (
                  <div
                    onClick={() => TurfDetailsdetails(turf._id)}
                    key={turf._id}
                    className="dropdown-item"
                  >
                    {turf.Name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <button>Search</button> */}
      </div>
    </div>
  );
}

export default Banner;
