import React, { useEffect, useState } from 'react';
import ticket from '../Assets/coupon-svgrepo-com 1.png';
import arrow from '../Assets/arrow-prev-svgrepo-com 9.png';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { apiDelete, apiGet, apiPut } from '../ApiUrls';
import Edit from '../Assets/Edit.svg'
import Delete from '../Assets/Delete.svg'
import UpdateProgram from '../Components/UpdateProgram';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import DeleteProgram from '../Components/DeleteProgram';
import BlockProgramSlotsModel from '../Components/BlockProgramSlotsModel';


function AllPrograms() {

    const [AllProgram, setAllProgram] = useState([]);
    const [IsUploading, setIsUploading] = useState();

    const GetProgram = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet('api/coach/GetAllProgramsofaInstitute');
            setAllProgram(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetProgram();
    }, []);

    const [openUpdateModel, setopenUpdateModel] = useState(false);

    const [Programid, setProgramid] = useState();
    const [ProgramName, setProgramName] = useState();
    const [Coach, setCoach] = useState();
    const [StartDate, setStartDate] = useState();
    const [TotalSlots, setTotalSlots] = useState();
    const [Price, setPrice] = useState();
    const [KeyFeatures, setKeyFeatures] = useState();


    const OpenUpdate = (program) => {
        setProgramid(program?._id)
        setProgramName(program?.ProgramName)
        setCoach(program?.Coach)
        setStartDate(program?.StartDate)
        setTotalSlots(program?.TotalSlots)
        setPrice(program?.Price)
        setKeyFeatures(program?.KeyFeatures)
        setopenUpdateModel(true)
    }
    const CloseUpdate = () => {
        setopenUpdateModel(false)
    }


    const UpdatePrograms = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            let data = {
                ProgramName: ProgramName,
                Coach: Coach,
                StartDate: StartDate,
                TotalSlots: TotalSlots,
                Price: Price,
                KeyFeatures: KeyFeatures
            }
            setIsUploading(true);
            const response = await apiPut(`api/coach/UpdateProgram/${Programid}`, data);
            console.log(response?.data)
            setProgramName('')
            setCoach('')
            setStartDate('')
            setTotalSlots('')
            setPrice('')
            setKeyFeatures('')
            toast.success(response?.message)
            CloseUpdate();
            GetProgram();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }

    const [DeleteModel, setDeleteModel] = useState(false);

    const DeleteOpen = (program) => {
        setProgramid(program?._id)
        setDeleteModel(true)
    }

    const deleteClose = () => {
        setDeleteModel(false)
    }

    const DeletePrograms = async () => {
        try {
            const response = await apiDelete(`api/coach/DeleteProgram/${Programid}`)
            deleteClose();
            GetProgram();
            toast.success(response?.message);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.log(error)
        }
    }

    const [Slots, setSlots] = useState('');

    const [BlockModel, setBlockModel] = useState(false);

    const DeleteBlockOpen = (program) => {
        setProgramid(program?._id)
        setBlockModel(true)
    }

    const deleteBlockClose = () => {
        setBlockModel(false)
    }


    const BlockSlots = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            let data = {
                slotsToBlock: Slots,
            }
            setIsUploading(true);
            const response = await apiPut(`api/coach/BlockProgramSlots/${Programid}`, data);
            console.log(response?.data)
            toast.success(response?.message)
            deleteBlockClose();
            setSlots('')
            GetProgram();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }

    const UnBlockSlots = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            let data = {
                slotsToUnblock: Slots,
            }
            setIsUploading(true);
            const response = await apiPut(`api/coach/UnblockProgramSlots/${Programid}`, data);
            console.log(response?.data)
            toast.success(response?.message)
            deleteBlockClose();
            setSlots('')
            GetProgram();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
            setcity(localcity||'Indore')
    }, []);


    return (
        <>
            <Navbar city={city} setcity={setcity}/>
            <br />
            <br />
            <br />
            <div className='slotdetail-div'>
                <div className='slotdetail-div'>
                    <div className='slotdetail'>
                        <div className='apply-coupon'>
                            <h2>
                                <img src={ticket} alt='Apply Coupon' />
                                All Programs Of Institute ({AllProgram?.length})
                            </h2>
                            <img src={arrow} alt='Arrow Icon' />
                        </div>
                    </div>
                </div>
                <br />
                <br />
                {
                    IsUploading ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                            <ClipLoader color="#31ED31" size={200} />
                        </div>
                        :
                        AllProgram?.map((program, index) => (
                            <>
                                <div style={{
                                    maxWidth: '900px',
                                    width: '100%',
                                    borderRadius: '10px',
                                    left: '10%'
                                }}
                                    key={index}>
                                    <div className='slotdetail slotdetail2'>
                                        <div className='apply-coupon apply-coupon2'>
                                            <ul>
                                                <h2>{program?.ProgramName}</h2>
                                                <li className='cat' style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    <img onClick={() => { OpenUpdate(program) }} src={Edit} style={{ width: '24px', height: '24px' }} alt='Edit Icon' />
                                                    <img onClick={() => { DeleteOpen(program) }} src={Delete} style={{ width: '24px', height: '24px' }} alt='Delete Icon' />
                                                </li>
                                            </ul>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Institute Name - {program?.Institute?.Name}</li>
                                            </ul>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Coach - {program?.Coach?.Name}</li>
                                                <li className='cat'>Date - {program?.StartDate}</li>
                                            </ul>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Total Slot - {program?.TotalSlots}</li>
                                                <li className='cat'>Available Slot - ({program?.AvailableSlots}*)</li>
                                            </ul>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Price - {program?.Price}</li>
                                                <li className='cat'>Feature - {program?.KeyFeatures}</li>
                                            </ul>
                                            <br />
                                            <br />
                                            <ul>
                                                <li>Blocked Slots - {program?.BlockedSlots || 0}</li>
                                                <li className='cat'>Block / UnBloack Slots<img onClick={() => { DeleteBlockOpen(program) }} src={Edit} style={{ width: '24px', height: '24px' }} alt='Edit Icon' /></li>
                                            </ul>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </>
                        ))}
            </div>
            <UpdateProgram
                show={openUpdateModel}
                ProgramName={ProgramName}
                setProgramName={setProgramName}
                Coach={Coach}
                setCoach={setCoach}
                StartDate={StartDate}
                setStartDate={setStartDate}
                TotalSlots={TotalSlots}
                setTotalSlots={setTotalSlots}
                Price={Price}
                setPrice={setPrice}
                KeyFeatures={KeyFeatures}
                setKeyFeatures={setKeyFeatures}
                UpdatePrograms={UpdatePrograms}
            />

            <DeleteProgram
                show={DeleteModel}
                Close={deleteClose}
                Deleteprogram={DeletePrograms}
            />
            <BlockProgramSlotsModel
                show={BlockModel}
                onClose={deleteBlockClose}
                Slots={Slots}
                setSlots={setSlots}
                BlockSlots={BlockSlots}
                UnBlockSlots={UnBlockSlots}
                Deleteprogram={DeletePrograms}
            />
            <br />
            <br />
            <Footer />
        </>
    )
}

export default AllPrograms