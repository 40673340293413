import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22038.png'
import exp from '../Assets/experience-svgrepo-com 1.png'
import { apiDelete, apiPut, Get_Api } from '../ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import HorizontalCalendar from './add/HorizontalCalendar';
import dayjs from 'dayjs';
import Edit from '../Assets/Edit.svg'
import Delete from '../Assets/Delete.svg'
import UpdateInstituteModel from '../Components/UpdateInstituteModel';
import { toast } from 'react-toastify';
import DeleteInstituteModel from '../Components/DeleteInstituteModel';
import ImageSlider from '../Components/ImageSlider';
import location from '../Assets/location.svg';
import system from '../Assets/system-uicons_location.png';

function MyInstituteDetails() {
    const navigate = useNavigate()
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);

    const currentDate = dayjs().format('DD-MM-YYYY');

    // const handleTabChange = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
        document.getElementById(`tab-content-${tabNumber}`)?.scrollIntoView({ behavior: 'smooth' });
    };


    const [Institutedetails, setInstitutedetails] = useState();
    const [AllPrograms, setAllPrograms] = useState([]);
    const [IsUploading, setIsUploading] = useState();

    const GetInstitute = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetaInstitute/${id}`);
            setInstitutedetails(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const GetInstituteprograms = async (date) => {
        try {
            const response = await Get_Api(`api/user/GetAllProgramsofaInstitute/${id}?StartDate=${date}`);
            setAllPrograms(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetInstitute();
        GetInstituteprograms(currentDate)
    }, [id]);

    const Allcoach = (id) => {
        navigate(`/ProgramDetails/${id}`)
    }

    const handleDayPress = (date) => {
        console.log(date?.dateString)
        GetInstituteprograms(date?.dateString)
    }


    const Addcoach = (id) => {
        navigate(`/AddCoach/${id}`)
    }
    const AddProgram = (id) => {
        navigate(`/AddPrograms/${id}`)
    }

    const [UpdateModel, setUpdateModel] = useState(false);

    const [Name, setName] = useState('');
    const [Location, setLocation] = useState('');
    const [Game, setGame] = useState('');
    const [InstituteDescription, setInstituteDescription] = useState('');
    const [startTime, setstartTime] = useState('');
    const [endTime, setendTime] = useState('');
    const [Features, setFeatures] = useState('');
    const [About, setAbout] = useState('');
    const [Services, setServices] = useState('');
    const [Achievements, setAchievements] = useState('');
    const [FaceBookLink, setFaceBookLink] = useState('');
    const [InstagramLink, setInstagramLink] = useState('');
    const [TwitterLink, setTwitterLink] = useState('');
    const [latitude, setlatitude] = useState();
    const [longitude, setlongitude] = useState();
    const [position, setPosition] = useState(null);

    const [selectedImages, setSelectedImages] = useState([]);
    const [Images, setImages] = useState([]);
    console.log(Images)
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        setSelectedImages(imageUrls);
        setImages(files);
    };

    const removeImage = (index) => {
        const newImages = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(newImages);
    };

    const OpenUpdateModel = (data) => {
        console.log(data)
        setUpdateModel(true)
        setName(data?.Name)
        setLocation(data?.Location)
        setGame(data?.Game)
        setInstituteDescription(data?.InstituteDescription)
        setstartTime(data?.Time?.startTime)
        setendTime(data?.Time?.endTime)
        setFeatures(data?.Features)
        setAbout(data?.About)
        setServices(data?.Services)
        setAchievements(data?.Achievements)
        setFaceBookLink(data?.FaceBookLink)
        setInstagramLink(data?.InstagramLink)
        setTwitterLink(data?.TwitterLink)
        setlatitude(data?.latitude)
        setlongitude(data?.longitude)
    }
    const CloseUpdateModel = () => {
        setUpdateModel(false)
    }

    // const UpdateInstitute = async () => {
    //     try {
    //         const token = localStorage.getItem("userToken");

    //         if (!token) {
    //             toast.error('Please Login First....');
    //             return
    //         }

    //         let data = {
    //             Name,
    //             Location,
    //             Game,
    //             InstituteDescription,
    //             Time: {
    //                 startTime,
    //                 endTime
    //             },
    //             Features,
    //             About,
    //             Services,
    //             Achievements,
    //             FaceBookLink,
    //             InstagramLink,
    //             TwitterLink,
    //             longitude,
    //             latitude
    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/coach/UpdateInstitute/${id}`, data);
    //         console.log(response)
    //         setName('')
    //         setLocation('')
    //         setGame('')
    //         setInstituteDescription('')
    //         setstartTime('')
    //         setendTime('')
    //         setAbout('')
    //         setFeatures('')
    //         setServices('')
    //         setAchievements('')
    //         setFaceBookLink('')
    //         setInstagramLink('')
    //         setTwitterLink('')
    //         setIsUploading(false);
    //         toast.success(response?.message)
    //         CloseUpdateModel();
    //         GetInstitute();
    //     } catch (error) {
    //         toast.error(error?.response?.message)
    //         console.error('Error fetching data:', error);
    //     }
    // }

    const UpdateInstitute = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return;
            }

            let formData = new FormData();
            formData.append("Name", Name);
            formData.append("Location", Location);
            formData.append("Game", Game);
            formData.append("InstituteDescription", InstituteDescription);
            formData.append("Time[startTime]", startTime);
            formData.append("Time[endTime]", endTime);
            formData.append("Features", Features);
            formData.append("About", About);
            formData.append("Services", Services);
            formData.append("Achievements", Achievements);
            formData.append("FaceBookLink", FaceBookLink);
            formData.append("InstagramLink", InstagramLink);
            formData.append("TwitterLink", TwitterLink);
            formData.append("longitude", longitude);
            formData.append("latitude", latitude);
            Images.forEach((image, index) => {
                formData.append(`Images`, image);
            });

            setIsUploading(true);
            const response = await apiPut(`api/coach/UpdateInstitute/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response);
            setName('');
            setLocation('');
            setGame('');
            setInstituteDescription('');
            setstartTime('');
            setendTime('');
            setAbout('');
            setFeatures('');
            setServices('');
            setAchievements('');
            setFaceBookLink('');
            setInstagramLink('');
            setTwitterLink('');
            setIsUploading(false);
            toast.success(response?.message);
            CloseUpdateModel();
            GetInstitute();
        } catch (error) {
            toast.error(error?.response?.message || 'Error updating institute');
            console.error('Error updating data:', error);
        }
    };


    const [OpenDeleteModal, setOpenDeleteModal] = useState(false);

    const OpenDelete = () => {
        setOpenDeleteModal(true)
    }

    const CloseDelete = () => {
        setOpenDeleteModal(false)
    }

    const DeleteInstitute = async () => {
        try {
            const response = await apiDelete(`api/coach/DeleteInstitute/${id}`)
            toast.success(response?.message);
            navigate(-1);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.log(error)
        }
    }

    const allBooking = () => {
        navigate(`/AllBookingsofAInstitute/${id}`)
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);



    const Openmap = (latitude, longitude) => {

        if (latitude && longitude) {
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

            window.open(mapUrl, '_blank');
        } else {
            alert("Location coordinates are not available.");
        }
    };


    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='venue-detail'>
                            <div className='venue-info'>
                                {/* <img src={Institutedetails?.profileimage ? img : img} /> */}
                                <ImageSlider images={Institutedetails?.Images.length > 0 ? Institutedetails?.Images : [img]} />
                                <div className='venue-text'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className='venue-text-heading'>
                                            <h2>{Institutedetails?.Name}</h2>
                                            <div className='green-review'>
                                                <p>4.7 <i className="bi bi-star-fill"></i></p>
                                            </div>
                                            <h4>(650 reviews)</h4>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            <img style={{ width: 30, height: 30 }} onClick={() => { OpenUpdateModel(Institutedetails) }} src={Edit} alt="Edit" />
                                            <img style={{ width: 30, height: 30 }} onClick={() => { OpenDelete() }} src={Delete} alt="Delete" />
                                        </div>
                                    </div>

                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <p onClick={() => { Openmap(Institutedetails?.latitude, Institutedetails?.longitude) }}>{Institutedetails?.Location} <img src={system} className='sport-icon' style={{ marginLeft: '8px', width: 25, height: 25 }} /></p>
                                            <p><img src={exp} />Experience : 7 Years</p>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            <button onClick={() => { Addcoach(id) }} >Create Coach</button>
                                            <button onClick={() => { AddProgram(id) }}>Create Program</button>
                                            <button onClick={() => { allBooking() }}>All Bookings</button>
                                        </div>

                                    </div>
                                    <div className='venue-text-heading3'>
                                        <h3>Rs 1080/- 3 month</h3>
                                        <div className='clock'>

                                            <i className="bi bi-clock"></i>
                                            <p>{Institutedetails?.Time?.startTime || '10:00 AM'}  -  {Institutedetails?.Time?.endTime || '04:00 PM'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <br />
                        <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
                            <HorizontalCalendar handleDayPress={handleDayPress} />
                        </div>
                        <br />
                        <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
                            <div className='tab1'>
                                <h3>Available Programs</h3>
                            </div>
                        </div>
                        <br />
                        {AllPrograms?.length === 0 ? (
                            <div className='tab-content'>
                                <div className='tab1'>
                                    <h4 style={{ textAlign: 'center' }}>
                                        No programs found
                                    </h4>
                                </div>
                            </div>
                        ) : (
                            AllPrograms.map((program, index) => (
                                <div key={index} className="tab-container2">
                                    <div className="tabs">
                                        <button >{program?.ProgramName}</button>
                                        <button >Coach Name - {program?.Coach?.Name}</button>
                                        <button >{program?.Price}/Slot</button>
                                        <button className={'tab-btn active bookcoach'} onClick={() => Allcoach(program?._id)}>Book Now</button>
                                    </div>
                                </div>
                            ))
                        )}

                        <div className="tab-container">
                            <div className="tabs">
                                <button
                                    className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(1)}
                                >
                                    About
                                </button>
                                <button
                                    className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(2)}
                                >
                                    Features
                                </button>

                                <button
                                    className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(3)}
                                >
                                    Services
                                </button>
                                <button
                                    className={activeTab === 4 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(4)}
                                >
                                    Achievements
                                </button>
                            </div>
                            <br />
                            <br />
                            <div className="tab-content">
                                <div className='tab1'>
                                    <div id="tab-content-1" >
                                        <h3>About</h3>
                                        <br />
                                        <p>{Institutedetails?.About}</p>

                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <div id="tab-content-2" >
                                        <h3>Features</h3>
                                        <br />
                                        <ul>
                                            <li>{Institutedetails?.Features}</li>
                                        </ul>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-3" >
                                        <h3>Services</h3>
                                        <br />
                                        <p>{Institutedetails?.Services}</p>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-4">
                                        <h3>Achievements</h3>
                                        <br />
                                        <p>{Institutedetails?.Achievements}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UpdateInstituteModel
                            show={UpdateModel}
                            onClose={CloseUpdateModel}
                            Name={Name}
                            setName={setName}
                            Location={Location}
                            setLocation={setLocation}
                            Game={Game}
                            setGame={setGame}
                            InstituteDescription={InstituteDescription}
                            setInstituteDescription={setInstituteDescription}
                            startTime={startTime}
                            setstartTime={setstartTime}
                            endTime={endTime}
                            setendTime={setendTime}
                            Features={Features}
                            setFeatures={setFeatures}
                            About={About}
                            setAbout={setAbout}
                            Services={Services}
                            setServices={setServices}
                            Achievements={Achievements}
                            setAchievements={setAchievements}
                            FaceBookLink={FaceBookLink}
                            setFaceBookLink={setFaceBookLink}
                            InstagramLink={InstagramLink}
                            setInstagramLink={setInstagramLink}
                            TwitterLink={TwitterLink}
                            setTwitterLink={setTwitterLink}
                            longitude={longitude}
                            latitude={latitude}
                            setlongitude={setlongitude}
                            setlatitude={setlatitude}
                            position={position}
                            setPosition={setPosition}
                            Images={Images}
                            setImages={setImages}
                            selectedImages={selectedImages}
                            setSelectedImages={setSelectedImages}
                            handleImageChange={handleImageChange}
                            removeImage={removeImage}
                            UpdateInstitute={UpdateInstitute}
                        />

                        <DeleteInstituteModel
                            show={OpenDeleteModal}
                            Close={CloseDelete}
                            DeleteInstitute={DeleteInstitute}
                        />
                        <Footer />
                    </>
            }
        </>
    )
}

export default MyInstituteDetails