import React from 'react'

function DeleteProgram({ show, Close, Deleteprogram }) {
    if (!show) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <div className='venue-detail'>
                    <div style={{ backgroundColor: 'rgb(239, 236, 236)' }} className='venue-info'>
                        <div className='venue-text-heading2-text'>
                            <p>Do you want to delete this program ?</p>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className='venue-text-heading2'>
                            <button onClick={() => { Deleteprogram() }}>Yes</button>
                            <button onClick={() => { Close() }}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteProgram