import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Get_Api } from '../ApiUrls';
import img from '../Assets/Rectangle 21.svg'
import { useNavigate } from 'react-router-dom';

function Coach() {

        const [AllInstitute, setAllInstitute] = useState([]);
        const [IsUploading, setIsUploading] = useState(false);
        const navigate = useNavigate()
        const [city, setcity] = useState('');

        const localcity = localStorage.getItem("city");

        useEffect(() => {
                setcity(localcity || 'Indore')
        }, []);

        const GetAllInstitute = async () => {
                try {
                        setIsUploading(true);
                        const response = await Get_Api(`api/user/GetAllInstitute?location=${city}`);
                        setAllInstitute(response.data);
                        setIsUploading(false);
                } catch (error) {
                        console.error('Error fetching data:', error);
                }
        };

        useEffect(() => {
                if (city !== '') {
                        GetAllInstitute();
                }
        }, [city]);

        const institutedetails = (id) => {
                navigate(`/coachdetail/${id}`)
        }

        return (
                <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='reg-banner'>
                                <div className='reg-banner-div reg-banner-div2'>
                                        <h2>Find Perfect Coach</h2>
                                </div>
                        </div>
                        <div className='available-venues'>
                                <div className='available-venues-div'>
                                        <div className='available-venues-heading'>
                                                <h2>Coaching Institute ({AllInstitute?.length || 0})</h2>
                                        </div>
                                        <br />
                                        <br />
                                        <div className='game-cards'>
                                                {AllInstitute?.map((institute, index) => (
                                                        <div key={index}>
                                                                <div className='game-card'>
                                                                        <div style={{ backgroundImage: `url(${institute?.profileimage || img})` }} className='game-card-img'>
                                                                        </div>
                                                                        <br />
                                                                        <h3>{institute?.Name}</h3>
                                                                        <p>{institute?.Location}</p>
                                                                        <h4>Rs.1080 / 3 months</h4>
                                                                        <button onClick={() => { institutedetails(institute?._id) }}>Book Now</button>
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </div>
                        <Footer />
                </>
        )
}

export default Coach