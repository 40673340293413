import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22038.png'
import exp from '../Assets/experience-svgrepo-com 1.png'
import { apiDelete, apiGet, apiPut, Get_Api } from '../ApiUrls';
import { ClipLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteCoachModel from '../Components/DeleteCoachModel';
import { toast } from 'react-toastify';
import Edit from '../Assets/Edit.svg'
import UpdateCoachModel from '../Components/UpdateCoachModel';
import { Try } from '@mui/icons-material';
import ImageSlider from '../Components/ImageSlider';

function CoachCoachDetails() {
    const navigate = useNavigate()
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);


    // const handleTabChange = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };


    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
        document.getElementById(`tab-content-${tabNumber}`)?.scrollIntoView({ behavior: 'smooth' });
    };


    const [Coach, setCoach] = useState();
    const [AllPrograms, setAllPrograms] = useState([]);
    const [IsUploading, setIsUploading] = useState();

    const GetCoach = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet(`api/coach/GetACoach/${id}`);
            setCoach(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        GetCoach();
    }, [id]);

    const [OpenDeleteModal, setOpenDeleteModal] = useState(false);

    const OpenDelete = () => {
        setOpenDeleteModal(true)
    }

    const CloseDelete = () => {
        setOpenDeleteModal(false)
    }


    const DeleteCoach = async () => {
        try {
            const response = await apiDelete(`api/coach/DeleteCoach/${id}`)
            toast.success(response?.message);
            navigate('/AllCoachOfInstitute')
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.log(error)
        }
    }


    const [OpenCoachUpdateModel, setOpenCoachUpdateModel] = useState(false);

    const [Name, setName] = useState();
    const [Location, setLocation] = useState();
    const [Game, setGame] = useState();
    const [Email, setEmail] = useState();
    const [startTime, setstartTime] = useState();
    const [endTime, setendTime] = useState();
    const [CoachDescription, setCoachDescription] = useState();
    const [About, setAbout] = useState();
    const [Services, setServices] = useState();
    const [Achievements, setAchievements] = useState();

    const OpenUpdateModel = () => {
        setName(Coach?.Name)
        setLocation(Coach?.Location)
        setGame(Coach?.Game)
        setEmail(Coach?.Email)
        setstartTime(Coach?.Time?.startTime)
        setendTime(Coach?.Time?.endTime)
        setCoachDescription(Coach?.CoachDescription)
        setAbout(Coach?.About)
        setServices(Coach?.Services)
        setAchievements(Coach?.Achievements)
        setOpenCoachUpdateModel(true)
    }

    const CloseUpdateModel = () => {
        setOpenCoachUpdateModel(false)
    }

    const UpdateCoach = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            let data = {
                Name,
                Location,
                Game,
                Email,
                Time: {
                    startTime,
                    endTime
                },
                CoachDescription,
                About,
                Services,
                Achievements
            }
            setIsUploading(true);
            const response = await apiPut(`api/coach/UpdateCoach/${id}`, data);
            console.log(response)
            setName('')
            setGame('')
            setLocation('')
            setEmail('')
            setstartTime('')
            setendTime('')
            setCoachDescription('')
            setAbout('')
            setServices('')
            setAchievements('')
            setIsUploading(false);
            toast.success(response?.message)
            CloseUpdateModel();
            GetCoach();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }


    const DeleteInstitutes = async () => {

    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='venue-detail'>
                            <div className='venue-info'>
                                {/* <img style={{ height: '500px' }} src={Coach?.profileimage ? Coach?.profileimage : img} /> */}
                                <ImageSlider images={Coach?.Images.length > 0 ? Coach?.Images : [img]} />
                                <div className='venue-text'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className='venue-text-heading'>
                                            <h2>{Coach?.Name}</h2>
                                            <div className='green-review'>
                                                <p>4.7 <i className="bi bi-star-fill"></i></p>
                                            </div>
                                            <h4>(650 reviews)</h4>
                                        </div>
                                        <div>
                                            <img onClick={() => { OpenUpdateModel() }} src={Edit} alt="Edit" />
                                        </div>
                                    </div>
                                    <br />
                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <p>{Coach?.Location}</p>
                                            <p><img src={exp} />Experience : {Coach?.Experience}</p>

                                        </div>
                                        <button onClick={() => { OpenDelete() }}>Delete</button>
                                    </div>
                                    <br />
                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <p>Game - {Coach?.Game}</p>
                                            <p>Contact - {Coach?.Email}</p>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='venue-text-heading3'>
                                        <h3>Rs 1080/- 3 month</h3>
                                        <div className='clock'>

                                            <i className="bi bi-clock"></i>
                                            <p>{Coach?.Time?.startTime} -  {Coach?.Time?.endTime}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <br />
                        <br />
                        <div className="tab-container">
                            <div className="tabs">
                                <button
                                    className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(1)}
                                >
                                    Description
                                </button>
                                <button
                                    className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(2)}
                                >
                                    About
                                </button>

                                <button
                                    className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(3)}
                                >
                                    Services
                                </button>
                                <button
                                    className={activeTab === 4 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(4)}
                                >
                                    Achievements
                                </button>
                            </div>
                            <br />
                            <br />
                            <div className="tab-content">
                                <div className='tab1'>
                                    <div id="tab-content-1" >
                                        <h3>Description</h3>
                                        <br />
                                        <p>{Coach?.CoachDescription}</p>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-2" >
                                        <h3>About</h3>
                                        <br />
                                        <p>{Coach?.About}</p>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-3" >
                                        <h3>Services</h3>
                                        <br />
                                        <ul style={{ marginLeft: 20 }}>
                                            {Coach?.Services?.map((service, index) => (
                                                <li key={index}>
                                                    <p> {service}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-4" >
                                        <h3>Achievements</h3>
                                        <br />
                                        <p>{Coach?.Achievements}</p>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <DeleteCoachModel
                            show={OpenDeleteModal}
                            Close={CloseDelete}
                            DeleteCoach={DeleteCoach}
                        />
                        <UpdateCoachModel
                            show={OpenCoachUpdateModel}
                            onClose={CloseUpdateModel}
                            Name={Name}
                            Location={Location}
                            Game={Game}
                            Email={Email}
                            startTime={startTime}
                            endTime={endTime}
                            CoachDescription={CoachDescription}
                            About={About}
                            Services={Services}
                            Achievements={Achievements}
                            setName={setName}
                            setLocation={setLocation}
                            setGame={setGame}
                            setEmail={setEmail}
                            setstartTime={setstartTime}
                            setendTime={setendTime}
                            setCoachDescription={setCoachDescription}
                            setAbout={setAbout}
                            setServices={setServices}
                            setAchievements={setAchievements}
                            UpdateCoach={UpdateCoach}
                        />
                        <Footer />
                    </>
            }
        </>
    )
}

export default CoachCoachDetails