import React, { useEffect, useState } from 'react';
import { Get_Api } from '../ApiUrls';
import { toast } from 'react-toastify';
import location from '../Assets/location.svg';
import SelectLocation from './SelectLocation';

const UpdateTurfModel = ({ show, onClose, Name, setName, Dimension, setDimension, Location, setLocation, FaceBookLink, setFaceBookLink, InstagramLink, setInstagramLink, TwitterLink, setTwitterLink, Price, setPrice, About, setAbout, AvailableSports, setAvailableSports, VenueRules, setVenueRules, Amenities, setAmenities, latitude, longitude, setlongitude, setlatitude, position, setPosition, selectedImages, setSelectedImages, Images, setImages, handleImageChange, removeImage, UpdateTurf }) => {



    const handleCheckboxAmenitiesChange = (event) => {
        const { name, checked } = event.target;
        setAmenities((prevAmenities) => ({
            ...prevAmenities,
            [name]: checked,
        }));
    };

    const [IsUploading, setIsUploading] = useState(false);
    const [cities, setcities] = useState([]);

    const [Sports, setSports] = useState([]);

    const getAllCity = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/admin/GetAllCity`);
            setcities(response.data);
        } catch (error) {
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };
    const getAllSport = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/admin/GetAllSports`);
            setSports(response.data);
        } catch (error) {
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        getAllCity();
        getAllSport();
    }, []);

    const handleSportsChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setAvailableSports(selectedOptions.join(', '));
    };
    const [selectLocation, setselectLocation] = useState(false);

    const OpenLocation = () => {
        setselectLocation(true)
    }

    const CloseLocation = () => {
        setselectLocation(false)
    }

    if (!show) return null;




    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Update Turf</h2>
                <br />
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Name</label>
                            <input
                                placeholder='Name'
                                value={Name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Dimension</label>
                            <input
                                placeholder='Dimension'
                                value={Dimension}
                                onChange={(e) => { setDimension(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Location</label>
                            <select style={{ marginTop: '-2px' }} value={Location} onChange={(e) => setLocation(e.target.value)}>
                                <option value="Select a city" defaultValue >Select a city</option>
                                {cities.map((city, index) => (
                                    <option key={index} value={city?.Name}>
                                        {city?.Name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>FaceBook Link</label>
                            <input
                                placeholder='FaceBookLink'
                                value={FaceBookLink}
                                onChange={(e) => { setFaceBookLink(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Instagram Link</label>
                            <input
                                placeholder='InstagramLink'
                                value={InstagramLink}
                                onChange={(e) => { setInstagramLink(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Twitter Link</label>
                            <input
                                placeholder='TwitterLink'
                                value={TwitterLink}
                                onChange={(e) => { setTwitterLink(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Price</label>
                            <input
                                placeholder='Price'
                                value={Price}
                                onChange={(e) => { setPrice(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>About</label>
                            <input
                                placeholder='About'
                                value={About}
                                onChange={(e) => setAbout(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>VenueRules</label>
                            <input
                                placeholder='VenueRules'
                                value={VenueRules}
                                onChange={(e) => setVenueRules(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Available Sports</label>
                            <select style={{ marginTop: '-2px' }} multiple={true} onChange={handleSportsChange}>
                                {Sports.map((sport, index) => (
                                    <option key={index} value={sport?.Name}>
                                        {sport?.Name}
                                    </option>
                                ))}
                            </select>
                            <p>Selected Sports: {AvailableSports}</p>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className='addtruf-ips'>
                    <label>Amenities</label>
                    <div className="checkbox-rownew">
                        {Object.keys(Amenities).map((amenity) => (
                            <div key={amenity} className="checkbox-containernew">
                                <label>
                                    <input
                                        type='checkbox'
                                        name={amenity}
                                        checked={Amenities[amenity]}
                                        onChange={handleCheckboxAmenitiesChange}
                                        className='small-checkbox'
                                    />
                                    {amenity.charAt(0).toUpperCase() + amenity.slice(1).replace(/([A-Z])/g, ' $1')}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <br />
                <div>
                    <h4 onClick={OpenLocation} style={{ display: 'flex', alignItems: 'center' }}>
                        Select a Location on the Map
                        <img src={location} className='sport-icon' style={{ marginLeft: '8px' }} />
                    </h4>
                    <div>
                        <p>Latitude: {latitude}</p>
                        <p>Longitude: {longitude}</p>
                    </div>
                </div>
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Select Turf Images</label>
                        <div>
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                                {selectedImages.map((image, index) => (
                                    <div key={index} style={{ position: 'relative', margin: '10px' }}>
                                        <img
                                            src={image}
                                            alt={`Selected ${index + 1}`}
                                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                        />
                                        <button
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '0',
                                                background: 'red',
                                                color: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => removeImage(index)}
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <button onClick={() => { UpdateTurf() }}>Submit</button>
                <button onClick={() => { onClose() }}>Close</button>
            </div>
            <SelectLocation
                isOpen={selectLocation}
                setIsOpen={setselectLocation}
                latitude={latitude}
                longitude={longitude}
                position={position}
                setlatitude={setlatitude}
                setlongitude={setlongitude}
                setPosition={setPosition}
            />
        </div>
    );
};

export default UpdateTurfModel;
