import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../Assets/logo.svg'
import Kreeda_Logo from '../Assets/Kreeda_Logo.png'
import profileSVG from '../Assets/profileSVG.svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import SelectCity from './SelectCity';
function Navbar({ city, setcity }) {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [IsLogin, setsetIsLogin] = useState(false);
  const toggleDivRef = useRef(null);

  const openToggleDiv = () => {
    setIsDivVisible(true);
  };

  const closeToggleDiv = () => {
    setIsDivVisible(false);
  };

  const handleClickOutside = (event) => {
    if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
      closeToggleDiv();
    }
  };

  useEffect(() => {
    if (isDivVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDivVisible]);
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      navigate(selectedValue);
    }
  };

  useEffect(() => {
    const dataToken = localStorage.getItem("userToken");
    if (dataToken) {
      setsetIsLogin(true);
    } else {
      setsetIsLogin(false);
    }
  }, []);


  const [IsUser, setIsUser] = useState(false);
  const [IsInstitute, setIsInstitute] = useState(false);
  const [IsTurfOwner, setIsTurfOwner] = useState(false);


  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));

    if (userType === "User") {
      setIsUser(true);
    }
    if (userType === "CoachingInstitute") {
      setIsInstitute(true);
    }
    if (userType === "TurfOwner") {
      setIsTurfOwner(true);
    }
  }, []);


  const [ShowCity, setShowCity] = useState(false);


  const opencityModel = () => {
    closeToggleDiv();
    setShowCity(true)
  }

  const CloseCitymodel = () => {
    setShowCity(false)
  }

  const handleUpdateSelectedCity = (city) => {
    setcity(city)
    localStorage.setItem('city', city);
    CloseCitymodel();
  }


  return (
    <>
      <nav>
        {/* <Link className='none' to='/'>
          <img style={{ width: '75px', height: '75px', objectFit: 'contain' }} src={Kreeda_Logo} alt="Kreeda Logo" />
        </Link> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link className='none' to='/'>
            <img
              style={{ width: '75px', height: '75px', objectFit: 'contain', marginRight: '10px' }}
              src={Kreeda_Logo}
              alt="Kreeda Logo"
            />
          </Link>
          <ul>
            <Link className='none' to='/' style={{ textDecoration: 'none', color: 'black' }}>
            <li><h1 style={{ fontSize: '24px', margin: 0 }}>Kreeda</h1></li>
            </Link>
          </ul>
        </div>
        <ul>
          <Link className='none' to='/'>
            <li>Home</li>
          </Link>
          <Link className='none' to='/Turfvanues'>
            <li>Turf</li>
          </Link>

          <Link className='none' to='/Institutes'>
            <li>Institutes</li>
          </Link>
          {IsUser &&
            <Link className='none' to='/'>
              <li>About Us</li>
            </Link>
          }
          {IsInstitute &&
            <Link className='none' to='/MyInstitute'>
              <li>My Institutes</li>
            </Link>
          }
          {IsInstitute &&
            <Link className='none' to='/AllPrograms'>
              <li>All Programs</li>
            </Link>
          }
          {IsInstitute &&
            <Link className='none' to='/AllCoachOfInstitute'>
              <li>All Coaches</li>
            </Link>
          }
          {IsInstitute &&
            <Link className='none' to='/AddInstitute'>
              <li>Add Institute</li>
            </Link>
          }
          {
            IsTurfOwner &&
            <Link className='none' to='/MyAllTurfs'>
              <li>My All Turfs</li>
            </Link>
          }
          {
            IsTurfOwner &&
            <Link className='none' to='/addturf'>
              <li>Add Turf</li>
            </Link>
          }
          <li onClick={opencityModel}> <button className='locationbtn'>{city}</button></li>
          {
            IsLogin ?
              <Link to='/profile'>
                <img src={profileSVG} />
              </Link>
              :
              <Link to='/login'>
                <li>  <button className='loginbtn'>Login</button></li>
              </Link>
          }
        </ul>
        <i
          style={{ color: 'black', cursor: 'pointer' }}
          className="bi bi-list"
          onClick={isDivVisible ? closeToggleDiv : openToggleDiv}
        ></i>
      </nav>
      <SelectCity
        isOpen={ShowCity}
        setIsOpen={setShowCity}
        handleUpdateSelectedCity={handleUpdateSelectedCity}
      />
      {/* {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
              <Link className='none2' to='/'>
                <li>Home</li>
              </Link>
              <Link className='none2' to='/registration'>
                <li>Registration</li>
              </Link>
              <Link className='none2' to='/coach'>
                <li>Coach</li>
              </Link>
              <Link className='none2' to='/availableslot'>
                <li>Available Slot</li>
              </Link>
              <Link className='none2' to='/sportturf'>
                <li>Sport Turf</li>
              </Link>
              <Link className='none2' to='/coachdetail'>
                <li>Coach Detail</li>
              </Link>
              <Link className='none2' to='/coachavailable'>
                <li>Coach Available</li>
              </Link>
              <Link className='none2' to='/profile'>
                <li>Profile</li>
              </Link>
            </ul>
          </div>
        </div>
      )} */}
      {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
              <Link className='none2' to='/'><li>Home</li></Link>
              <Link className='none2' to='/Turfvanues'><li>Turf</li></Link>
              <Link className='none2' to='/Institutes'><li>Institutes</li></Link>
              {IsUser && <Link className='none2' to='/'><li>About Us</li></Link>}
              {IsInstitute && <>
                <Link className='none2' to='/MyInstitute'><li>My Institutes</li></Link>
                <Link className='none2' to='/AllPrograms'><li>All Programs</li></Link>
                <Link className='none2' to='/AllCoachOfInstitute'><li>All Coaches</li></Link>
                <Link className='none2' to='/AddInstitute'><li>Add Institute</li></Link>
              </>}
              {IsTurfOwner && <>
                <Link className='none2' to='/MyAllTurfs'><li>My All Turfs</li></Link>
                <Link className='none2' to='/addturf'><li>Add Turf</li></Link>
              </>}
              <li onClick={opencityModel}><button className='locationbtn'>{city}</button></li>
              {IsLogin ? <Link to='/profile'><img src={profileSVG} alt="Profile" /></Link> :
                <Link to='/login'><li><button className='loginbtn'>Login</button></li></Link>}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Navbar