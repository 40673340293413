import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { apiPost, Get_Api } from '../../ApiUrls';
import { toast } from 'react-toastify';
import AddPlayerModel from './AddPlayerModel';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import UpdateToss from './UpdateToss';
import AddReserveplayerModel from './AddReserveplayerModel';
function CreateTeam() {

    const navigate = useNavigate();
    const { id } = useParams();

    const [IsUploading, setIsUploading] = useState(false)

    const handleSubmitTeams = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (teamAPlayers.length === 0) {
                toast.error('Please Select Team A Players....');
                return
            }
            if (teamBPlayers.length === 0) {
                toast.error('Please Select Team B Players....');
                return
            }

            let data = {
                TeamAplayer: {
                    players: teamAPlayers,
                },
                TeamBplayer: {
                    players: teamBPlayers,
                },
            }
            setIsUploading(true);
            const response = await apiPost(`api/game/Addplayertoteam/${id}`, data);
            console.log(response)
            setIsUploading(false);
            toast.success(response?.message)
            handleOpenTossModel();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [currentTeam, setCurrentTeam] = useState('');
    const [player, setPlayer] = useState({
        playerName: '',
        role: '',
        designation: '',
        isPlaying: true,
    });

    const [teamAPlayers, setTeamAPlayers] = useState([]);
    const [teamBPlayers, setTeamBPlayers] = useState([]);

    const [teamAReservePlayers, setTeamAReservePlayers] = useState([]);
    const [teamBReservePlayers, setTeamBReservePlayers] = useState([]);

    const handleOpenModal = (role, team) => {
        setPlayer({ playerName: '', role: role, designation: '', isPlaying: true });
        setCurrentTeam(team);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = () => {
        const newPlayer = { ...player };

        if (currentTeam === 'TeamA') {
            setTeamAPlayers([...teamAPlayers, newPlayer]);
        } else if (currentTeam === 'TeamB') {
            setTeamBPlayers([...teamBPlayers, newPlayer]);
        }

        handleCloseModal();
    };

    const [showreserveModal, setShowreserveModal] = useState(false);

    const handlereserveOpenModal = (role, team) => {
        setPlayer({ playerName: '', role: role, designation: '', isPlaying: false });
        setCurrentTeam(team);
        setShowreserveModal(true);
    };

    const handlereserveCloseModal = () => {
        setShowreserveModal(false);
    };

    const handlereserveSubmit = () => {
        const newPlayer = { ...player };

        if (currentTeam === 'TeamA') {
            setTeamAReservePlayers([...teamAReservePlayers, newPlayer]);
        } else if (currentTeam === 'TeamB') {
            setTeamBReservePlayers([...teamBReservePlayers, newPlayer]);
        }
        handlereserveCloseModal();
    };


    const [showTossModal, setShowTossModal] = useState(false);

    const handleOpenTossModel = () => {
        setShowTossModal(true)
    }

    const handleCloseTossModal = () => {
        setShowTossModal(false);
    };

    const [toss, settoss] = useState();
    const [battingFirst, setbattingFirst] = useState();

    const handleTossSubmit = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!toss) {
                toast.error('Please select toss first');
                return
            }
            if (!battingFirst) {
                toast.error('Please Select battingFirst....');
                return
            }

            let data = {
                toss,
                battingFirst
            }
            setIsUploading(true);
            const response = await apiPost(`api/game/UpdateToss/${id}`, data);
            console.log(response?.data)
            setIsUploading(false);
            toast.success(response?.message)
            navigate(`/GetScoreCard/${id}`)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const [Game, setGame] = useState({});

    const GetAGame = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/game/GetAGame/${id}`);
            setGame(response.data);
        } catch (error) {
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        GetAGame();
    }, [id]);


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='addturf-div'>
                            <br />
                            <br />
                            <br />

                            <div className="addturf">
                                <h1 style={{ textAlign: 'center', fontFamily: 'Oxanium', fontSize: '30px', margin: '20px 0' }}>Create Your Teams</h1>
                                <br />
                                <br />
                                <div className="teams-container">
                                    <div className="team">
                                        <h2>Team A</h2>
                                        <div className="designation-grid">
                                            {Array.from({ length: Game?.NumberofPlayer }, (_, index) => {
                                                const player = teamAPlayers[index];

                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() => handleOpenModal(player ? player.role : `Player ${index + 1}`, 'TeamA')}
                                                        className={`designation-btn ${index === 0 ? 'active' : ''}`}
                                                    >
                                                        {player ? `${player.playerName} - ${player.role}` : `Player ${index + 1}`}
                                                    </button>
                                                );
                                            })}
                                            <h2>Reserve Player</h2>
                                            {Array.from({ length: Game?.ReservePlayer }, (_, index) => {
                                                const player = teamAReservePlayers[index];

                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() => handlereserveOpenModal(player ? player.role : `Player ${index + 1}`, 'TeamA')}
                                                        className={`designation-btn ${index === 0 ? 'active' : ''}`}
                                                    >
                                                        {player ? `${player.playerName} - ${player.role}` : `Player ${index + 1}`}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="team">
                                        <h2>Team B</h2>
                                        <div className="designation-grid">
                                            {Array.from({ length: Game?.NumberofPlayer }, (_, index) => {
                                                const player = teamBPlayers[index];
                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() => handleOpenModal(player ? player.role : `Player ${index + 1}`, 'TeamB')}
                                                        className={`designation-btn ${index === 0 ? 'active' : ''}`}
                                                    >
                                                        {player ? `${player.playerName} - ${player.role}` : `Player ${index + 1}`}
                                                    </button>
                                                );
                                            })}
                                            <h2>Reserve Player</h2>
                                            {Array.from({ length: Game?.ReservePlayer }, (_, index) => {
                                                const player = teamBReservePlayers[index];
                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={() => handlereserveOpenModal(player ? player.role : `Player ${index + 1}`, 'TeamB')}
                                                        className={`designation-btn ${index === 0 ? 'active' : ''}`}
                                                    >
                                                        {player ? `${player.playerName} - ${player.role}` : `Player ${index + 1}`}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="center-btn">
                                    <button onClick={() => { handleSubmitTeams() }}>Submit</button>
                                </div>
                            </div>

                            <AddPlayerModel
                                show={showModal}
                                onClose={handleCloseModal}
                                onSubmit={handleSubmit}
                                player={player}
                                setPlayer={setPlayer}
                                teamPlayers={currentTeam === 'TeamA' ? teamAPlayers : teamBPlayers}
                            />
                            <AddReserveplayerModel
                                show={showreserveModal}
                                onClose={handlereserveCloseModal}
                                onSubmit={handlereserveSubmit}
                                player={player}
                                setPlayer={setPlayer}
                                teamPlayers={currentTeam === 'TeamA' ? teamAPlayers : teamBPlayers}
                            />
                            <UpdateToss
                                show={showTossModal}
                                onClose={handleCloseTossModal}
                                settoss={settoss}
                                setbattingFirst={setbattingFirst}
                                toss={toss}
                                battingFirst={battingFirst}
                                handleTossSubmit={handleTossSubmit}
                            />

                        </div>
                        <Footer />

                    </>
            }
        </>
    )
}

export default CreateTeam