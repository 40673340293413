import React from 'react'
import logo from '../Assets/Ddsgnr Library.svg'
import Kreeda_Logo from '../Assets/Kreeda_Logo.png'
import img1 from '../Assets/Group.svg'
import img2 from '../Assets/Group-1.svg'
import img3 from '../Assets/linkedin.svg'

import { Link, useNavigate } from 'react-router-dom'

function Footer() {
  const navigate = useNavigate();

  function scrollToTop(screen) {
    navigate(`/${screen}`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <>
      <div className='footer'>
        {/* <img src={logo} /> */}
        <img style={{ width: '80px', height: '80px', objectFit: 'contain' }} src={Kreeda_Logo} alt="Kreeda Logo" />
        <div className='foot-social'>
          <img
            src={img1}
            onClick={() => window.open("https://x.com/KreedaConnect", "_blank")}
            style={{ cursor: 'pointer' }}
          />
          <img
            src={img2}
            onClick={() => window.open("https://www.instagram.com/kreeda.connect?igsh=ZG9hYnYyMmtuajB6", "_blank")}
            style={{ cursor: 'pointer' }}
          />
          <img
            src={img3}
            onClick={() => window.open("https://www.linkedin.com/company/kreeda-connect/", "_blank")}
            style={{ cursor: 'pointer' }}
          />

        </div>
        <ul>
          <li onClick={() => { scrollToTop('') }}>Home</li>
          <li onClick={() => { scrollToTop('Turfvanues') }}>Turf</li>
          <li onClick={() => { scrollToTop('Institutes') }}>Institutes</li>
          <li onClick={() => { scrollToTop('TurfOwner/login') }}>Turf Owner/ Institute</li>
        </ul>
        <br />
        <div className='TermsandConditionsdiv'>
          <ul>
            <li onClick={() => { scrollToTop('TermsandConditions') }}>Terms and Conditions</li>
            <li onClick={() => { scrollToTop('PrivacyPolicy') }}>Privacy Policy</li>
            <li onClick={() => { scrollToTop('CancellationandReturnPolicies') }}>Cancellation and Return Policies</li>
          </ul>
        </div>
        <br />
        <p className="footer-text">© 2024 All rights reserved, kreeda-connect</p>
      </div>
    </>
  )
}

export default Footer