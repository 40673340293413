import React from 'react';

const BlockProgramSlotsModel = ({
    show,
    onClose,
    Slots,
    setSlots,
    BlockSlots,
    UnBlockSlots
}) => {
    if (!show) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Block\UnBlock Program slots</h2>
                <br />
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Slots</label>
                        <input
                            placeholder='Slots'
                            value={Slots}
                            onChange={(e) => { setSlots(e.target.value) }}
                        />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <button onClick={BlockSlots}>Block</button>
                <button onClick={UnBlockSlots}>UnBlock</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default BlockProgramSlotsModel;


