import React, { useEffect, useState } from 'react';
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';
import img from '../../../Assets/image 4.png';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Create_Api } from '../../../ApiUrls';
import { ClipLoader } from 'react-spinners';

function Signup() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };


  const navigate = useNavigate()
  const [IsUploading, setIsUploading] = useState(false);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Number, setNumber] = useState('');
  const [Password, setPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');

  const SignUpTurfOwner = async () => {
    if (!Name || !Email || !Number || !Password || !ConfirmPassword) {
      toast.error('All fields are required!');
      return;
    }
    if (Number.length !== 10) {
      toast.error('Please Enter Correct Phone Number');
      return;
    }
    if (Password !== ConfirmPassword) {
      toast.error('Password and Confirm Password must match!');
      return;
    }
    try {
      setIsUploading(true)
      let data = {
        Name,
        Email,
        Number: '91' + Number,
        Type: "Turf",
        Password
      }
      const response = await Create_Api(`api/turf/register`, data)
      console.log('User register Successfull:', response);
      localStorage.setItem('user', JSON.stringify(response?.data));
      localStorage.setItem('userType', JSON.stringify('TurfOwner'));
      localStorage.setItem('userid', response?.data?.Turf?._id);
      localStorage.setItem('userToken', response?.data?.token);
      toast.success(response?.message);
      setName('')
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      navigate('/')
      setIsUploading(false)
    } catch (error) {
      toast.success(error?.response?.data?.message);
      setIsUploading(false)
      console.log(error)
    }
  }
  const CoachingInstituteSignUp = async () => {
    if (!Name || !Email || !Number || !Password || !ConfirmPassword) {
      toast.error('All fields are required!');
      return;
    }
    if (Number.length !== 10) {
      toast.error('Please Enter Correct Phone Number');
      return;
    }

    if (Password !== ConfirmPassword) {
      toast.error('Password and Confirm Password must match!');
      return;
    }
    try {
      setIsUploading(true)
      let data = {
        Name,
        Email,
        Number: '91' + Number,
        Type: "CoachingInstitute",
        Password
      }
      const response = await Create_Api(`api/coach/register`, data)
      console.log('User register Successfull:', response);
      localStorage.setItem('user', JSON.stringify(response?.data));
      localStorage.setItem('userType', JSON.stringify('CoachingInstitute'));
      localStorage.setItem('userid', response?.data?.institude?._id);
      localStorage.setItem('userToken', response?.data?.token);
      toast.success(response?.message);
      setName('')
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      navigate('/')
      setIsUploading(false)
    } catch (error) {
      toast.success(error?.response?.data?.message);
      setIsUploading(false)
      console.log(error)
    }
  }


  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);


  return (
    <>
      <Navbar city={city} setcity={setcity} />
      <div className='login'>
        <div className="login-tebs">
          <div className="login-teb">
            <button
              className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
              onClick={() => handleTabChange(1)}
            >
              Turf Owner
            </button>
            <button
              className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
              onClick={() => handleTabChange(2)}
            >
              Institute
            </button>
          </div>
          <br />
          <br />
        </div>
        <div className="tab-content">
          {activeTab === 1 &&
            <>
              <div className='login-section'>
                <div className='login-left'>
                  <img src={img} />
                </div>
                <div className='login-right'>
                  <div className='login-right-div'>
                    <h3>Sign Up</h3>
                    <br />
                    <br />
                    <br />
                    <label>Name </label>
                    <br />
                    <input
                      placeholder='Name'
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    <label>Email </label>
                    <br />
                    <input
                      placeholder='Email '
                      value={Email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <br />
                    <label>Phone Number </label>
                    <br />
                    <input
                      placeholder='Number '
                      value={Number}
                      onChange={(e) => { setNumber(e.target.value) }}
                    />
                    <br />
                    <label>Password </label>
                    <br />
                    <input
                      placeholder='Enter Password'
                      type='password'
                      value={Password}
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                    <br />
                    <label>Confirm Password </label>
                    <br />
                    <input
                      placeholder='Confirm Password'
                      type='password'
                      value={ConfirmPassword}
                      onChange={(e) => { setConfirmPassword(e.target.value) }}
                    />
                    <br />
                    {IsUploading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                        <ClipLoader color="#31ED31" size={50} />
                      </div>
                    ) : (
                      <button className='tab-btn active' onClick={() => { SignUpTurfOwner() }}>Sign Up</button>
                    )}

                    <br />
                    <br />
                    <p>Already Have an Account?</p>
                    <br />
                    <Link style={{ textDecoration: 'none' }} to='/TurfOwner/login'>
                      <h4>Log In ?</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          }
          {activeTab === 2 &&
            <>
              <div className='login-section'>
                <div className='login-left'>
                  <img src={img} />
                </div>
                <div className='login-right'>
                  <div className='login-right-div'>
                    <h3>Sign Up</h3>
                    <br />
                    <br />
                    <br />
                    <label>Name </label>
                    <br />
                    <input
                      placeholder='Name'
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    <label>Email </label>
                    <br />
                    <input
                      placeholder='Email '
                      value={Email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                    <br />
                    <label>Phone Number </label>
                    <br />
                    <input
                      placeholder='Number '
                      value={Number}
                      onChange={(e) => { setNumber(e.target.value) }}
                    />
                    <br />
                    <label>Password </label>
                    <br />
                    <input
                      placeholder='Enter Password'
                      type='password'
                      value={Password}
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                    <br />
                    <label>Confirm Password </label>
                    <br />
                    <input
                      placeholder='Confirm Password'
                      type='password'
                      value={ConfirmPassword}
                      onChange={(e) => { setConfirmPassword(e.target.value) }}
                    />
                    <br />
                    {IsUploading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                        <ClipLoader color="#31ED31" size={50} />
                      </div>
                    ) : (
                      <button className='tab-btn active' onClick={() => { CoachingInstituteSignUp() }}>Sign Up</button>
                    )}
                    <br />
                    <br />
                    <p>Already Have an Account?</p>
                    <br />
                    <Link style={{ textDecoration: 'none' }} to='/TurfOwner/login'>
                      <h4>Log In ?</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Signup