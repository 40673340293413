import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import cancel from '../Assets/cancel1-svgrepo-com 3.png'
import ticket from '../Assets/coupon-svgrepo-com 1.png'
import arrow from '../Assets/arrow-prev-svgrepo-com 9.png'
import yes from '../Assets/yes-alt-svgrepo-com 1.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { apiPost, apiPut, Get_Api } from '../ApiUrls'
import { toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners'
import BookingSuccess from './add/BookingSuccessFulModel'
function SportTurf() {

    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const bookingData = location.state?.data;

    const totalAmount = bookingData?.reduce((acc, slot) => acc + slot?.price, 0);
    const convenienceFees = bookingData?.reduce((acc, slot) => acc + slot?.convenienceFees, 0);
    const total = totalAmount + convenienceFees;



    const [Turfdetails, setTurfdetails] = useState();
    const [Turfreview, setTurfreview] = useState([]);
    const [IsUploading, setIsUploading] = useState();


    const GetTurf = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetTurf/${id}`);
            setTurfdetails(response.data?.Getturf);
            setTurfreview(response.data?.reviews);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetTurf();
    }, [id]);


    const [IsUser, setIsUser] = useState(false);
    const [IsInstitute, setIsInstitute] = useState(false);
    const [IsTurfOwner, setIsTurfOwner] = useState(false);


    useEffect(() => {
        const userType = JSON.parse(localStorage.getItem("userType"));

        if (userType === "User") {
            setIsUser(true);
        }
        if (userType === "CoachingInstitute") {
            setIsInstitute(true);
        }
        if (userType === "TurfOwner") {
            setIsTurfOwner(true);
        }
    }, []);



    const BookingIds = bookingData?.map(item => item._id);

    // const UpdatepaymentDetails = async () => {
    //     try {
    //         let data = {
    //             bookingIds: BookingIds,
    //             phonepe_orderID: "orderId12345",
    //             PaymentStatus: true,
    //             status: "booked"
    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/user/UpdateTurfpaymentStatus`, data);
    //         console.log(response?.data)
    //         toast.success(response?.data);
    //         setIsUploading(false);
    //         navigate('/')
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message);
    //         console.error('Error fetching data:', error);
    //     }
    // }

    // const UpdateInstitutepaymentDetails = async () => {
    //     try {
    //         let data = {
    //             bookingIds: BookingIds,
    //             phonepe_orderID: "orderId12345",
    //             PaymentStatus: true,
    //             status: "booked"
    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/coach/UpdateTurfPaymentStatus`, data);
    //         console.log(response?.data)
    //         toast.success(response?.data);
    //         setIsUploading(false);
    //         navigate('/')
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message);
    //         console.error('Error fetching data:', error);
    //     }
    // }

    // const UpdateturfOwnerpaymentDetails = async () => {
    //     try {
    //         let data = {
    //             bookingIds: BookingIds,
    //             phonepe_orderID: "orderId12345",
    //             PaymentStatus: true,
    //             status: "booked"
    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/turf/UpdateTurfOwnerPaymentStatus`, data);
    //         console.log(response?.data)
    //         toast.success(response?.data);
    //         setIsUploading(false);
    //         navigate('/')
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message);
    //         console.error('Error fetching data:', error);
    //     }
    // }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const CreateBactch = async () => {
        try {
            let data = {
                slotid: BookingIds,
            };
            setIsUploading(true);
            const response = await apiPost(`api/user/CreateBatch`, data);
            setIsUploading(false);
            console.log(response?.data?._id)
            PhonepeCalling(response?.data?._id)
        } catch (error) {
            setIsUploading(false);
            toast.error(error?.response?.data?.message || "An error occurred");
            console.error("Error Create batch:", error);
        }
    }


    const PhonepeCalling = async (id) => {
        try {
            const userid = localStorage.getItem("userid");
            let data = {
                Userid: userid,
                amount: total,
                merchantUserId: id,
            };
            setIsUploading(true);

            const response = await apiPost(`api/Phonepe/phoneperequest`, data);
            setIsUploading(false);

            const redirectUrl = response?.data?.data?.instrumentResponse?.redirectInfo?.url;

            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                toast.error("Failed to fetch PhonePe redirect URL");
            }
        } catch (error) {
            setIsUploading(false);
            toast.error(error?.response?.data?.message || "An error occurred");
            console.error("Error initiating payment:", error);
        }
    };


    return (
        <> {
            IsUploading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                    <ClipLoader color="#31ED31" size={200} />
                </div>
                :
                <>
                    <Navbar city={city} setcity={setcity} />
                    <br />
                    <div className='sloat-heading-div'>
                        <div className='sloat-heading'>
                            <div className='sloat-heading1'>
                                <h2>{Turfdetails?.Name}</h2>
                                <div className='green-review'>
                                    <p>0.0  <i class="bi bi-star-fill"></i></p>
                                </div>
                                <h4>({Turfreview?.length || 0} reviews)</h4>
                            </div>
                            <p>Address - {Turfdetails?.Location}</p>
                            <p>Dimension - {Turfdetails?.Dimension}</p>
                        </div>

                    </div>
                    <div className='slotdetail-div'>
                        <div className='slotdetail'>
                            <div className='slotdetail-heading'>
                                <h3>Slot Detail ({bookingData?.length?.toString()?.padStart(2, '0')})</h3>
                                <h4>view all</h4>
                            </div>
                            <br />
                            <p>{new Date(bookingData[0]?.date).toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' })}</p>
                            <br />

                            <div className='slot-cards'>
                                {bookingData?.map((slot, index) => (
                                    <div className='slot-card' key={slot._id}>
                                        <div className='slot-card-heading'>
                                            {/* <p>{slot?.startTime}-{slot?.endTime}</p> */}
                                            <p>
                                                {new Date(`1970-01-01T${slot?.startTime}`).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })} -
                                                {new Date(`1970-01-01T${slot?.endTime}`).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })}
                                            </p>
                                            <img src={cancel} alt='Cancel Slot' />
                                        </div>
                                        <br />
                                        <br />
                                        <div className='slot-card-heading'>
                                            <h4>Sport Turf</h4>
                                            <h3>Rs {slot?.price}</h3>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <br />
                        </div>
                        <br />
                        <br />
                        <div className='slotdetail-div'>
                            <div className='slotdetail'>
                                <div className='apply-coupon'>
                                    <h2>
                                        <img src={ticket} alt='Apply Coupon' />
                                        Apply Coupon <span>(2 Coupons Available)</span>
                                    </h2>
                                    <img src={arrow} alt='Arrow Icon' />
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className='slotdetail slotdetail2'>
                            <div className='apply-coupon apply-coupon2'>
                                <h2>Booking Summary</h2>
                                <br />
                                <br />
                                <ul>
                                    <li>Sports</li>
                                    <li className='cat'>Cricket</li>
                                </ul>
                                <br />
                                <br />
                                <ul>
                                    <li>Total Slot(s) Base Price</li>
                                    <li className='cat'>Rs {totalAmount}</li>
                                </ul>
                                <br />
                                <br />
                                <ul>
                                    <li>Convenience Fees</li>
                                    <li className='cat'>Rs {convenienceFees}</li>
                                </ul>
                                <br />
                                <br />
                                <br />
                                <br />
                                <ul>
                                    <li>Total</li>
                                    <li className='cat'>Rs {total}</li>
                                </ul>
                                <br />
                                <br />
                                <div className='line'></div>
                                <br />
                                <br />
                                <ul>
                                    <li className='cat'>Total</li>
                                    <li className='cat'>Rs {total}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />

                    <div className='yes'>
                        <p><img src={yes} /> I here by agree to the Terms & Conditions of Kreeda</p>
                        <br />
                        <p><img src={yes} /> I here by agree to the Terms & Conditions of Kreeda</p>
                    </div>
                    <br />
                    <br />
                    {
                        IsUser &&
                        <div className='payment-btn'>
                            <p>Rs {total} INCL. Taxes</p>
                            <button onClick={() => { CreateBactch() }}>Proceed To Pay</button>
                        </div>
                    }
                    {IsInstitute &&
                        <div className='payment-btn'>
                            <p>Rs {total} INCL. Taxes</p>
                            <button onClick={() => { CreateBactch() }}>Proceed To Pay</button>
                        </div>
                    }
                    {IsTurfOwner &&
                        <div className='payment-btn'>
                            <p>Rs {total} INCL. Taxes</p>
                            <button onClick={() => { CreateBactch() }}>Proceed To Pay</button>
                        </div>
                    }
                    {/* <BookingSuccess/> */}
                    <Footer />
                </>
        }
        </>
    )
}

export default SportTurf