import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import success from '../Assets/success.svg';
import { useNavigate } from 'react-router-dom';

function SuccessBooking() {
    const navigate = useNavigate()
    const [city, setcity] = useState('');
    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore');
    }, []);



    return (
        <div>
            <Navbar city={city} setcity={setcity} />
            <div className="success-booking-container">
                <img src={success} alt="Success Icon" className="success-icon" />
                <h2 className="success-heading">Your Booking Is Successful</h2>
                <button onClick={() => { navigate('/') }} className="back-to-home-btn">Back To Home</button>
            </div>
            <Footer />
        </div>
    );
}

export default SuccessBooking;
