import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { apiGet, Get_Api } from '../ApiUrls';
import img from '../Assets/Rectangle 21.svg'
import { useNavigate } from 'react-router-dom';

function AllCoachOfCoach() {

    const [AllCoach, setAllCoach] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);
    const navigate = useNavigate()
    const GetAllCoach = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet('api/coach/GetAllMyCoach');
            setAllCoach(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetAllCoach();
    }, []);

    const Coachdetails = (id) => {
        navigate(`/InstituteCoachDetails/${id}`)
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
            setcity(localcity||'Indore')
    }, []);

    return (
        <>
            <Navbar city={city} setcity={setcity}/>
            <div className='reg-banner'>
                <div className='reg-banner-div reg-banner-div2'>
                    <h2>Find Perfect Coach</h2>
                </div>
            </div>
            <div className='available-venues'>
                <div className='available-venues-div'>
                    <div className='available-venues-heading'>
                        <h2>Institute Coach ({AllCoach?.length || 0})</h2>
                    </div>
                    <br />
                    <br />
                    <div className='game-cards'>
                        {AllCoach?.map((Coach, index) => (
                            <div key={index}>
                                <div className='game-card'>
                                    <div style={{ backgroundImage: `url(${Coach?.profileimage || img})` }} className='game-card-img'>
                                    </div>
                                    <br />
                                    <h3>{Coach?.Name}</h3>
                                    <p>{Coach?.Location}</p>
                                    <h4>Rs.1080 / 3 months</h4>
                                    <button onClick={() => { Coachdetails(Coach?._id) }}>Detailed View</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AllCoachOfCoach