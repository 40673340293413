import React, { useEffect, useState } from 'react';
import { apiGet } from '../ApiUrls';

const UpdateProgram = ({ show, onClose, ProgramName, Coach, StartDate, TotalSlots, Price, KeyFeatures, setProgramName, setCoach, setStartDate, setTotalSlots, setPrice, setKeyFeatures, UpdatePrograms }) => {


    const [AllCoach, setAllCoach] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);
    const formatDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const GetCoach = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet('api/coach/GetAllMyCoach');
            setAllCoach(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetCoach();
    }, []);



    if (!show) return null;
    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Update Program</h2>
                <br />
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Program Name</label>
                            <input
                                placeholder='ProgramName'
                                value={ProgramName}
                                onChange={(e) => { setProgramName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label style={{ marginTop: 0 }}>Coach</label>
                            <select
                                value={Coach?.Name}
                                onChange={(e) => setCoach(e.target.value)}
                            >
                                {AllCoach.map((coach) => (
                                    <option key={coach._id} value={coach._id}>
                                        {coach.Name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>StartDate</label>
                            <input
                                placeholder='StartDate'
                                value={formatDate(StartDate)}
                                type='date'
                                onChange={(e) => { setStartDate(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>TotalSlots</label>
                            <input
                                placeholder='TotalSlots'
                                value={TotalSlots}
                                onChange={(e) => { setTotalSlots(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Price</label>
                            <input
                                placeholder='Price'
                                value={Price}
                                onChange={(e) => { setPrice(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Key Features</label>
                            <input
                                placeholder='KeyFeatures'
                                value={KeyFeatures}
                                onChange={(e) => { setKeyFeatures(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <button onClick={() => { UpdatePrograms() }}>Submit</button>
            </div>
        </div>

    );
};

export default UpdateProgram;
