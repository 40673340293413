import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import calender from '../Assets/date-round-svgrepo-com 1.png'
import day from '../Assets/day-forecast-hot-svgrepo-com 1.png'
import night from '../Assets/night-sky-svgrepo-com 2.png'
import cross from '../Assets/cancel1-svgrepo-com 1.png'
import reddot from '../Assets/reddot.svg'
import Greendot from '../Assets/Greendot.svg'
import Blackdot from '../Assets/Blackdot.svg'
import Yellowdot from '../Assets/Yellowdot.svg'
import { ClipLoader } from 'react-spinners';
import HorizontalCalendar from './add/HorizontalCalendar';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom'
import { apiPost, Get_Api } from '../ApiUrls'
import { toast } from 'react-toastify'

function AvailableSlot() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [Turfdetails, setTurfdetails] = useState();
  const [Turfreview, setTurfreview] = useState([]);
  const [IsUploading, setIsUploading] = useState();
  const [selectedDate, setSelectedDate] = useState(dayjs().format('DD-MM-YYYY'));


  const GetTurf = async () => {
    try {
      setIsUploading(true);
      const response = await Get_Api(`api/user/GetTurf/${id}`);
      setTurfdetails(response.data?.Getturf);
      setTurfreview(response.data?.reviews);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    GetTurf();
  }, [id]);

  const handleDayPress = (date) => {
    console.log(date?.dateString)
    setSelectedDate(date?.dateString)
  }


  const filteredSlots = Turfdetails?.Slots?.filter(slot =>
    dayjs(slot?.Date).format('DD-MM-YYYY') === selectedDate
  );

  const [selectedids, setSelectedIds] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [ConvenienceFees, setConvenienceFees] = useState(0);

  useEffect(() => {
    calculateConvenienceFees();
  }, [totalAmount]);

  const calculateConvenienceFees = () => {
    const calculatedFee = totalAmount * 0.05;
    const fee = Math.min(calculatedFee, 50);
    setConvenienceFees(fee.toFixed(2));
  };



  const handleSlotSelection = (timeSlotId, price) => {
    setSelectedIds(prevSelectedIds => {
      let updatedSelectedIds;
      let updatedTotalAmount = totalAmount;

      if (prevSelectedIds.includes(timeSlotId)) {
        updatedSelectedIds = prevSelectedIds.filter(id => id !== timeSlotId);
        updatedTotalAmount -= Number(price);
      } else {
        updatedSelectedIds = [...prevSelectedIds, timeSlotId];
        updatedTotalAmount += Number(price);
      }

      setTotalAmount(updatedTotalAmount);
      return updatedSelectedIds;
    });
  };





  const [IsUser, setIsUser] = useState(false);
  const [IsInstitute, setIsInstitute] = useState(false);
  const [IsTurfOwner, setIsTurfOwner] = useState(false);


  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));

    if (userType === "User") {
      setIsUser(true);
    }
    if (userType === "CoachingInstitute") {
      setIsInstitute(true);
    }
    if (userType === "TurfOwner") {
      setIsTurfOwner(true);
    }
  }, []);



  const BookSlots = async () => {
    try {
      if (!IsUser) {
        return toast.error('Please Login As User first....');
      }
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }

      let data = {
        convenienceFees: ConvenienceFees,
        slotIds: selectedids
      }
      setIsUploading(true);
      const response = await apiPost(`api/user/bookTurfSlot/${id}`, data);
      console.log(response?.data)
      navigate(`/sportturf/${id}`, { state: { data: response?.data } });
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const InstituteBookSlots = async () => {
    try {
      if (!IsInstitute) {
        return toast.error('Please Login As User first....');
      }
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }

      let data = {
        convenienceFees: ConvenienceFees,
        slotIds: selectedids
      }
      setIsUploading(true);
      const response = await apiPost(`api/coach/bookTurfSlotByInstitute/${id}`, data);
      console.log(response?.data)
      navigate(`/sportturf/${id}`, { state: { data: response?.data } });
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);


  return (
    <>
      {
        IsUploading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
            <ClipLoader color="#31ED31" size={200} />
          </div>
          :
          <>
            <Navbar city={city} setcity={setcity} />
            <br />
            <div className='sloat-heading-div'>
              <div className='sloat-heading'>
                <div className='sloat-heading1'>
                  <h2>{Turfdetails?.Name}</h2>
                  <div className='rate-div'>
                    <div className='green-review'>
                      <p>0.0  <i class="bi bi-star-fill"></i></p>
                    </div>
                    <h4>({Turfreview?.length || 0} reviews)</h4>
                  </div>
                </div>
                <p>Address - {Turfdetails?.Location}</p>
                <p>Dimension - {Turfdetails?.Dimension}</p>
              </div>
            </div>
            <br />
            <br />
            <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
              <HorizontalCalendar handleDayPress={handleDayPress} />
            </div>
            {selectedDate && filteredSlots?.length > 0 ? (
              <>
                <div className='NaturalGrass-div'>
                  <div className='NaturalGrass'>
                    <p>Natural Grass Turf</p>
                  </div>
                </div>
                <div className='slot-heading'>
                  <h3>Available Slots ({filteredSlots?.reduce((total, slot) => total + (slot?.Times?.length || 0), 0)})</h3>
                  <p>Min, 60 mins Slots</p>
                </div>
                {filteredSlots?.map((slot) => (
                  slot?.Times?.map((time) => {
                    const currentTime = new Date();
                    const bookTime = time?.BookTime ? new Date(time.BookTime) : null;

                    let timeDifferenceInMinutes = null;

                    if (bookTime && !isNaN(bookTime)) {
                      timeDifferenceInMinutes = (currentTime - bookTime) / (1000 * 60);
                    }
                    const isProcessing = timeDifferenceInMinutes !== null && timeDifferenceInMinutes <= 5;
                    return (
                      <div className='time-div' key={time?._id}>
                        <div className='time-divs'>
                          <div className='time-text'>
                            {/* <p>{time?.startTime} - {time?.endTime}</p> */}
                            <p>
                              {new Date(`1970-01-01T${time?.startTime}`).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })} -
                              {new Date(`1970-01-01T${time?.endTime}`).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })}
                            </p>
                            <p>Rs {time?.Price}</p>
                          </div>
                          {
                            time?.Booked ? (
                              <div className='time-text'>
                                <img src={Blackdot} alt="cross"   className="status-icon"/>
                                <p>Booked</p>
                              </div>
                            ) : time?.Blocked ? (
                              <div className='time-text'>
                                <img src={reddot} alt="cross"  className="status-icon" />
                                <p>Blocked</p>
                              </div>
                            ) : isProcessing ? (
                              <div className='time-text'>
                                <img src={Yellowdot} alt="processing"  className="status-icon" />
                                <p>Processing</p>
                              </div>
                            ) : (
                              <div className='time-text'>
                                <img src={Greendot} alt="check"   className="status-icon"/>
                                <p>Available</p>
                              </div>
                            )
                          }
                          <div className='SelectedSlot'>
                            <button
                              style={{
                                backgroundColor: time?.Booked
                                  ? '#696969'
                                  : time?.Blocked
                                    ? '#FF6347'
                                    : isProcessing
                                      ? '#FFD700'
                                      : selectedids.includes(time?._id)
                                        ? '#32CD32'
                                        : '#FF6347'
                              }}
                              onClick={() => !time?.Booked && !time?.Blocked && handleSlotSelection(time?._id, time?.Price)}
                              disabled={time?.Booked || time?.Blocked || isProcessing}
                            >
                              {time?.Booked ? 'Booked' : time?.Blocked ? 'Blocked' : isProcessing ? 'Processing' : selectedids.includes(time?._id) ? 'Selected' : 'Add'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ))}

              </>
            ) : (
              <div className='NaturalGrass-div'>
                <div className='NaturalGrass'>
                  <p>No available slots for the selected date.</p>
                </div>
              </div>
            )}
            <br />
            <br />
            <br />

            {selectedids.length !== 0 && (
              <>
                {!IsUser && !IsInstitute && (
                  <div className='venue-detail'>
                    <div className='venue-info'>
                      <div className='venue-text'>
                        <div className='venue-text-heading'>
                          <h2>Total Charge</h2> : <h2>{totalAmount}</h2>
                        </div>
                        <div className='venue-text-heading2'>
                          <div className='venue-text-heading2-text'>
                            <h2>Convenience Fees</h2> : <h2>{ConvenienceFees}</h2>
                          </div>
                          <button onClick={() => BookSlots()}>Book Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {IsUser && (
                  <div className='venue-detail'>
                    <div className='venue-info'>
                      <div className='venue-text'>
                        <div className='venue-text-heading'>
                          <h2>Total Charge</h2> : <h2>{totalAmount}</h2>
                        </div>
                        <div className='venue-text-heading2'>
                          <div className='venue-text-heading2-text'>
                            <h2>Convenience Fees</h2> : <h2>{ConvenienceFees}</h2>
                          </div>
                          <button onClick={() => BookSlots()}>Book Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {IsInstitute && (
                  <div className='venue-detail'>
                    <div className='venue-info'>
                      <div className='venue-text'>
                        <div className='venue-text-heading'>
                          <h2>Total Charge</h2> : <h2>{totalAmount}</h2>
                        </div>
                        <div className='venue-text-heading2'>
                          <div className='venue-text-heading2-text'>
                            <h2>Convenience Fees</h2> : <h2>{ConvenienceFees}</h2>
                          </div>
                          <button onClick={() => InstituteBookSlots()}>Book Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <br />
            <br />
            <Footer />
          </>
      }
    </>
  )
}

export default AvailableSlot