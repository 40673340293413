import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img3 from '../Assets/Rectangle 22027.png'
import loc from '../Assets/system-uicons_location.png'
import date from '../Assets/date-svgrepo-com 5.png'
import pro from '../Assets/Ellipse 6.png'
import time from '../Assets/time-filled-svgrepo-com 1.png'
import { ClipLoader } from 'react-spinners';
import HorizontalCalendar from './add/HorizontalCalendar';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom'
import { apiGet, apiPost, apiPut, formatDate, formatDateonly, Get_Api } from '../ApiUrls'
import { toast } from 'react-toastify'
import AddMoreSlots from '../Components/AddMoreSlots'
import CancelTurfBookingModel from '../Components/CancelTurfBookingModel'

function AllBookingOfATurf() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [Turfdetails, setTurfdetails] = useState();
    const [Turfreview, setTurfreview] = useState([]);
    const [IsUploading, setIsUploading] = useState();
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [TurfBookings, setTurfBookings] = useState([]);

    const GetTurf = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetTurf/${id}`);
            setTurfdetails(response.data?.Getturf);
            setTurfreview(response.data?.reviews);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const GetATurfBooking = async () => {
        try {
            console.log(selectedDate, 'aaaa')
            setIsUploading(true);
            const response = await apiGet(`api/turf/GetAtrufBooking/${id}?date=${selectedDate}`);
            setTurfBookings(response?.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetTurf();
        GetATurfBooking();
    }, [id]);

    useEffect(() => {
        GetATurfBooking();
    }, [selectedDate]);

    const handleDayPress = (date) => {
        const [day, month, year] = date?.dateString.split('-');
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDate(formattedDate);
    };

    const [IsUser, setIsUser] = useState(false);
    const [IsInstitute, setIsInstitute] = useState(false);
    const [IsTurfOwner, setIsTurfOwner] = useState(false);


    useEffect(() => {
        const userType = JSON.parse(localStorage.getItem("userType"));

        if (userType === "User") {
            setIsUser(true);
        }
        if (userType === "CoachingInstitute") {
            setIsInstitute(true);
        }
        if (userType === "TurfOwner") {
            setIsTurfOwner(true);
        }
    }, []);


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    const [CancelturfModel, setCancelturfModel] = useState(false);
    const [turfBookingId, setturfBookingId] = useState();
    const [refundMessage, setRefundMessage] = useState("");
    const [refundAmount, setrefundAmount] = useState();

    const getRefundMessage = (DateandTime, price) => {
        const bookingDateTime = new Date(DateandTime);
        const currentTime = new Date();
        const timeDifference = (bookingDateTime - currentTime) / (1000 * 60 * 60);
    
        let message = "";
        let refund = 0;
    
        if (timeDifference > 6) {
          message = "A 100% refund will be initiated for cancellations made more than 6 hours before the booked slot.";
          refund = price;
        } else if (timeDifference > 4) {
          message = "Only 70% of the amount will be refunded for cancellations made 4-6 hours before the booked slot.";
          refund = price * 0.7;
        } else if (timeDifference > 2) {
          message = "Only 50% of the amount will be refunded for cancellations made 2-4 hours before the booked slot.";
          refund = price * 0.5;
        } else {
          message = "No refund will be provided for cancellations made within 2 hours of the booked slot.";
          refund = 0;
        }
    
        setrefundAmount(refund);
        return message;   
    };

    const OpenCancelBooking = (booking) => {
        setRefundMessage(getRefundMessage(booking?.DateandTime,booking?.price));
        setCancelturfModel(true);
        setturfBookingId(booking._id);
    };

    const CloseCancelBooking = () => {
        setCancelturfModel(false)
    }

    const [reason, setreason] = useState('');


    const CancelturfBooking = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            let data = {
                reason,
                refundAmount
            }
            setIsUploading(true);
            const response = await apiPost(`api/turf/cancelTurfBooking/${turfBookingId}`, data);
            console.log(response?.data)
            setreason('')
            setIsUploading(false);
            toast.success(response?.message)
            CloseCancelBooking();
            GetATurfBooking();
            GetTurf();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }

    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <br />
                        <div className='sloat-heading-div'>
                            <div className='sloat-heading'>
                                <div className='sloat-heading1'>
                                    <h2>{Turfdetails?.Name}</h2>
                                    <div className='rate-div'>
                                        <div className='green-review'>
                                            <p>0.0  <i class="bi bi-star-fill"></i></p>
                                        </div>
                                        <h4>({Turfreview?.length || 0} reviews)</h4>
                                    </div>
                                </div>
                                <p>Address - {Turfdetails?.Location}</p>
                                <p>Dimension - {Turfdetails?.Dimension}</p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
                            <HorizontalCalendar handleDayPress={handleDayPress} />
                        </div>
                        <br />
                        <br />
                        <div className='bookingPadding'>
                            <div className='mybooking-heading'>
                                <h3>All Turf Bookings ({TurfBookings?.length || 0})</h3>
                            </div>
                            <br />
                            <div className='all-bookings-div'>
                                {TurfBookings?.map((slot, index) => {
                                    const bookingDateAndTime = new Date(slot?.DateandTime);
                                    const currentDate = new Date();
                                    const isPastBooking = bookingDateAndTime < currentDate;

                                    return (
                                        <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                                            <img  className='all-bookingsimg' src={slot?.turf?.Images[0] ? slot?.turf?.Images[0] : img3} />
                                            <div className='booking-text'>
                                                <h3>{slot?.turf?.Name}</h3>
                                                <h4>Contact - {slot?.user?.Name || slot?.InstituteOwnerId?.Name || "Self Booking"} {slot?.user?.phoneNumber || slot?.InstituteOwnerId?.Email}</h4>
                                                <p><img src={loc} />{slot?.turf?.Location}</p>
                                                <h4>Rs {(slot?.totalAmount || 0) + (slot?.convenienceFees || 0)}</h4>
                                                <div className='booking-timing'>
                                                    <p><img src={date} />{formatDateonly(slot?.date)}</p>
                                                    <p><img src={time} />{slot?.startTime} / {slot?.endTime}</p>
                                                    <p>
                                                        <img src={pro} />
                                                        {slot?.BookByOwner ? (
                                                            "Self Booking"
                                                        ) : slot?.BookByInstitute ? (
                                                            <>
                                                                Institute Owner - {slot?.InstituteOwnerId?.Name}({slot?.InstituteOwnerId?.Email})
                                                            </>
                                                        ) : (
                                                            <>
                                                                User - {slot?.user?.Name} ({slot?.user?.phoneNumber})
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                                <div className='booking-timing'>
                                                    <p><img src={time} />Booked Date - {formatDate(slot?.createdAt)}</p>
                                                </div>
                                                <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                                    <button
                                                        onClick={() => { OpenCancelBooking(slot) }}
                                                        className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                                        disabled={isPastBooking}
                                                    >
                                                        {slot?.Cancelrequest === 'Notcreated' ? "Cancel Booking" : slot?.Cancelrequest}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <CancelTurfBookingModel
                            show={CancelturfModel}
                            Close={CloseCancelBooking}
                            reason={reason}
                            setreason={setreason}
                            refundMessage={refundMessage}
                            CancelturfBooking={CancelturfBooking}
                        />
                        <Footer />
                    </>
            }
        </>
    )
}

export default AllBookingOfATurf