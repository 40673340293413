import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22038.png'
import exp from '../Assets/experience-svgrepo-com 1.png'
import toiletwashroom from '../Assets/toiletwashroom.svg'
import lightbulb from '../Assets/lightbulb.svg'
import room from '../Assets/room.svg'
import waterdrop from '../Assets/waterdrop.svg'
import firstaidkit from '../Assets/firstaidkit.svg'
import couchseats from '../Assets/couchseats.svg'
import cricket from '../Assets/cricket.svg'
import football from '../Assets/football.svg'
import heart from '../Assets/heart.svg'
import location from '../Assets/location.svg';
import system from '../Assets/system-uicons_location.png';
import Redheart from '../Assets/Redheart.svg'
import { apiPost, Get_Api } from '../ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import AddReview from './add/AddReview';
import { toast } from 'react-toastify';
import ImageSlider from '../Components/ImageSlider';

function TurfDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);


    // const handleTabChange = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
        document.getElementById(`tab-content-${tabNumber}`)?.scrollIntoView({ behavior: 'smooth' });
    };

    const [Turfdetails, setTurfdetails] = useState();
    const [Turfreview, setTurfreview] = useState([]);
    const [IsUploading, setIsUploading] = useState();
    const [Isliked, setIsliked] = useState(false);




    const GetTurf = async () => {
        try {
            const userid = localStorage.getItem("userid");
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetTurf/${id}`);
            setTurfdetails(response.data?.Getturf);
            let liked = response.data?.Getturf?.Likes;
            if (liked && liked.includes(userid)) {
                setIsliked(true);
            } else {
                setIsliked(false);
            }
            setTurfreview(response.data?.reviews);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetTurf();
    }, [id]);

    const AllSlots = (id) => {
        navigate(`/availableslot/${id}`)
    }

    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState("");

    const CreateReview = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            let data = {
                Turf: id,
                Rating: rating,
                Review: reviewText
            }
            setIsUploading(true);
            const response = await apiPost(`api/user/CreateTurfReview`, data);
            console.log(response?.data)
            toast.success(response?.message)
            setRating(0);
            setReviewText('');
            GetTurf();
            setIsUploading(false);
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.error('Error fetching data:', error);
        }
    }

    const LikeTurf = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            let data = {}
            setIsUploading(true);
            const response = await apiPost(`/api/user/Liketurf/${id}`, data);
            toast.success(response?.message)
            GetTurf();
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.error('Error fetching data:', error);
        }
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);



    const Openmap = (latitude, longitude) => {

        if (latitude && longitude) {
            // Construct the Google Maps URL with the latitude and longitude
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

            // Open the URL in a new tab
            window.open(mapUrl, '_blank');
        } else {
            alert("Location coordinates are not available.");
        }
    };


    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='venue-detail'>
                            <div className='venue-info' style={{ position: 'relative', display: 'inline-block' }}>
                                {/* <img src={Turfdetails?.profileimage ? img : img} alt="Turf" style={{ display: 'block' }} /> */}
                                <ImageSlider images={Turfdetails?.Images.length > 0 ? Turfdetails?.Images : [img]} />
                                <div style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img onClick={() => { LikeTurf() }} src={Isliked ? Redheart : heart} alt="Heart" style={{ width: '30px', height: '30px' }} />
                                </div>

                                <div className='venue-text'>
                                    <div className='venue-text-heading'>
                                        <h2>{Turfdetails?.Name}</h2>
                                        <div className='green-review'>
                                            <p>4.5 <i className="bi bi-star-fill"></i></p>
                                        </div>
                                        <h4>({Turfreview?.length || 0} reviews) (Likes - {Turfdetails?.Likes?.length})</h4>
                                    </div>

                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <p onClick={() => { Openmap(Turfdetails?.latitude, Turfdetails?.longitude) }}>{Turfdetails?.Location} <img src={system} className='sport-icon' style={{ marginLeft: '8px', width: 25, height: 25 }} /></p>
                                            <p><img src={exp} />Dimensions : {Turfdetails?.Dimension}</p>
                                        </div>
                                        <button onClick={() => { AllSlots(Turfdetails?._id) }}>Book Now</button>
                                    </div>
                                    <div className='venue-text-heading3'>
                                        <h3>Rs {Turfdetails?.Price}/- Slot</h3>
                                        <div className='clock'>

                                            <i className="bi bi-clock"></i>
                                            <p>6:00 Am -  11:59 Pm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="tab-container">
                            <div className="tabs">
                                <button
                                    className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(1)}
                                >
                                    About
                                </button>
                                <button
                                    className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(2)}
                                >
                                    Available Sports
                                </button>

                                <button
                                    className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(3)}
                                >
                                    Amenities
                                </button>
                                <button
                                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(5)}
                                >
                                    Rules
                                </button>
                                <button
                                    className={activeTab === 6 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(6)}
                                >
                                    Review
                                </button>

                            </div>
                            <br />
                            <br />
                            <div className="tab-content">
                                <div className='tab1'>
                                    <div id="tab-content-1" >
                                        <h3>About</h3>
                                        <p>{Turfdetails?.About}</p>
                                    </div>

                                    <br />
                                    <div id="tab-content-2" >
                                        <h3>Available Sports</h3>
                                        <br />
                                        <div className='Availablesport-btn'>
                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', padding: '5px' }}>
                                                <img src={cricket} style={{ height: 20, width: 20 }} />
                                                Box Cricket
                                            </button>
                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', padding: '5px' }}>
                                                <img src={football} style={{ height: 20, width: 20 }} />
                                                Football
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-3" >
                                        <h3>Amenities</h3>
                                        <br />
                                        <div className='Amenities-btn'>
                                            {Turfdetails?.Amenities?.changingRoom &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={room} />
                                                    <span style={{ marginTop: 10 }}>Changing Room</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.Drinkingwater &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={waterdrop} />
                                                    <span style={{ marginTop: 10 }}>Drinking Water</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.FirstAid &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={firstaidkit} />
                                                    <span style={{ marginTop: 10 }}>First Aid</span>
                                                </button>
                                            }
                                        </div>
                                        <br />
                                        <div className='Amenities-btn'>
                                            {Turfdetails?.Amenities?.Floodlight &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={lightbulb} />
                                                    <span style={{ marginTop: 10 }}>Flood Light</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.seatingLounge &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={couchseats} />
                                                    <span style={{ marginTop: 10 }}>Seating Lounge</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.washroom &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={toiletwashroom} />
                                                    <span style={{ marginTop: 10 }}>Washroom</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-5">
                                        <h3>Venue Rules</h3>
                                        <br />
                                        <ul>
                                            <li>{Turfdetails?.VenueRules}</li>
                                        </ul>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div id="tab-content-6" >
                                    <div>
                                        <div className="tab-content">
                                            <div className='tab1'>
                                                <h3>Reviews</h3>
                                                <p>★★★★★ 4.5 ({Turfreview?.length || 0} reviews)</p>
                                                {Turfreview?.length === 0 &&
                                                    <p>Be the first to review</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    {Turfreview?.slice(0, 5)?.map((slot, index) => (
                                        <div key={index} className="review-container">
                                            <div className="review-header">
                                                <img
                                                    src={img}
                                                    alt="User Profile"
                                                    className="user-profile-img"
                                                />
                                                <div>
                                                    <div className="user-name">{slot?.User?.Name}</div>
                                                    <div className="rating">
                                                        {Array.from({ length: slot?.Rating }, (_, i) => (
                                                            <span key={i} className="star">★</span>
                                                        ))}
                                                    </div>
                                                    <div className="review-text">{slot?.Review}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <br />
                                    <br />
                                    <AddReview rating={rating} reviewText={reviewText} CreateReview={CreateReview} setRating={setRating} setReviewText={setReviewText} />
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
            }
        </>
    )
}

export default TurfDetails