import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22038.png'
import exp from '../Assets/experience-svgrepo-com 1.png'
import { apiPost, Get_Api } from '../ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import HorizontalCalendar from './add/HorizontalCalendar';
import dayjs from 'dayjs';
import heart from '../Assets/heart.svg'
import Redheart from '../Assets/Redheart.svg'
import { toast } from 'react-toastify';
import AddReview from './add/AddReview';
import ImageSlider from '../Components/ImageSlider';
import location from '../Assets/location.svg';
import system from '../Assets/system-uicons_location.png';

function CoachDetail() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);

  const currentDate = dayjs().format('DD-MM-YYYY');

  // const handleTabChange = (tabIndex) => {
  //   setActiveTab(tabIndex);
  // };

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
    document.getElementById(`tab-content-${tabNumber}`)?.scrollIntoView({ behavior: 'smooth' });
  };


  const [Institutedetails, setInstitutedetails] = useState();
  const [AllPrograms, setAllPrograms] = useState([]);
  const [Institutereview, setInstitutereview] = useState([]);
  const [IsUploading, setIsUploading] = useState();
  const [Isliked, setIsliked] = useState(false);

  const GetInstitute = async () => {
    try {
      const userid = localStorage.getItem("userid");
      setIsUploading(true);
      const response = await Get_Api(`api/user/GetaInstitute/${id}`);
      setInstitutedetails(response.data);
      let liked = response.data?.Likes;
      if (liked && liked.includes(userid)) {
        setIsliked(true);
      } else {
        setIsliked(false);
      }
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetInstituteprograms = async (date) => {
    try {
      const response = await Get_Api(`api/user/GetAllProgramsofaInstitute/${id}?StartDate=${date}`);
      setAllPrograms(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const GetInstitutereview = async () => {
    try {
      const response = await Get_Api(`api/user/GetAllReviewofInstitute/${id}`);
      setInstitutereview(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    GetInstitute();
    GetInstituteprograms(currentDate)
    GetInstitutereview();
  }, [id]);

  const Allcoach = (id) => {
    navigate(`/ProgramDetails/${id}`)
  }

  const handleDayPress = (date) => {
    console.log(date?.dateString)
    GetInstituteprograms(date?.dateString)
  }


  const LikeInstitute = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }
      let data = {}
      setIsUploading(true);
      const response = await apiPost(`api/user/LikeInstitute/${id}`, data);
      toast.success(response?.message)
      GetInstitute();
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.error('Error fetching data:', error);
    }
  }


  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  const CreateReview = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }

      let data = {
        Institute: id,
        Rating: rating,
        Review: reviewText
      }
      setIsUploading(true);
      const response = await apiPost(`api/user/CreateInstiteReview`, data);
      console.log(response?.data)
      toast.success(response?.message)
      setRating(0);
      setReviewText('');
      GetInstitutereview();
      setIsUploading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.error('Error fetching data:', error);
    }
  }


  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);


  const Openmap = (latitude, longitude) => {

    if (latitude && longitude) {
      const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

      window.open(mapUrl, '_blank');
    } else {
      alert("Location coordinates are not available.");
    }
  };


  return (
    <>
      {
        IsUploading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
            <ClipLoader color="#31ED31" size={200} />
          </div>
          :
          <>
            <Navbar city={city} setcity={setcity} />
            <div className='venue-detail'>
              <div className='venue-info' style={{ position: 'relative', display: 'inline-block' }}>
                <ImageSlider images={Institutedetails?.Images.length > 0 ? Institutedetails?.Images : [img]} />
                <div style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <img onClick={() => { LikeInstitute() }} src={Isliked ? Redheart : heart} alt="Heart" style={{ width: '30px', height: '30px' }} />
                </div>
                <div className='venue-text'>
                  <div className='venue-text-heading'>
                    <h2>{Institutedetails?.Name}</h2>
                    <div className='green-review'>
                      <p>4.7 <i className="bi bi-star-fill"></i></p>
                    </div>
                    <h4>({Institutereview?.length || 0} reviews) (Likes - {Institutedetails?.Likes?.length})</h4>
                  </div>

                  <div className='venue-text-heading2'>
                    <div className='venue-text-heading2-text'>
                      <p onClick={() => { Openmap(Institutedetails?.latitude, Institutedetails?.longitude) }}>{Institutedetails?.Location} <img src={system} className='sport-icon' style={{ marginLeft: '8px', width: 25, height: 25 }} /></p>
                      <p><img src={exp} />Experience : 7 Years</p>
                    </div>
                    <button>Book Now</button>
                  </div>
                  <div className='venue-text-heading3'>
                    <h3>Rs 1080/- 3 month</h3>
                    <div className='clock'>

                      <i className="bi bi-clock"></i>
                      <p>6:00 Am -  11:59 Pm</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <br />
            <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
              <HorizontalCalendar handleDayPress={handleDayPress} />
            </div>
            <br />
            <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
              <div className='tab1'>
                <h3>Available Programs</h3>
              </div>
            </div>
            <br />
            {AllPrograms?.length === 0 ? (
              <div className='tab-content'>
                <div className='tab1'>
                  <h4 style={{ textAlign: 'center' }}>
                    No programs found
                  </h4>
                </div>
              </div>
            ) : (
              AllPrograms.map((program, index) => (
                <div key={index} className="tab-container2">
                  <div className="tabs">
                    <button >{program?.ProgramName}</button>
                    <button >Coach Name - {program?.Coach?.Name}</button>
                    <button >{program?.Price}/Slot</button>
                    <button className={'tab-btn active bookcoach'} onClick={() => Allcoach(program?._id)}>Book Now</button>
                  </div>
                </div>
              ))
            )}

            <div className="tab-container">
              <div className="tabs">
                <button
                  className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                  onClick={() => handleTabChange(1)}
                >
                  About
                </button>
                <button
                  className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                  onClick={() => handleTabChange(2)}
                >
                  Features
                </button>

                <button
                  className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
                  onClick={() => handleTabChange(3)}
                >
                  Services
                </button>
                <button
                  className={activeTab === 4 ? 'tab-btn active' : 'tab-btn'}
                  onClick={() => handleTabChange(4)}
                >
                  Achievements
                </button>
                <button
                  className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                  onClick={() => handleTabChange(5)}
                >
                  Review
                </button>

              </div>
              <br />
              <br />
              <div className="tab-content">
                <div className='tab1'>
                  <div id="tab-content-1" >
                    <h3>About</h3>
                    <br />
                    <p>{Institutedetails?.About}</p>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div id="tab-content-2" >
                    <h3>Features</h3>
                    <br />
                    <ul>
                      <li>{Institutedetails?.Features}</li>
                    </ul>
                  </div>
                  <br />
                  <br />
                  <div id="tab-content-3" >
                    <h3>Services</h3>
                    <br />
                    <p>{Institutedetails?.Services}</p>
                    <br />
                  </div>
                  <br />
                  <div id="tab-content-4">
                    <h3>Achievements</h3>
                    <br />
                    <p>{Institutedetails?.Achievements}</p>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="tab-content">
                <div id="tab-content-5" >
                  <div className='tab1'>
                    <h3>Reviews</h3>
                    <p>★★★★★ 4.5 ({Institutereview?.length || 0} reviews)</p>
                    {Institutereview?.length === 0 &&
                      <>
                        <br />
                        <p>Be the first to review</p>
                      </>
                    }
                  </div>
                </div>
                <br />
                <br />
                {Institutereview?.slice(0, 5)?.map((slot, index) => (
                  <div key={index} className="review-container">
                    <div className="review-header">
                      <img
                        src={img}
                        alt="User Profile"
                        className="user-profile-img"
                      />
                      <div>
                        <div className="user-name">{slot?.User?.Name}</div>
                        <div className="rating">
                          {Array.from({ length: slot?.Rating }, (_, i) => (
                            <span key={i} className="star">★</span>
                          ))}
                        </div>
                        <div className="review-text">{slot?.Review}</div>
                      </div>
                    </div>
                  </div>
                ))}
                <br />
                <br />
                <AddReview rating={rating} reviewText={reviewText} CreateReview={CreateReview} setRating={setRating} setReviewText={setReviewText} />
              </div>
            </div>
            <Footer />
          </>
      }
    </>
  )
}

export default CoachDetail