import React, { useState } from 'react';

const SelectBowlerModel = ({ show, onClose, Bowler, setBowler, SelectPlayer, inactiveTeam }) => {

    if (!show) return null;


    return (
        <div className="modal-backdrop">
            <div className="modal-content1">
                <h2>Select Bowler</h2>
                <br />
                <br />
                <label htmlFor="bowler">Bowler:</label>
                <select
                    id="bowler"
                    name="Bowler"
                    value={Bowler}
                    onChange={(e) => setBowler(e.target.value)}
                >
                    <option value="">Select Bowler</option>
                    {inactiveTeam?.bowlers?.map((player) => (
                        <option key={player?.playerId} value={JSON.stringify(player)}>
                            {player?.player}
                        </option>
                    ))}
                </select>
                <button className='tab-btn active' onClick={() => { SelectPlayer(Bowler) }}>Submit</button>
                {/* <button onClick={onClose}>Close</button> */}
            </div>
        </div>
    );
};

export default SelectBowlerModel;
