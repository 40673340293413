import React from 'react';

const UpdateTurfOwner = ({ show, onClose, Name, Email, setName, setEmail, Password, setPassword, UpdateTurfOwnerapi }) => {
    if (!show) return null;


    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Turf Owner Update</h2>
                <br />
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Name</label>
                        <input
                            placeholder='Name'
                            value={Name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </div>
                </div>
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Email</label>
                        <input
                            placeholder='Email'
                            value={Email}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </div>
                </div>
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Password</label>
                        <input
                            placeholder='Password'
                            value={Password}
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                    </div>
                </div>
                <br />
                <button onClick={() => { UpdateTurfOwnerapi() }}>Submit</button>
            </div>
        </div>
    );
};

export default UpdateTurfOwner;
