import React, { useEffect, useState } from 'react';

import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import img from '../../Assets/image 4.png'
import { Link, useNavigate } from 'react-router-dom';
import { Create_Api } from '../../ApiUrls';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';


function UserSignup() {
    const navigate = useNavigate()
    const [IsUploading, setIsUploading] = useState(false);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');

    const SignUp = async () => {
        if (!Name || !Email || !phoneNumber || !Password || !ConfirmPassword) {
            toast.error('All fields are required!');
            return;
        }
        if (phoneNumber.length !== 10) {
            toast.error('Please Enter Correct Phone Number');
            return;
        }
        console.log(ConfirmPassword)
        console.log(Password)
        if (Password !== ConfirmPassword) {
            toast.error('Password and Confirm Password must match!');
            return;
        }
        try {
            setIsUploading(true)
            let data = {
                Name,
                Email,
                phoneNumber: '91' + phoneNumber,
                Password
            }
            const response = await Create_Api(`api/user/register`, data)
            console.log('User register Successfull:', response);
            localStorage.setItem('user', JSON.stringify(response?.data));
            localStorage.setItem('userType', JSON.stringify('User'));
            localStorage.setItem('userid', response?.data?.User?._id);
            localStorage.setItem('userToken', response?.data?.token);
            toast.success(response?.message);
            setName('')
            setEmail('')
            setphoneNumber('')
            setPassword('')
            setConfirmPassword('')
            navigate('/')
            setIsUploading(false)
        } catch (error) {
            toast.success(error?.response?.data?.message);
            setIsUploading(false)
            console.log(error)
        }
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='login'>
                <div className="login-tebs">
                    <br />
                    <br />
                </div>
                <div className="tab-content">
                    <div className='login-section'>
                        <div className='login-left'>
                            <img src={img} />
                        </div>
                        <div className='login-right'>
                            <div className='login-right-div'>
                                <h3>Sign Up</h3>
                                <br />
                                <br />
                                <br />
                                <label>Name </label>
                                <br />
                                <input
                                    placeholder='Name'
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <br />
                                <label>Email </label>
                                <br />
                                <input
                                    placeholder='Email '
                                    type='email'
                                    value={Email}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                                <br />
                                <label>Phone Number </label>
                                <br />
                                <input
                                    placeholder=' Phone Number'
                                    value={phoneNumber}
                                    onChange={(e) => { setphoneNumber(e.target.value) }}
                                />
                                <br />
                                <label>Password </label>
                                <br />
                                <input
                                    placeholder='Enter Password'
                                    type='password'
                                    value={Password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                                <br />
                                <label>Confirm Password </label>
                                <br />
                                <input
                                    placeholder='Confirm Password'
                                    type='password'
                                    value={ConfirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                                />
                                <br />
                                {IsUploading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                        <ClipLoader color="#31ED31" size={50} />
                                    </div>
                                ) : (
                                    <button className='tab-btn active' onClick={SignUp}>Sign Up</button>
                                )}
                                <br />
                                <br />
                                <p>Already Have an Account?</p>
                                <br />
                                <Link style={{ textDecoration: 'none' }} to='/login'>
                                    <h4>Log In ?</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default UserSignup