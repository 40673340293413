import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

function PrivacyPolicies() {
  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);
  return (
    <div>
      <Navbar city={city} setcity={setcity} />
      <br />
      <div className="privacy-policy-container">
        <header className="privacy-header">
          <h1>Privacy Policy</h1>
          <p>
            *** This Website is Owned and Maintained by Kreeda Connect PVT LTD ***
          </p>
        </header>

        <section className="policy-section">
          <h2>1. Introduction</h2>
          <p>
            Kreeda Connect Technologies Private Limited and our partners/affiliates/authorized representatives ("Company", "we", "us", "our") value your privacy and are dedicated to safeguarding it through adherence to this privacy policy ("Policy"). In this Policy, "you" refers to all users of the GameVerse Platform (defined below), regardless of whether you complete transactions or simply browse our services ("Users").
          </p>
        </section>

        <section className="policy-section">
          <h2>2. Scope and Consent:</h2>
          <div style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <p>This Policy outlines:</p>
            <br />
            <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
              <li>a. The categories of information we may collect when you use our services.            </li>
              <br />
              <li>b. Our practices for collecting, using, protecting, and sharing that information.            </li>
              <br />
            </ul>
            <br />
            <p>
              The term "Platform" encompasses both our Website and Mobile Application, operated directly or through licensed partners. By using our Platform and providing Information
              or SPDI (defined below), you explicitly consent to our data practices as
              described herein and agree to our Terms of Use.
            </p>
          </div>
        </section>

        <section className="policy-section">
          <h2>3.Key Definitions:          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>A. <strong>Account </strong>: A password-protected user profile created to access Platform services.</li>
            <br />
            <li>B. <strong>Non-personal Identification Information </strong>:Technical data collected during Platform interaction, including browser type, computer specifications, operating system, internet service provider details, etc.</li>
            <br />
            <li>C. <strong>Personal Identification Information </strong>:Data that can identify individuals, including name, email, phone number, address, and other details shared voluntarily.</li>
            <br />
            <li>D. <strong>Sensitive Personal Data or Information (SPDI) </strong>: Includes passwords, financial details, health information, biometric data, and related sensitive information.</li>
            <br />
            <li>E. <strong>Third Party </strong>: Any entity or individual other than you or GameVerse.</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>4. Information Collection:          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              A. Platform Registration:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Basic contact details                </li>
                <br />
                <li>ii. Demographic information                </li>
                <br />
                <li>iii. Profile content and activity data                </li>
                <br />
                <li>iv. Device location (with consent)                </li>
                <br />
                <li>v. Contact list access (optional, for social features)                </li>
                <br />
                <li>vi. Communication records between users                </li>
              </ul>
            </li>
            <br />
            <li>B. Third-Party Integration:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Social media login capabilities</li>
                <br />
                <li>ii. Data sharing with authorized platforms                </li>
                <br />
                <li>iii. Integration with payment processors                </li>
              </ul>
            </li>
            <br />
            <li>
              C. Cookies and Tracking:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Session management
                </li>
                <br />
                <li>ii. User preference storage
                </li>
                <br />
                <li>iii. Authentication
                </li>
                <br />
                <li>iv. Analytics and advertising
                </li>
                <br />
                <li>v. Security features
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section className="policy-section">
          <h2>5. Use of Information:
          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              We use collected information to:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Deliver requested services               </li>
                <br />
                <li>ii. Process transactions                </li>
                <br />
                <li>iii. Improve user experience                </li>
                <br />
                <li>iv. Communicate updates                </li>
                <br />
                <li>v. Provide customer support                </li>
                <br />
                <li>vi. Generate analytics                </li>
                <br />
                <li>vii. Ensure platform security                </li>
                <br />
                <li>vi. Comply with legal obligations                </li>
              </ul>
            </li>
            <br />
          </ul>
        </section>
        <section className="policy-section">
          <h2>6. Information Sharing:
          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              We may share information:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. With service providers and partners                </li>
                <br />
                <li>ii. During corporate transactions                </li>
                <br />
                <li>iii. For legal compliance                </li>
                <br />
                <li>iv. With user consent                </li>
                <br />
                <li>v. In aggregated, anonymous form                </li>
                <br />
              </ul>
            </li>
            <br />
          </ul>
        </section>
        <section className="policy-section">
          <h2>7. Data Security          :
          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              We implement industry-standard security measures:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Encryption Protocols              </li>
                <br />
                <li>ii. Secure payment processing                </li>
                <br />
                <li>iii. Access Controls             </li>
                <br />
                <li>iv. Regular Security audits                </li>
                <br />
                <li>v. Physical Security Measures                </li>
                <br />
              </ul>
            </li>
            <br />
          </ul>
        </section>
        <section className="policy-section">
          <h2>8. User Rights          :
          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Review your information                </li>
                <br />
                <li>ii. Request updates or corrections               </li>
                <br />
                <li>iii. Delete your account                </li>
                <br />
                <li>iv. Opt-out of communications                </li>
                <br />
                <li>v. Control cookie preferences                </li>
                <br />
              </ul>
            </li>
            <br />
          </ul>
        </section>
        <section className="policy-section">
          <h2>9. Policy Updates          :
          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              We reserve the right to modify this Policy. Users will be notified of changes via:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Email notifications                </li>
                <br />
                <li>ii. Platform announcements                </li>
                <br />
                <li>iii. Website updates                </li>
                <br />
              </ul>
            </li>
            <br />
          </ul>
        </section>
        <section className="policy-section">
          <h2>10. Third-Party Services          :
          </h2>
          <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
            <li>
              We partner with verified service providers for:
              <ul style={{ marginLeft: 20, listStyleType: 'lower-alpha' }}>
                <br />
                <li>i. Payment processing                </li>
                <br />
                <li>ii. Analytics                </li>
                <br />
                <li>iii. User Verification                </li>
                <br />
                <li>iv. Marketing services                </li>
                <br />
              </ul>
            </li>
            <br />
          </ul>
        </section>

        <section className="policy-section">
          <h2>11. Contact Information</h2>
          <p>
            For privacy-related inquiries:
          </p>
          <br />
          <p>Email: <a href="mailto: admin@kreeedaco.com"> admin@kreeedaco.com</a></p>
          <br />
          <address>
            Address:   Kreeda <br />
            OLD MIG BHEL <br />
            Hyderabad, Telangana, India - 502032 <br />
            Kreeda Connect PVT LTD
          </address>
        </section>
      </div>
      <br />
      <Footer />
    </div>
  )
}

export default PrivacyPolicies