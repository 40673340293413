import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { apiPost, Get_Api } from '../../ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import UpdateScoreCard from './UpdateScoreCard';
import SelectPlayerModel from './SelectPlayerModel';
import SelectBowlerModel from './SelectBowlerModel';

function GetScoreCard() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isUploading, setIsUploading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [teamA, setTeamA] = useState(null);
    const [teamB, setTeamB] = useState(null);

    const getScoreCardOfATeam = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/game/getTeamStats/${id}/teamA`);
            setTeamA(response.data);
        } catch (error) {
            navigate(`/CreateTeam/${id}`)
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };

    const getScoreCardOfBTeam = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/game/getTeamStats/${id}/teamB`);
            setTeamB(response.data);
        } catch (error) {
            toast.error('Please correct the Game ID');
            console.error('Error fetching data:', error);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        getScoreCardOfATeam();
        getScoreCardOfBTeam();
    }, [id]);

    const activeTeam = activeTab === 1 ? teamA : teamB;
    const inactiveTeam = activeTab === 1 ? teamB : teamA;


    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = (role, team) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    const [team, setteam] = useState('teamA');
    const [playerId, setplayerId] = useState();
    const [bowlerId, setbowlerId] = useState();
    const [runs, setruns] = useState();
    const [ballType, setballType] = useState();
    const [isOut, setisOut] = useState(false);
    const [wicketType, setwicketType] = useState('');
    const [fielderId, setfielderId] = useState('');

    const handleBallTypeChange = (event) => {
        setballType(event.target.value);
    };
    const handleWicketTypeChange = (event) => {
        setwicketType(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setisOut(event.target.checked);
    };






    const handleTabChange = (tabIndex, team) => {
        handlePlayerOpenModal()
        setteam(team)
        setActiveTab(tabIndex);
    };


    const [striker, setstriker] = useState('');
    const [Nonstriker, setNonstriker] = useState('');
    const [Bowler, setBowler] = useState('');
    const [Bowlername, setBowlername] = useState('');

    const [SelectBowlerModal, setSelectBowlerModal] = useState(false);

    const handleBowlerOpenModal = () => {
        setSelectBowlerModal(true);
    };

    const handlebowlerCloseModal = () => {
        setSelectBowlerModal(false);
    };

    const SelectBowler = (Bowler) => {
        let data;
        try {
            data = JSON.parse(Bowler);
        } catch (error) {
            console.error("Invalid JSON format for Bowler:", error);
        }
        setBowler(data?.playerId);
        setBowlername(data?.player);
        handlebowlerCloseModal();
    }


    const [showPlayerModal, setShowPlayerModal] = useState(true);

    const handlePlayerOpenModal = () => {
        setShowPlayerModal(true);
    };

    const handlePlayerCloseModal = () => {
        setShowPlayerModal(false);
    };

    const SelectPlayer = (striker, Nonstriker, Bowler) => {
        let data;
        try {
            data = JSON.parse(Bowler);
        } catch (error) {
            console.error("Invalid JSON format for Bowler:", error);
        }
        setstriker(striker);
        setNonstriker(Nonstriker);
        // setBowler(data?.playerId);
        // setBowlername(data?.player);
        handlePlayerCloseModal();
    }

    const [Uteam, setUteam] = useState();
    const [UplayerId, setUplayerId] = useState();
    const [UbowlerId, setUbowlerId] = useState();
    const [Uruns, setUruns] = useState();
    const [UballType, setUballType] = useState();
    const [UisOut, setUisOut] = useState();
    const [UwicketType, setUwicketType] = useState();
    const [UfielderId, setUfielderId] = useState();
    const [Ubowlername, setUbowlername] = useState();


    const handleManualScoreCardUpdate = async (runs) => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!team) {
                toast.error('Please select team first');
                return
            }
            if (!striker) {
                toast.error('Please Batsman First....');
                return
            }
            if (!Bowler) {
                handleBowlerOpenModal()
                toast.error('Please bowler First....');
                return
            }
            if (!ballType) {
                toast.error('Please ballType First....');
                return
            }

            let data = {
                gameId: id,
                team,
                playerId: striker,
                bowlerId: Bowler,
                runs: runs,
                ballType,
                isOut,
                wicketType,
                fielderId,
                bowlername: Bowlername
            }
            setIsUploading(true);
            const response = await apiPost(`api/game/updateScoreCard`, data);
            setIsUploading(false);
            toast.success(response?.message)
            if (runs === 1 || runs === 3) {
                setstriker(prev => Nonstriker);
                setNonstriker(prev => striker);
            }
            if (isOut) {
                handlePlayerOpenModal();
            }
            setUteam(team);
            setUplayerId(striker);
            setUbowlerId(Bowler);
            setUruns(runs);
            setUballType(ballType);
            setUisOut(isOut);
            setUwicketType(wicketType);
            setUfielderId(fielderId);
            setUbowlername(Bowlername);
            getScoreCardOfATeam();
            getScoreCardOfBTeam();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    useEffect(() => {
        if (activeTeam?.score?.overs % 1 === 0) {
            handleBowlerOpenModal();
        }
    }, [activeTeam?.score?.overs % 1 === 0]);


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);



    const handleUndoScoreCardUpdate = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!team) {
                toast.error('Can Not Undo this ScoreCard');
                return
            }
            if (!UplayerId) {
                toast.error('Can Not Undo this ScoreCard');
                return
            }
            if (!UbowlerId) {
                toast.error('Can Not Undo this ScoreCard');
                return
            }
            if (!UballType) {
                toast.error('Can Not Undo this ScoreCard');
                return
            }

            let data = {
                gameId: id,
                team: Uteam,
                playerId: UplayerId,
                bowlerId: UbowlerId,
                runs: Uruns,
                ballType: UballType,
                isOut: UisOut,
                wicketType: UwicketType,
                fielderId: UfielderId,
                bowlername: Ubowlername,
                undo: true
            }
            console.log(data)
            setIsUploading(true);
            const response = await apiPost(`api/game/updateScoreCard`, data);
            console.log(response)
            handleCloseModal()
            setIsUploading(false);
            toast.success(response?.message)
            setUplayerId('')
            setUbowlerId('')
            setUruns('')
            setUballType('')
            setUisOut('')
            setUwicketType('')
            setUfielderId('')
            setUbowlername('')
            getScoreCardOfATeam();
            getScoreCardOfBTeam();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <>
            {
                isUploading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                ) : (
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='addturf-div'>
                            <div className="addturf">
                                <div className="scorecard">
                                    <div className="header">
                                        <button className="back-button" onClick={() => navigate(-1)}>←</button>
                                        <h2>Score Card</h2>
                                        <button className="back-button">
                                            <button className="UndoButton" onClick={() => { handleUndoScoreCardUpdate() }}>
                                                Undo Last Ball
                                            </button>
                                        </button>
                                    </div>
                                    <div className="score-summary">
                                        <div className="team-score">
                                            <h3>{teamA?.score?.score} / {teamA?.score?.wickets}</h3>
                                            <p>{teamA?.score?.overs} Overs</p>
                                            <button
                                                className={`team-button ${activeTab === 1 ? 'active' : ''}`}
                                                onClick={() => handleTabChange(1, 'teamA')}
                                            >
                                                Team A
                                            </button>
                                        </div>
                                        <div className="vs">V/S</div>
                                        <div className="team-score">
                                            <h3>{teamB?.score?.score} / {teamB?.score?.wickets}</h3>
                                            <p>{teamB?.score?.overs} Overs</p>
                                            <button
                                                className={`team-button ${activeTab === 2 ? 'active' : ''}`}
                                                onClick={() => handleTabChange(2, 'teamB')}
                                            >
                                                Team B
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <p className="status">Team {teamA?.Toss} won the toss and Team {teamA?.Battingfisrt} is batting first.</p>
                                    <br />
                                    <br />
                                    <div className="login-teb">
                                        <button
                                            className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                                            onClick={() => handleTabChange(1, 'teamA')}
                                        >
                                            Team A
                                        </button>
                                        <button
                                            className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                                            onClick={() => handleTabChange(2, 'teamB')}
                                        >
                                            Team B
                                        </button>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <div className="table">
                                        <div className="table-header">
                                            <p>Batsman</p>
                                            <p>R</p>
                                            <p>B</p>
                                            <p>4s</p>
                                            <p>6s</p>
                                            <p>SR</p>
                                        </div>
                                        {activeTeam?.batsmen?.map((player, index) => (
                                            <div className="table-row" key={index}>
                                                <p>{player?.player} {player?.designation === "Captain" ? " (C)" : player?.designation === "Vice-Captain" ? " (VC)" : ""}
                                                    {player?.player ? (player?.isOut ? "" : "*") : ""}
                                                    {player?.isOut ? (
                                                        <> {" "}
                                                            {player?.bowlerId && <span>B. {player.bowlerId}</span>}
                                                            {(player?.wicketType === "Caught" || player?.wicketType === "Run Out") && player?.fielderId && (
                                                                <span>/{player.fielderId}</span>
                                                            )}
                                                            ({player?.wicketType})
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </p>
                                                <p>{player?.runs}</p>
                                                <p>{player?.balls}</p>
                                                <p>{player?.fours}</p>
                                                <p>{player?.sixes}</p>
                                                <p>{player?.strikeRate}</p>
                                            </div>
                                        ))}
                                        <div className="table-row extras">
                                            <p>Extras</p>
                                            <p>{activeTeam?.extra?.wides + activeTeam?.extra?.noBalls + activeTeam?.extra?.byes + activeTeam?.extra?.legByes}</p>
                                            <p colSpan="4">(B {activeTeam?.extra?.byes}, LB {activeTeam?.extra?.legByes}, NB {activeTeam?.extra?.noBalls}, W {activeTeam?.extra?.wides})</p>
                                        </div>
                                        <div className="table-row total">
                                            <p>TOTAL</p>
                                            <p>{activeTeam?.score?.score}/{activeTeam?.score?.wickets}</p>
                                            <p colSpan="4">{activeTeam?.score?.overs} OV (RR {(activeTeam?.score?.overs ? (activeTeam?.score?.score / activeTeam?.score?.overs).toFixed(1) : 0)})</p>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="table">
                                        <div className="table-header">
                                            <p>Bowler</p>
                                            <p>Overs</p>
                                            <p>Balls Bowled</p>
                                            <p>Runs Conceded</p>
                                            <p>Wickets</p>
                                            <p>Economy</p>
                                        </div>
                                        {inactiveTeam?.bowlers?.map((player, index) => (
                                            <div className="table-row" key={index}>
                                                <p>{player?.player}</p>
                                                <p>{player?.overs}</p>
                                                <p>{player?.ballsBowled}</p>
                                                <p>{player?.runsConceded}</p>
                                                <p>{player?.wickets}</p>
                                                <p>{player?.economyRate}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="scoreboard">
                                    <div className="checkbox-row">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isOut}
                                                onChange={handleCheckboxChange}
                                            />
                                            Wicket
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="wide"
                                                checked={ballType === 'wide'}
                                                onChange={handleBallTypeChange}
                                            />
                                            Wide
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="normal"
                                                checked={ballType === 'normal'}
                                                onChange={handleBallTypeChange}
                                            />
                                            Normal
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="noBall"
                                                checked={ballType === 'noBall'}
                                                onChange={handleBallTypeChange}
                                            />
                                            No Ball
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="bye"
                                                checked={ballType === 'bye'}
                                                onChange={handleBallTypeChange}
                                            />
                                            B
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="legBye"
                                                checked={ballType === 'legBye'}
                                                onChange={handleBallTypeChange}
                                            />
                                            LB
                                        </label>
                                    </div>
                                </div>
                                <br />
                                <br />
                                {isOut &&
                                    <div className="scoreboard">
                                        <div className="checkbox-row">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Bowled"
                                                    checked={wicketType === 'Bowled'}
                                                    onChange={handleWicketTypeChange}
                                                />
                                                Bowled
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="LBW"
                                                    checked={wicketType === 'LBW'}
                                                    onChange={handleWicketTypeChange}
                                                />
                                                LBW
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Caught"
                                                    checked={wicketType === 'Caught'}
                                                    onChange={handleWicketTypeChange}
                                                />
                                                Caught
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Run Out"
                                                    checked={wicketType === 'Run Out'}
                                                    onChange={handleWicketTypeChange}
                                                />
                                                Run Out
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value="Stumped"
                                                    checked={wicketType === 'Stumped'}
                                                    onChange={handleWicketTypeChange}
                                                />
                                                Stumped
                                            </label>
                                            {(wicketType === 'Caught' || wicketType === 'Run Out') && (
                                                <>
                                                    <label htmlFor="bowler">Fielder</label>
                                                    <select
                                                        id="bowler"
                                                        name="Bowler"
                                                        value={fielderId}
                                                        onChange={(e) => setfielderId(e.target.value)}
                                                    >
                                                        <option value="">Select fielder</option>
                                                        {inactiveTeam?.bowlers?.map((player) => (
                                                            <option key={player?.playerId} value={player?.player}>
                                                                {player?.player}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                <br />
                                <br />
                                <div className="scoreboard">
                                    <div className="number-row">
                                        {[0, 1, 2, 3, 4, 5, 6].map((number) => (
                                            <button onClick={() => { handleManualScoreCardUpdate(number) }} key={number} className="number-button">
                                                {number}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Footer />
                    </>
                )
            }
            <SelectPlayerModel
                show={showPlayerModal}
                onClose={handlePlayerCloseModal}
                striker={striker}
                Nonstriker={Nonstriker}
                Bowler={Bowler}
                setstriker={setstriker}
                setNonstriker={setNonstriker}
                setBowler={setBowler}
                SelectPlayer={SelectPlayer}
                activeTeam={activeTeam}
                inactiveTeam={inactiveTeam}
            />
            <SelectBowlerModel
                show={SelectBowlerModal}
                onClose={handlebowlerCloseModal}
                Bowler={Bowler}
                setBowler={setBowler}
                SelectPlayer={SelectBowler}
                activeTeam={activeTeam}
                inactiveTeam={inactiveTeam}
            />

        </>
    );
}

export default GetScoreCard;
