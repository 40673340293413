import React, { useEffect, useState } from 'react';

import Navbar from '../../../Components/Navbar'
import Footer from '../../../Components/Footer'
import img from '../../../Assets/image 4.png'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Create_Api } from '../../../ApiUrls';
import { ClipLoader } from 'react-spinners';

function Login() {

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };


  const [Email, setEmail] = useState('');
  const [Number, setNumber] = useState('');
  const [Password, setPassword] = useState('');
  const [IsUploading, setIsUploading] = useState(false);

  const TurfOnwerLogin = async () => {
    if (!Email || !Password) {
      toast.error('Email and Password are required!');
      return;
    }
    try {
      let data = {
        Email,
        Password
      }
      setIsUploading(true)
      const response = await Create_Api(`api/turf/Login`, data)
      console.log('User Login Successfull:', response);
      localStorage.setItem('user', JSON.stringify(response?.data));
      localStorage.setItem('userType', JSON.stringify('TurfOwner'));
      localStorage.setItem('userid', response?.data?.Turf?._id);
      localStorage.setItem('userToken', response?.data?.token);
      toast.success(response?.message);
      setEmail('')
      setPassword('')
      navigate('/')
      setIsUploading(false)
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }


  const CoachLogin = async () => {
    if (!Email || !Password) {
      toast.error('Email and Password are required!');
      return;
    }
    try {
      let data = {
        Email,
        Password
      }
      setIsUploading(true)
      const response = await Create_Api(`api/coach/Login`, data)
      console.log('User Login Successfull:', response);
      localStorage.setItem('user', JSON.stringify(response?.data));
      localStorage.setItem('userType', JSON.stringify('CoachingInstitute'));
      localStorage.setItem('userid', response?.data?.institude?._id);
      localStorage.setItem('userToken', response?.data?.token);
      toast.success(response?.message);
      setEmail('')
      setPassword('')
      navigate('/')
      setIsUploading(false)
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }

  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);

  const [LoginType, setLoginType] = useState('Email');

  const ChangeLogintab = (type) => {
    setLoginType(type)
  }


  const [phoneNumber, setphoneNumber] = useState('');
  const [otp, setotp] = useState('');
  const [Showotp, setShowotp] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);

  const startResendCountdown = () => {
    setResendCountdown(30);
  };

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);


  const TurfOwnerSendOtp = async () => {
    if (!phoneNumber) {
      toast.error('phoneNumber are required!');
      return;
    }
    try {
      let data = {
        Number: '91' + phoneNumber
      }
      setIsUploading(true)
      const response = await Create_Api(`api/turf/TurfLoginUsingNumber`, data)
      console.log('send Otp Successfull:', response);
      toast.success(response?.message);
      setShowotp(true)
      setIsUploading(false)
      startResendCountdown();
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }

  const ResendOtp = async () => {
    TurfOwnerSendOtp();
  };

  const TurfOwnerVerifyOtp = async () => {
    if (!phoneNumber || !otp) {
      toast.error('phoneNumber and otp are required!');
      return;
    }
    try {
      let data = {
        Number: '91' + phoneNumber,
        otp
      }
      setIsUploading(true)
      const response = await Create_Api(`api/turf/TurfVerifyOtp`, data)
      console.log('User Login Successfull:', response);
      localStorage.setItem('user', JSON.stringify(response?.data));
      localStorage.setItem('userType', JSON.stringify('TurfOwner'));
      localStorage.setItem('userid', response?.data?.Turf?._id);
      localStorage.setItem('userToken', response?.data?.token);
      toast.success(response?.message);
      setphoneNumber('')
      setotp('')
      navigate('/')
      setIsUploading(false)
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }


  const InstituteOwnerSendOtp = async () => {
    if (!phoneNumber) {
      toast.error('phoneNumber are required!');
      return;
    }
    try {
      let data = {
        Number: '91' + phoneNumber
      }
      setIsUploading(true)
      const response = await Create_Api(`api/coach/CoachingInstituteLoginUsingNumber`, data)
      console.log('send Otp Successfull:', response);
      toast.success(response?.message);
      setShowotp(true)
      setIsUploading(false)
      startResendCountdown();
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }

  const InstituteResendOtp = async () => {
    TurfOwnerSendOtp();
  };

  const InstituteOwnerVerifyOtp = async () => {
    if (!phoneNumber || !otp) {
      toast.error('phoneNumber and otp are required!');
      return;
    }
    try {
      let data = {
        Number: '91' + phoneNumber,
        otp
      }
      setIsUploading(true)
      const response = await Create_Api(`api/coach/CoachingInstituteVerifyOtp`, data)
      console.log('User Login Successfull:', response);
      localStorage.setItem('user', JSON.stringify(response?.data));
      localStorage.setItem('userType', JSON.stringify('CoachingInstitute'));
      localStorage.setItem('userid', response?.data?.institude?._id);
      localStorage.setItem('userToken', response?.data?.token);
      toast.success(response?.message);
      setphoneNumber('')
      setotp('')
      navigate('/')
      setIsUploading(false)
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }


  return (
    <>
      <Navbar city={city} setcity={setcity} />
      <div className='login'>
        <div className="login-tebs">
          <div className="login-teb">
            <button
              className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
              onClick={() => handleTabChange(1)}
            >
              Turf Owner
            </button>
            <button
              className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
              onClick={() => handleTabChange(2)}
            >
              Institute
            </button>
          </div>
          <br />
          <br />
        </div>
        <div className="tab-content">
          {activeTab === 1 &&
            <>
              <div className='login-section'>
                <div className='login-left'>
                  <img src={img} />
                </div>
                <div className='login-right'>
                  <div className='login-right-div'>
                    <h3>Login</h3>
                    <br />
                    <br />
                    <br />
                    <div className="login-tebs">
                      <div className="login-teb">
                        <button
                          className={LoginType === "Email" ? 'tab-btn active' : 'tab-btn'}
                          onClick={() => ChangeLogintab("Email")}
                        >
                          Email
                        </button>
                        <button
                          className={LoginType === "Number" ? 'tab-btn active' : 'tab-btn'}
                          onClick={() => ChangeLogintab("Number")}
                        >
                          Number
                        </button>
                      </div>
                      <br />
                      <br />
                    </div>
                    <br />
                    <br />
                    <br />
                    {
                      LoginType === "Email" &&
                      <>
                        <label>Email </label>
                        <br />
                        <input
                          placeholder='Email'
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <br />
                        <label>Password </label>
                        <br />
                        <input
                          placeholder='Enter Password'
                          type='password'
                          value={Password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <br />
                        {IsUploading ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                            <ClipLoader color="#31ED31" size={50} />
                          </div>
                        ) : (
                          <button className='tab-btn active' onClick={() => { TurfOnwerLogin() }}>Log in</button>
                        )}
                        <br />
                        <br />
                      </>
                    }

                    {
                      LoginType === "Number" &&
                      <>
                        <label>Number </label>
                        <br />
                        <input
                          placeholder='Number'
                          value={phoneNumber}
                          onChange={(e) => setphoneNumber(e.target.value)}
                        />
                        <br />
                        <br />
                        {
                          Showotp &&
                          <>
                            <label>OTP </label>
                            <br />
                            <input
                              placeholder='Enter OTP'
                              type='OTP'
                              value={otp}
                              onChange={(e) => setotp(e.target.value)}
                            />
                          </>
                        }

                        <br />
                        <br />
                        {IsUploading ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                            <ClipLoader color="#31ED31" size={50} />
                          </div>
                        ) : (
                          Showotp ? (
                            <button className='tab-btn active' onClick={TurfOwnerVerifyOtp}>Verify OTP</button>
                          ) : (
                            <button className='tab-btn active' onClick={TurfOwnerSendOtp}>Send OTP</button>
                          )
                        )}
                        <br />
                        <br />
                        {Showotp && (
                          <p onClick={resendCountdown === 0 ? ResendOtp : null}>
                            Resend OTP {resendCountdown > 0 ? `(${resendCountdown}s)` : ''}
                          </p>
                        )}
                        <br />
                        <br />
                      </>
                    }

                    <p>Don’t Have an Account?</p>
                    <br />
                    <Link style={{ textDecoration: 'none' }} to='/TurfOwner/signup'>
                      <h4 >Create Account?</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          }
          {activeTab === 2 &&
            <>
              <div className='login-section'>
                <div className='login-left'>
                  <img src={img} />
                </div>
                <div className='login-right'>
                  <div className='login-right-div'>
                    <h3>Login</h3>
                    <br />
                    <br />
                    <br />
                    <div className="login-tebs">
                      <div className="login-teb">
                        <button
                          className={LoginType === "Email" ? 'tab-btn active' : 'tab-btn'}
                          onClick={() => ChangeLogintab("Email")}
                        >
                          Email
                        </button>
                        <button
                          className={LoginType === "Number" ? 'tab-btn active' : 'tab-btn'}
                          onClick={() => ChangeLogintab("Number")}
                        >
                          Number
                        </button>
                      </div>
                      <br />
                      <br />
                    </div>
                    <br />
                    <br />
                    <br />
                    {
                      LoginType === "Email" &&
                      <>
                        <label>Email</label>
                        <br />
                        <input
                          placeholder='Email'
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <br />
                        <label>Password </label>
                        <br />
                        <input
                          placeholder='Enter Password'
                          type='password'
                          value={Password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <br />
                        {IsUploading ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                            <ClipLoader color="#31ED31" size={50} />
                          </div>
                        ) : (
                          <button className='tab-btn active' onClick={() => { CoachLogin() }}>Log in</button>
                        )}
                        <br />
                        <br />
                      </>
                    }

                    {
                      LoginType === "Number" &&
                      <>
                        <label>Number </label>
                        <br />
                        <input
                          placeholder='Number'
                          value={phoneNumber}
                          onChange={(e) => setphoneNumber(e.target.value)}
                        />
                        <br />
                        <br />
                        {
                          Showotp &&
                          <>
                            <label>OTP </label>
                            <br />
                            <input
                              placeholder='Enter OTP'
                              type='OTP'
                              value={otp}
                              onChange={(e) => setotp(e.target.value)}
                            />
                          </>
                        }

                        <br />
                        <br />
                        {IsUploading ? (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                            <ClipLoader color="#31ED31" size={50} />
                          </div>
                        ) : (
                          Showotp ? (
                            <button className='tab-btn active' onClick={InstituteOwnerVerifyOtp}>Verify OTP</button>
                          ) : (
                            <button className='tab-btn active' onClick={InstituteOwnerSendOtp}>Send OTP</button>
                          )
                        )}
                        <br />
                        <br />
                        {Showotp && (
                          <p onClick={resendCountdown === 0 ? InstituteResendOtp : null}>
                            Resend OTP {resendCountdown > 0 ? `(${resendCountdown}s)` : ''}
                          </p>
                        )}
                        <br />
                        <br />
                      </>
                    }

                    <p>Don’t Have an Account?</p>
                    <br />
                    <Link style={{ textDecoration: 'none' }} to='/TurfOwner/signup'>
                      <h4 >Create Account?</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          }

        </div>

      </div>
      <Footer />
    </>
  )
}

export default Login