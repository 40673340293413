// import React, { useEffect, useState } from 'react';
// import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';

// // Fix default marker icon issue for Leaflet
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// });

// function LocationPicker({ onLocationSelected }) {
//     useMapEvents({
//         click(e) {
//             onLocationSelected(e.latlng);
//         }
//     });

//     return null;
// }

// const SelectLocation = ({ isOpen, setIsOpen, latitude, longitude, position, setlatitude, setlongitude, setPosition }) => {
//     const handleLocationSelect = (latlng) => {
//         setlatitude(latlng.lat);
//         setlongitude(latlng.lng);
//         setPosition(latlng);
//         setIsOpen(false);
//     };

//     const handleFindCurrentLocation = () => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     const latlng = {
//                         lat: position.coords.latitude,
//                         lng: position.coords.longitude
//                     };
//                     setlatitude(latlng.lat);
//                     setlongitude(latlng.lng);
//                     setPosition(latlng);
//                 },
//                 (error) => {
//                     console.error("Error retrieving location:", error);
//                 }
//             );
//         } else {
//             alert("Geolocation is not supported by this browser.");
//         }
//     };

//     return (
//         <>
//             {isOpen && (
//                 <div className="modal-backdrop">
//                     <div className="modal-content">
//                         <div className="modal-background" onClick={() => setIsOpen(false)}></div>
//                         <span className="modal-spacer">&#8203;</span>
//                         <div className="modal-panel">
//                             <div className="modal-content">
//                                 <MapContainer center={[22.717984230778317, 75.84532952409474]} zoom={13} style={{ height: '300px', width: '100%' }}>
//                                     <TileLayer
//                                         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                                         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                                     />
//                                     <LocationPicker onLocationSelected={handleLocationSelect} />
//                                     {position && <Marker position={position}></Marker>}
//                                 </MapContainer>
//                                 <div>
//                                     <button onClick={handleFindCurrentLocation}>Find My Location</button>
//                                 </div>
//                                 <div>
//                                     <p>Latitude: {latitude}</p>
//                                     <p>Longitude: {longitude}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

// export default SelectLocation;


import React, { useState } from 'react';
import {
    GoogleMap,
    useLoadScript,
    Marker
} from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '300px'
};

const center = {
    lat: 22.717984230778317,
    lng: 75.84532952409474
};

const SelectLocation = ({ isOpen, setIsOpen, latitude, longitude, position, setlatitude, setlongitude, setPosition }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBdiHm0zeGJmyPiGRsI3eJDqoTfWPBT9eM' 
    });

    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setlatitude(lat);
        setlongitude(lng);
        setPosition({ lat, lng });
        setIsOpen(false);
    };

    const handleFindCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    setlatitude(lat);
                    setlongitude(lng);
                    setPosition({ lat, lng });
                },
                (error) => {
                    console.error('Error retrieving location:', error);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            {isOpen && (
                <div className="modal-backdrop">
                    <div className="modal-content">
                        <div className="modal-background" onClick={() => setIsOpen(false)}></div>
                        <span className="modal-spacer">&#8203;</span>
                        <div className="modal-panel">
                            <div className="modal-content">
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={center}
                                    zoom={13}
                                    onClick={handleMapClick}
                                >
                                    {position && <Marker position={position} />}
                                </GoogleMap>
                                <div>
                                    <button onClick={handleFindCurrentLocation}>Find My Location</button>
                                    <button onClick={()=>{setIsOpen(false)}}>Close</button>
                                </div>
                                <div>
                                    <p>Latitude: {latitude}</p>
                                    <p>Longitude: {longitude}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SelectLocation;
