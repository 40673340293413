import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import failed from '../Assets/failed.svg';
import { useNavigate } from 'react-router-dom';

function FailedBooking() {
    const navigate = useNavigate()
    const [city, setcity] = useState('');
    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore');
    }, []);

    return (
        <div>
            <Navbar city={city} setcity={setcity} />
            <div className="failed-booking-container">
                <img src={failed} alt="Failed Icon" className="failed-icon" />
                <h2 className="failed-heading">Your Booking Has Failed</h2>
                <button onClick={() => { navigate('/') }} className="retry-btn">Try Again</button>
            </div>
            <Footer />
        </div>
    );
}

export default FailedBooking;
