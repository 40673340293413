import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import pf from '../Assets/pfcaoach.png'
import calender from '../Assets/date-round-svgrepo-com 1.png'
import icon from '../Assets/heading.png'
import line from '../Assets/line.png'
import day from '../Assets/day-forecast-hot-svgrepo-com 1.png'
import night from '../Assets/night-sky-svgrepo-com 2.png'
function CoachAvailable() {

  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);

  return (
    <>
      <Navbar city={city} setcity={setcity} />
      <br />
      <div className='sloat-heading-div'>
        <div className='sloat-heading'>
          <div className='sloat-heading1'>
            <h2><img src={pf} />Yogesh Pradhan</h2>
            <div className='rate-div'>

              <div className='green-review'>
                <p>4.7 <i class="bi bi-star-fill"></i></p>
              </div>
              <h4>(650 reviews)</h4>
            </div>

          </div>
          <p>Senior Cricket Caoch Delhi 2020, Dwarka, Delhi</p>


        </div>

      </div>


      <div className='calander'>
        <div className='calander-heading'>

          <img src={calender} />
          <p>July 2024</p>


        </div>





      </div>

      <br />
      <br />
      <div className='coach-avail-heading'>
        <img src={icon} />
        <h2>Senior Cricket Coach</h2>
      </div>
      <br />
      <div className='line2'>
        <img src={line} />
      </div>
      <div className='slot-heading'>
        <h3>Available Slots (20)</h3>
        <p>Min, 60 mins Slots</p>
      </div>
      <div className='day-night-btn'>
        <button className='day'>Day <img src={day} /></button>
        <button className='Mid-Night'>Mid-Night <img src={night} /></button>

      </div>
      <div className='book-slot-coach'>
        <div className='book-slot-coach-div'>
          <div className='book-slot-coach-div-p'>
            <p>10 Days</p>
            <p>10 Days</p>

          </div>
          <button>Book Now</button>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default CoachAvailable