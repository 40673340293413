import React from 'react';

const CancelTurfBookingModel = ({ show, Close, reason, setreason, refundMessage, CancelturfBooking }) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Cancel Turf Booking</h2>
        <p>{refundMessage}</p> 
        
        <div className="addtruf-ips">
          <div className="addtruf-ip">
            <label>Reason</label>
            <input
              placeholder="Enter reason for cancellation"
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </div>
        </div>
        <br/>
        <br/>
        <button onClick={CancelturfBooking}>Submit</button>
        <button onClick={Close}>Cancel</button>
      </div>
    </div>
  );
};

export default CancelTurfBookingModel;
