import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';
import { apiGet } from '../ApiUrls';
import img from '../Assets/Rectangle 21.svg'

function TurfownerAllTurfs() {

    const navigate = useNavigate()
    const [Allturfs, setAllturfs] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);

    const GetAllTurf = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet('api/turf/GetAllTurfofowner');
            console.log(response.data);
            setAllturfs(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetAllTurf();
    }, []);

    const TurfDetailsdetails = (id) => {
        navigate(`/TurfOwnerTurfDetails/${id}`)
    }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='reg-banner'>
                <div className='reg-banner-div'>
                    <h2>GET Rs 300<br />
                        <span>On 1st Time Registration</span></h2>
                    <br />
                    <button>Register Now</button>
                </div>
            </div>
            <div className='available-venues'>
                <div className='available-venues-div'>
                    <div className='available-venues-heading'>
                        <h2>My Turfs  ({Allturfs?.length || 0})</h2>
                    </div>
                    <br />
                    <div className='filters'>
                        <div class="dropdown-container">
                            <select id="options">
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                                <option value="option4">Option 4</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='game-cards'>
                        {Allturfs?.map((turf, index) => (
                            <div key={index}>
                                <div className='game-card'>
                                    <div style={{ backgroundImage: `url(${turf?.Images[0] || img})` }} className='game-card-img'>
                                    </div>
                                    <br />
                                    <div className='venue-title'>
                                        <h5>{turf?.Name?.slice(0, 30)}</h5>
                                    </div>
                                    <p>{turf?.Location}</p>
                                    <h4>Rs. {turf?.Price}/- Per Hour</h4>
                                    <button onClick={() => { TurfDetailsdetails(turf?._id) }}>Detailed View</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TurfownerAllTurfs