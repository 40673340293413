import React, { useState } from 'react';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
function AddInstitutions() {
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (e) => {
      const files = Array.from(e.target.files);
      const imageUrls = files.map((file) => URL.createObjectURL(file));
      setSelectedImages(imageUrls);
    };
  
    const removeImage = (index) => {
      const newImages = selectedImages.filter((_, i) => i !== index);
      setSelectedImages(newImages);
    };
  return (
    <>
    <Navbar/>
    <div className='addturf-div'>
    <div className='addturf'>
    <h1> Add Institutions</h1>
    <br/>
    <br/>
    <div>
      <div className='addtruf-ips'>
      <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
    <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
      </div>
    <br/>
  
      <div className='addtruf-ips'>
      <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
    <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
  
      </div>
    <br/>
  
      <div className='addtruf-ips'>
      <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
    <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
  
      </div>
    <br/>
  
      <div className='addtruf-ips'>
      <div className='addtruf-ip'>
      <label>Turf Name</label>
      <div>
       
        <input 
          type="file" 
          multiple 
          accept="image/*" 
          onChange={handleImageChange}
        />
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
          {selectedImages.map((image, index) => (
            <div key={index} style={{ position: 'relative', margin: '10px' }}>
              <img
                src={image}
                alt={`Selected ${index + 1}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
              <button
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  background: 'red',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => removeImage(index)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>
  
  </div>
    <div className='addtruf-ip'>
      <label>Turf Name</label>
      <input placeholder='Turf Name'/>
  
  </div>
  
      </div>
      <br/>
      <br/>
      <div className='center-btn'>
          <button>Submit</button>
      </div>
    </div>
  
  </div>
    </div>
    <Footer/>
      
    </>
  )
}

export default AddInstitutions