import React, { useState } from 'react';

const SelectPlayerModel = ({ show, onClose, striker, setstriker, Nonstriker, setNonstriker, Bowler, setBowler, SelectPlayer, inactiveTeam, activeTeam }) => {

    if (!show) return null;


    return (
        <div className="modal-backdrop">
            <div className="modal-content1">
                <h2>Select Players</h2>
                <br />
                <br />
                <label htmlFor="batsman">Striker</label>
                <select
                    id="striker"
                    name="striker"
                    value={striker}
                    onChange={(e) => setstriker(e.target.value)}
                >
                    <option value="">Select Batsman</option>
                    {activeTeam?.batsmen
                        ?.filter((player) => !player.isOut && player?.playerId !== Nonstriker)
                        .map((player) => (
                            <option key={player?.playerId} value={player?.playerId}>
                                {player?.player}
                            </option>
                        ))
                    }
                </select>
                <label htmlFor="batsman">Non-Striker:</label>
                <select
                    id="Non=striker"
                    name="Nonstriker"
                    value={Nonstriker}
                    onChange={(e) => setNonstriker(e.target.value)}
                >
                    <option value="">Select Batsman</option>
                    {activeTeam?.batsmen
                        ?.filter((player) => !player.isOut && player?.playerId !== striker)
                        .map((player) => (
                            <option key={player?.playerId} value={player?.playerId}>
                                {player?.player}
                            </option>
                        ))
                    }
                </select>
                {/* <label htmlFor="bowler">Bowler:</label>
                <select
                    id="bowler"
                    name="Bowler"
                    value={Bowler}
                    onChange={(e) => setBowler(e.target.value)}
                >
                    <option value="">Select Bowler</option>
                    {inactiveTeam?.bowlers?.map((player) => (
                        <option key={player?.playerId} value={JSON.stringify(player)}>
                            {player?.player}
                        </option>
                    ))}
                </select> */}
                <button className='tab-btn active' onClick={() => { SelectPlayer(striker, Nonstriker, Bowler) }}>Submit</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default SelectPlayerModel;
