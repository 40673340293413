import React from 'react';

const UpdateToss = ({ show, onClose, settoss, setbattingFirst, toss, battingFirst, handleTossSubmit }) => {
    if (!show) return null;


    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Toss Update</h2>
                <br />
                <br />
                <label>Toss Winner</label>
                <select
                    value={toss}
                    onChange={(e) => { settoss(e.target.value) }}
                >
                    <option value="None">None</option>
                    <option value="TeamA">Team A</option>
                    <option value="TeamB">Team B</option>
                </select>
                <br />
                <br />
                <label>batting First</label>
                <select
                    value={battingFirst}
                    onChange={(e) => { setbattingFirst(e.target.value) }}
                >
                    <option value="None">None</option>
                    <option value="TeamA">Team A</option>
                    <option value="TeamB">Team B</option>
                </select>
                <br />
                <br />
                <button className='tab-btn active' onClick={handleTossSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default UpdateToss;
