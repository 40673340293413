import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'

function AddSportTurf() {
  return (
    <>
      <Navbar />
      <div className='addturf-div'>
        <div className='addturf'>
          <div className='slot-info'>
            <div className='slot-info1'>
              <p>00:00  - 1:00 Am</p>
              <p>Rs 240</p>
            </div>
            <div className='slot-info3'>
              <button>Add</button>
            </div>
          </div>
          <br />
          <div className='center-btn'>
            <button>Submit</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AddSportTurf