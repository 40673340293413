import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22038.png'
import exp from '../Assets/experience-svgrepo-com 1.png'
import toiletwashroom from '../Assets/toiletwashroom.svg'
import lightbulb from '../Assets/lightbulb.svg'
import room from '../Assets/room.svg'
import waterdrop from '../Assets/waterdrop.svg'
import firstaidkit from '../Assets/firstaidkit.svg'
import couchseats from '../Assets/couchseats.svg'
import cricket from '../Assets/cricket.svg'
import football from '../Assets/football.svg'
import { apiPost, apiPut, Get_Api } from '../ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import AddReview from './add/AddReview';
import { toast } from 'react-toastify';
import Edit from '../Assets/Edit.svg'
import UpdateTurfModel from '../Components/UpdateTurfModel';
import ImageSlider from '../Components/ImageSlider';
import location from '../Assets/location.svg';
import system from '../Assets/system-uicons_location.png';

function TurfOwnerTurfDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);


    // const handleTabChange = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
        document.getElementById(`tab-content-${tabNumber}`)?.scrollIntoView({ behavior: 'smooth' });
    };


    const [Turfdetails, setTurfdetails] = useState();
    const [Turfreview, setTurfreview] = useState([]);
    const [IsUploading, setIsUploading] = useState();

    const GetTurf = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetTurf/${id}`);
            setTurfdetails(response.data?.Getturf);
            setTurfreview(response.data?.reviews);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetTurf();
    }, [id]);

    const AllSlots = (id) => {
        navigate(`/TurfownerAvailableslots/${id}`)
    }
    const AllBookings = (id) => {
        navigate(`/AllBookingOfATurf/${id}`)
    }


    // =================[Update Turf ] ======================

    const [UpdateTurfModal, setUpdateTurfModal] = useState(false);

    const [Name, setName] = useState('');
    const [Dimension, setDimension] = useState('');
    const [Location, setLocation] = useState('');
    const [FaceBookLink, setFaceBookLink] = useState('');
    const [InstagramLink, setInstagramLink] = useState('');
    const [TwitterLink, setTwitterLink] = useState('');
    const [Price, setPrice] = useState('');
    const [About, setAbout] = useState();
    const [AvailableSports, setAvailableSports] = useState();
    const [VenueRules, setVenueRules] = useState();
    const [latitude, setlatitude] = useState();
    const [longitude, setlongitude] = useState();
    const [position, setPosition] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [Images, setImages] = useState([]);
    const [Amenities, setAmenities] = useState({
        changingRoom: false,
        Drinkingwater: false,
        FirstAid: false,
        Floodlight: false,
        seatingLounge: false,
        washroom: false
    });

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        setSelectedImages(imageUrls);
        setImages(files);
    };

    const removeImage = (index) => {
        const newImages = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(newImages);
    };



    const OpenupdateTurf = () => {
        setName(Turfdetails?.Name)
        setDimension(Turfdetails?.Dimension)
        setLocation(Turfdetails?.Location)
        setFaceBookLink(Turfdetails?.FaceBookLink)
        setInstagramLink(Turfdetails?.InstagramLink)
        setTwitterLink(Turfdetails?.TwitterLink)
        setPrice(Turfdetails?.Price)
        setAbout(Turfdetails?.About)
        setAvailableSports(Turfdetails?.AvailableSports)
        setVenueRules(Turfdetails?.VenueRules)
        setAmenities(Turfdetails?.Amenities)
        setlatitude(Turfdetails?.latitude)
        setlongitude(Turfdetails?.longitude)
        setUpdateTurfModal(true)
    }

    const CloseModal = () => {
        setUpdateTurfModal(false)
    }

    // const UpdateTurf = async () => {
    //     try {
    //         const token = localStorage.getItem("userToken");

    //         if (!token) {
    //             toast.error('Please Login First....');
    //             return
    //         }

    //         let data = {
    //             Name: Name,
    //             Dimension: Dimension,
    //             Location: Location,
    //             FaceBookLink: FaceBookLink,
    //             InstagramLink: InstagramLink,
    //             TwitterLink: TwitterLink,
    //             Price: Price,
    //             About: About,
    //             AvailableSports: AvailableSports,
    //             latitude: latitude,
    //             longitude: longitude,
    //             VenueRules: VenueRules,
    //             Amenities: JSON.stringify(Amenities)
    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/turf/UpdateTurf/${id}`, data);
    //         console.log(response?.data)
    //         setName('')
    //         setDimension('')
    //         setLocation('')
    //         setFaceBookLink('')
    //         setInstagramLink('')
    //         setTwitterLink('')
    //         setPrice('')
    //         setIsUploading(false);
    //         toast.success(response?.message)
    //         CloseModal();
    //         GetTurf();
    //     } catch (error) {
    //         toast.error(error?.response?.message)
    //         console.error('Error fetching data:', error);
    //     }
    // }
    const UpdateTurf = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return;
            }

            const formData = new FormData();

            formData.append("Name", Name);
            formData.append("Dimension", Dimension);
            formData.append("Location", Location);
            formData.append("FaceBookLink", FaceBookLink);
            formData.append("InstagramLink", InstagramLink);
            formData.append("TwitterLink", TwitterLink);
            formData.append("Price", Price);
            formData.append("About", About);
            formData.append("AvailableSports", AvailableSports);
            formData.append("latitude", latitude);
            formData.append("longitude", longitude);
            formData.append("VenueRules", VenueRules);
            formData.append("Amenities", JSON.stringify(Amenities));
            Images.forEach((image, index) => {
                formData.append(`Images`, image);
            });
            setIsUploading(true);

            const response = await apiPut(`api/turf/UpdateTurf/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log(response?.data);
            setName('');
            setDimension('');
            setLocation('');
            setFaceBookLink('');
            setInstagramLink('');
            setTwitterLink('');
            setPrice('');
            setIsUploading(false);
            toast.success(response?.message);
            CloseModal();
            GetTurf();
        } catch (error) {
            toast.error(error?.response?.message);
            console.error('Error fetching data:', error);
        }
    };


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    const Openmap = (latitude, longitude) => {

        if (latitude && longitude) {
            const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

            window.open(mapUrl, '_blank');
        } else {
            alert("Location coordinates are not available.");
        }
    };



    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <div className='venue-detail'>
                            <div className='venue-info'>
                                {/* <img src={Turfdetails?.profileimage ? img : img} /> */}
                                <ImageSlider images={Turfdetails?.Images.length > 0 ? Turfdetails?.Images : [img]} />
                                <div className='venue-text'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className='venue-text-heading'>
                                            <h2>{Turfdetails?.Name}</h2>
                                            <div className='green-review'>
                                                <p>4.5 <i className="bi bi-star-fill"></i></p>
                                            </div>
                                            <h4>({Turfreview?.length || 0} reviews)</h4>
                                        </div>
                                        <div>
                                            <img onClick={() => { OpenupdateTurf() }} src={Edit} alt="Edit" />
                                        </div>
                                    </div>
                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <p onClick={() => { Openmap(Turfdetails?.latitude, Turfdetails?.longitude) }}>{Turfdetails?.Location} <img src={system} className='sport-icon' style={{ marginLeft: '8px', width: 25, height: 25 }} /></p>
                                            <p><img src={exp} />Dimensions : {Turfdetails?.Dimension}</p>

                                        </div>
                                        <button onClick={() => { AllSlots(Turfdetails?._id) }}>Show Slots</button>
                                    </div>
                                    <div className='venue-text-heading3'>
                                        <h3>Rs {Turfdetails?.Price}/- Slot</h3>
                                        <div className='clock'>

                                            <i className="bi bi-clock"></i>
                                            <p>6:00 Am -  11:59 Pm</p>
                                        </div>
                                    </div>
                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                        </div>
                                        <button onClick={() => { AllBookings(Turfdetails?._id) }}>Show Bookings</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="tab-container">
                            <div className="tabs">
                                <button
                                    className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(1)}
                                >
                                    About
                                </button>
                                <button
                                    className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(2)}
                                >
                                    Available Sports
                                </button>

                                <button
                                    className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(3)}
                                >
                                    Amenities
                                </button>
                                <button
                                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(5)}
                                >
                                    Rules
                                </button>
                                <button
                                    className={activeTab === 6 ? 'tab-btn active' : 'tab-btn'}
                                    onClick={() => handleTabChange(6)}
                                >
                                    Review
                                </button>

                            </div>
                            <br />
                            <br />
                            <div className="tab-content">
                                <div className='tab1'>
                                    <div id="tab-content-1" >
                                        <h3>About</h3>
                                        <p>{Turfdetails?.About}</p>
                                    </div>
                                    <br />
                                    <div id="tab-content-2" >
                                        <h3>Available Sports</h3>
                                        <br />
                                        <div className='Availablesport-btn'>
                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', padding: '5px' }}>
                                                <img src={cricket} style={{ height: 20, width: 20 }} />
                                                Box Cricket
                                            </button>
                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', padding: '5px' }}>
                                                <img src={football} style={{ height: 20, width: 20 }} />
                                                Football
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-3" >
                                        <h3>Amenities</h3>
                                        <br />
                                        <div className='Amenities-btn'>
                                            {Turfdetails?.Amenities?.changingRoom &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={room} style={{ height: 60, width: 60 }} />
                                                    <span style={{ marginTop: 10 }}>Changing Room</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.Drinkingwater &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={waterdrop} style={{ height: 60, width: 60 }} />
                                                    <span style={{ marginTop: 10 }}>Drinking Water</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.FirstAid &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={firstaidkit} style={{ height: 60, width: 60 }} />
                                                    <span style={{ marginTop: 10 }}>First Aid</span>
                                                </button>
                                            }
                                        </div>
                                        <br />
                                        <div className='Amenities-btn'>
                                            {Turfdetails?.Amenities?.Floodlight &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={lightbulb} style={{ height: 60, width: 60 }} />
                                                    <span style={{ marginTop: 10 }}>Flood Light</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.seatingLounge &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={couchseats} style={{ height: 60, width: 60 }} />
                                                    <span style={{ marginTop: 10 }}>Seating Lounge</span>
                                                </button>
                                            }
                                            {Turfdetails?.Amenities?.washroom &&
                                                <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                                                    <img src={toiletwashroom} style={{ height: 60, width: 60 }} />
                                                    <span style={{ marginTop: 10 }}>Washroom</span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div id="tab-content-5">
                                        <h3>Venue Rules</h3>
                                        <br />
                                        <ul>
                                            <li>{Turfdetails?.VenueRules}</li>
                                        </ul>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div id="tab-content-6" >
                                    <div className="tab-content">
                                        <div className='tab1'>
                                            <h3>Reviews</h3>
                                            <p>★★★★★ 4.5 ({Turfreview?.length || 0} reviews)</p>
                                            {Turfreview?.length === 0 &&
                                                <p>Be the first to review</p>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    {Turfreview?.slice(0, 5)?.map((slot, index) => (
                                        <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                <img
                                                    src={img}
                                                    alt="User Profile"
                                                    style={{ height: 100, width: 100, borderRadius: '50%', marginRight: '20px' }}
                                                />
                                                <div>
                                                    <div style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'Oxanium, sans-serif' }}>
                                                        {slot?.User?.Name}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {Array.from({ length: slot?.Rating }, (_, i) => (
                                                            <span key={i} style={{ color: '#FFD700', marginRight: '5px' }}>★</span>
                                                        ))}
                                                    </div>
                                                    <div style={{ marginTop: '10px', fontSize: '20px', color: '#555', fontFamily: 'Oxanium, sans-serif' }}>
                                                        {slot?.Review}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <UpdateTurfModel
                            show={UpdateTurfModal}
                            Name={Name}
                            onClose={CloseModal}
                            Dimension={Dimension}
                            Location={Location}
                            FaceBookLink={FaceBookLink}
                            InstagramLink={InstagramLink}
                            TwitterLink={TwitterLink}
                            Price={Price}
                            setName={setName}
                            setDimension={setDimension}
                            setLocation={setLocation}
                            setFaceBookLink={setFaceBookLink}
                            setInstagramLink={setInstagramLink}
                            setTwitterLink={setTwitterLink}
                            setPrice={setPrice}
                            About={About}
                            setAbout={setAbout}
                            AvailableSports={AvailableSports}
                            setAvailableSports={setAvailableSports}
                            VenueRules={VenueRules}
                            setVenueRules={setVenueRules}
                            Amenities={Amenities}
                            setAmenities={setAmenities}
                            longitude={longitude}
                            latitude={latitude}
                            setlongitude={setlongitude}
                            setlatitude={setlatitude}
                            position={position}
                            setPosition={setPosition}
                            Images={Images}
                            setImages={setImages}
                            selectedImages={selectedImages}
                            setSelectedImages={setSelectedImages}
                            handleImageChange={handleImageChange}
                            removeImage={removeImage}
                            UpdateTurf={UpdateTurf}
                        />
                        <Footer />
                    </>
            }
        </>
    )
}

export default TurfOwnerTurfDetails