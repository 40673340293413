import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';
import { Get_Api } from '../ApiUrls';
import img from '../Assets/Rectangle 21.svg'

function Turfvanues() {

    const navigate = useNavigate()
    const [Allturfs, setAllturfs] = useState([]);
    const [IsUploading, setIsUploading] = useState(false);
    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const GetAllTurf = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetAllTurf?location=${city}`);
            setAllturfs(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        if (city !== '') {
            GetAllTurf();
        }
    }, [city]);

    const TurfDetailsdetails = (id) => {
        navigate(`/TurfDetails/${id}`)
    }


    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='reg-banner'>
                <div className='reg-banner-div'>
                    <h2>GET Rs 300<br />
                        <span>On 1st Time Registration</span></h2>
                    <br />
                    <button>Register Now</button>
                </div>
            </div>
            <div className='available-venues'>
                <div className='available-venues-div'>
                    <div className='available-venues-heading'>
                        <h2>Available venues ({Allturfs?.length || 0})</h2>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='game-cards'>
                        {Allturfs?.map((turf, index) => (
                            <div key={index}>
                                <div className='game-card'>
                                    <div style={{ backgroundImage: `url(${turf?.Images[0] || img})` }} className='game-card-img'>
                                    </div>
                                    <br />
                                    <div className='venue-title'>
                                        <h5>{turf?.Name?.slice(0, 30)}</h5>
                                    </div>
                                    <p>{turf?.Location}</p>
                                    <h4>Rs. {turf?.Price}/- Per Hour</h4>
                                    <button onClick={() => { TurfDetailsdetails(turf?._id) }}>Book Now</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Turfvanues