import React from 'react';

const UpdateCoachModel = ({ show, onClose, Name, Location, Game, Email, startTime, endTime, CoachDescription, About, Services, Achievements, setName, setLocation, setGame, setEmail, setstartTime, setendTime, setCoachDescription, setAbout, setServices, setAchievements ,UpdateCoach}) => {
    if (!show) return null;


    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Update Coach</h2>
                <br />
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Name</label>
                            <input
                                placeholder='Name'
                                value={Name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Email</label>
                            <input
                                placeholder='Email'
                                value={Email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Location</label>
                            <input
                                placeholder='Location'
                                value={Location}
                                onChange={(e) => { setLocation(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Game</label>
                            <input
                                placeholder='Game'
                                value={Game}
                                onChange={(e) => { setGame(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className='form-grid'>
                    <div className='form-grid'>
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>startTime</label>
                                <input
                                    placeholder='startTime'
                                    value={startTime}
                                    type='time'
                                    onChange={(e) => { setstartTime(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>endTime</label>
                                <input
                                    placeholder='endTime'
                                    type='time'
                                    value={endTime}
                                    onChange={(e) => { setendTime(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>CoachDescription</label>
                            <input
                                placeholder='CoachDescription'
                                value={CoachDescription}
                                onChange={(e) => { setCoachDescription(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>About</label>
                            <input
                                placeholder='About'
                                value={About}
                                onChange={(e) => { setAbout(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                        <label>Services</label>
                            <input
                                placeholder='Services'
                                value={Services}
                                onChange={(e) => { setServices(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-grid'>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                            <label>Achievements</label>
                            <input
                                placeholder='Achievements'
                                value={Achievements}
                                onChange={(e) => { setAchievements(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className='addtruf-ips'>
                        <div className='addtruf-ip'>
                        </div>
                    </div>
                </div>
                <br />
                <button onClick={() => { UpdateCoach() }}>Submit</button>
            </div>
        </div>
    );
};

export default UpdateCoachModel;
