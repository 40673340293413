import React, { useEffect, useState } from 'react';

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22026.png'

function VenuesDetail() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);

  return (
    <>
      <Navbar city={city} setcity={setcity} />
      <div className='venue-detail'>
        <div className='venue-info'>
          <img src={img} />
          <div className='venue-text'>
            <div className='venue-text-heading'>
              <h2>Sport Turf</h2>
              <div className='green-review'>
                <p>4.7 <i class="bi bi-star-fill"></i></p>
              </div>
              <h4>(650 reviews)</h4>



            </div>

            <div className='venue-text-heading2'>
              <p><i class="bi bi-geo-alt"></i>256, Picnic Gorden Rd, Nator Park, Near Bandel Gate Flyover, Kolkata 700039</p>
              <button>Book Now</button>


            </div>

            <div className='venue-text-heading3'>
              <h3>Rs 240/- per month</h3>
              <div className='clock'>
                <i class="bi bi-clock"></i>
                <p>6:00 Am -  11:59 Pm</p>
              </div>

            </div>
          </div>

        </div>

      </div>

      <div className="tab-container">
        <div className="tabs">
          <button
            className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
            onClick={() => handleTabChange(1)}
          >
            Description
          </button>
          <button
            className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
            onClick={() => handleTabChange(2)}
          >
            Reviews
          </button>

        </div>
        <br />
        <br />
        <div className="tab-content">
          {activeTab === 1 &&
            <div className='tab1'>
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum See more...</p>

              <br />
              <h3>Available Sports</h3>
              <br />
              <div className='Availablesport-btn'>
                <button>Box Cricket</button>
                <button>Football</button>

              </div>
              <br />
              <h3>Amenities</h3>
              <br />
              <br />
              <h3>Venue Rules</h3>
              <br />
              <ul>
                <li>Sport Club provides slots given by the venue and bears no responsibility for usage of the facility that extends past the Government guidelines.</li>
                <li>Sports Club is not responsible for any direct or indirect, for any loss, damage or injury to property or person in connection to the provided services by the facility</li>
                <li>Wear appropriate sports attire and shoes while playing.</li>
                <li>Be present at the venue 10 mins prior to the booked slot.</li>
                <li>Management is not responsible for loss of personal belongings or any injuries caused during the match.</li>


              </ul>

            </div>
          }
          {activeTab === 2 &&
            <>
              3
            </>


          }

        </div>
      </div>

      <Footer />
    </>
  )
}

export default VenuesDetail