import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import { apiGet, apiPost, apiPostFormData } from '../../ApiUrls';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
function AddPrograms() {
    const { id } = useParams();
    const navigate = useNavigate();


    const [ProgramName, setProgramName] = useState('');
    const [Coach, setCoach] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [Enddate, setEnddate] = useState('');
    const [TotalSlots, setTotalSlots] = useState('');
    const [Price, setPrice] = useState('');
    const [KeyFeatures, setKeyFeatures] = useState('');

    const [AllCoach, setAllCoach] = useState([]);


    const GetAllCoach = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet('api/coach/GetAllMyCoach');
            setAllCoach(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetAllCoach()
    }, []);

    const [IsUploading, setIsUploading] = useState(false);

    const Createprogram = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return;
            }
            if (!ProgramName) return toast.error('ProgramName is required');
            if (!Coach) return toast.error('Coach is required');
            if (!StartDate) return toast.error('StartDate select Game');
            // if (!Enddate) return toast.error('Enddate is required');
            if (!TotalSlots) return toast.error('TotalSlots is required');
            if (!Price) return toast.error('Price is required');
            if (!KeyFeatures) return toast.error('KeyFeatures is required');
            setIsUploading(true);
            const data = {
                Institute: id,
                ProgramName,
                Coach,
                StartDate,
                Enddate: StartDate,
                TotalSlots,
                Price,
                KeyFeatures
            }
            const response = await apiPost('api/coach/CreatePrograms', data);
            console.log(response);
            if (response?.statusCode === 200) {
                setProgramName('');
                setCoach('');
                setStartDate('');
                setEnddate('');
                setTotalSlots('');
                setPrice('');
                setKeyFeatures('');
                setIsUploading(false);
                toast.success(response?.message || 'Coach created successfully');
                navigate('/')
            }
            else {
                toast.error(response?.message || 'Coach created successfully');
                setIsUploading(false);
            }
        } catch (error) {
            toast.error(error?.response?.message || 'Error creating Coach');
            setIsUploading(false);
            console.error('Error creating Coach:', error);
        }
    };


    const [city, setcity] = useState('');

        const localcity = localStorage.getItem("city");

        useEffect(() => {
                setcity(localcity||'Indore')
        }, []);


    return (
        <>
            <Navbar city={city} setcity={setcity} />
            <div className='addturf-div'>
                <div className='addturf'>
                    <h1> Add Program</h1>
                    <br />
                    <br />
                    <div>
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>ProgramName</label>
                                <input
                                    placeholder='ProgramName'
                                    value={ProgramName}
                                    onChange={(e) => { setProgramName(e.target.value) }}
                                />
                            </div>
                            <div className='addtruf-ip'>
                                <label>Coach</label>
                                <select
                                    value={Coach}
                                    onChange={(e) => setCoach(e.target.value)}
                                >
                                    <option value="" disabled>Select</option>
                                    {AllCoach?.map((coach) => (
                                        <option key={coach?._id} value={coach?._id}>
                                            {coach?.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>Date</label>
                                <input
                                    placeholder='Date'
                                    type='date'
                                    value={StartDate}
                                    onChange={(e) => { setStartDate(e.target.value) }}
                                />
                            </div>
                            <div className='addtruf-ip'>
                                {/* <label>Enddate</label>
                                <input
                                    placeholder='Enddate'
                                    type='date'
                                    value={Enddate}
                                    onChange={(e) => { setEnddate(e.target.value) }}
                                /> */}
                            </div>

                        </div>
                        <br />

                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>TotalSlots</label>
                                <input
                                    placeholder='TotalSlots'
                                    value={TotalSlots}
                                    onChange={(e) => { setTotalSlots(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                                <label>Price</label>
                                <input
                                    placeholder='Price'
                                    value={Price}
                                    onChange={(e) => { setPrice(e.target.value) }}
                                />
                            </div>

                        </div>
                        <br />
                        <div className='addtruf-ips'>
                            <div className='addtruf-ip'>
                                <label>KeyFeatures</label>
                                <input
                                    placeholder='KeyFeatures'
                                    value={KeyFeatures}
                                    onChange={(e) => { setKeyFeatures(e.target.value) }}
                                />

                            </div>
                            <div className='addtruf-ip'>
                            </div>
                        </div>
                        <br />
                        <div className='center-btn'>
                            {IsUploading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                    <ClipLoader color="#31ED31" size={50} />
                                </div>
                            ) : (
                                <button onClick={() => { Createprogram() }}>Submit</button>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <Footer />

        </>
    )
}

export default AddPrograms