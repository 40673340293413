import React, { useState } from 'react';
import calender from '../../Assets/date-round-svgrepo-com 1.png'
import arrowprev from '../../Assets/arrowprev.svg'
import arrowback from '../../Assets/arrowback.svg'
import dayjs from 'dayjs';

const HorizontalCalendar = ({ handleDayPress }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  console.log(selectedDate)

  const currentMonthStart = currentMonth.startOf('month');
  const nextMonthStart = currentMonthStart.add(1, 'month');
  const allowedEndMonth = nextMonthStart.add(1, 'month').endOf('month');

  const startDate = currentMonth.startOf('month');
  const endDate = currentMonth.endOf('month');

  const handleDayPress1 = (date) => {
    setSelectedDate(date);
    const selectedDateObj = {
      dateString: date.format('DD-MM-YYYY'),
      day: date.date(),
      month: date.month() + 1,
      year: date.year(),
      timestamp: date.valueOf(),
    };
    handleDayPress(selectedDateObj);
  };

  const renderDateItem = (item) => {
    const isSelected = selectedDate.isSame(item, 'day');
    return (
      <button
        onClick={() => handleDayPress1(item)}
        className={`date-box ${isSelected ? 'selected-date-box' : ''}`}
      >
        <div className="date-text">{item.format('ddd')}</div>
        <div className="date-number">{item.date()}</div>
      </button>
    );
  };

  const changeMonth = (direction) => {
    let newMonth;

    if (direction === 'left') {
      if (currentMonth.isBefore(currentMonthStart)) {
        newMonth = currentMonthStart;
      } else {
        newMonth = currentMonth.subtract(1, 'month');
      }
    } else if (direction === 'right') {
      newMonth = currentMonth.add(1, 'month');
      if (newMonth.isAfter(allowedEndMonth)) {
        return;
      }
    }

    setCurrentMonth(newMonth);
  };

  const dates = [];
  for (let i = startDate; i.isBefore(endDate.add(1, 'day')); i = i.add(1, 'day')) {
    dates.push(i);
  }

  return (
    <div className="calendar-container">
      <div className="header">
        <div onClick={() => changeMonth('left')}>
          <img src={arrowback} />
        </div>
        <div className='calander'>
          <div className='calander-heading'>
            <img src={calender} />
            <p>{currentMonth.format('MMMM YYYY')}</p>
          </div>
        </div>
        <div onClick={() => changeMonth('right')}>
          <img src={arrowprev} />
        </div>
      </div>
      <br />
      <br />
      <div className="dates-container">
        {dates?.map((date) => renderDateItem(date))}
      </div>
    </div>
  );
};

export default HorizontalCalendar;
