import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img3 from '../Assets/Rectangle 22027.png'
import loc from '../Assets/system-uicons_location.png'
import date from '../Assets/date-svgrepo-com 5.png'
import pro from '../Assets/Ellipse 6.png'
import time from '../Assets/time-filled-svgrepo-com 1.png'
import { ClipLoader } from 'react-spinners';
import HorizontalCalendar from './add/HorizontalCalendar';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom'
import { apiGet, apiPost, apiPut, formatDate, formatDateonly, Get_Api } from '../ApiUrls'
import { toast } from 'react-toastify'
import AddMoreSlots from '../Components/AddMoreSlots'
import { keyframes } from '@emotion/react'
import CancelprogramBookingModel from '../Components/CancelprogramBookingModel'

function AllBookingsofAInstitute() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [IsUploading, setIsUploading] = useState();
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [InstituteBookings, setInstituteBookings] = useState([]);

    const GetAInstituteBooking = async () => {
        try {
            setIsUploading(true);
            const response = await apiGet(`api/coach/GetAllMyBooking/${id}
                `);
            // ?date=${selectedDate}
            setInstituteBookings(response?.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetAInstituteBooking();
    }, [id]);

    useEffect(() => {
        GetAInstituteBooking();
    }, [selectedDate]);

    const handleDayPress = (date) => {
        const [day, month, year] = date?.dateString.split('-');
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDate(formattedDate);
    };

    const [IsUser, setIsUser] = useState(false);
    const [IsInstitute, setIsInstitute] = useState(false);
    const [IsInstituteOwner, setIsInstituteOwner] = useState(false);


    useEffect(() => {
        const userType = JSON.parse(localStorage.getItem("userType"));

        if (userType === "User") {
            setIsUser(true);
        }
        if (userType === "CoachingInstitute") {
            setIsInstitute(true);
        }
        if (userType === "InstituteOwner") {
            setIsInstituteOwner(true);
        }
    }, []);


    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);

    const [reason, setreason] = useState('');
    const [CancelprogramModel, setCancelprogramModel] = useState(false);
    const [programBookingId, setprogramBookingId] = useState();
    const [refundMessage1, setRefundMessage1] = useState("");
    const [refundAmount, setrefundAmount] = useState();

    const getRefundMessage1 = (DateandTime, price) => {
        const bookingDateTime = new Date(DateandTime);
        const currentTime = new Date();
        const timeDifference = (bookingDateTime - currentTime) / (1000 * 60 * 60);

        let message = "";
        let refund = 0;

        if (timeDifference > 6) {
            message = "A 100% refund will be initiated for cancellations made more than 6 hours before the booked slot.";
            refund = price;
        } else if (timeDifference > 4) {
            message = "Only 70% of the amount will be refunded for cancellations made 4-6 hours before the booked slot.";
            refund = price * 0.7;
        } else if (timeDifference > 2) {
            message = "Only 50% of the amount will be refunded for cancellations made 2-4 hours before the booked slot.";
            refund = price * 0.5;
        } else {
            message = "No refund will be provided for cancellations made within 2 hours of the booked slot.";
            refund = 0;
        }

        setrefundAmount(refund);
        return message
    };

    const OpenCancelprogramBooking = (booking) => {
        setRefundMessage1(getRefundMessage1(booking?.date, booking?.Total));
        setCancelprogramModel(true);
        setprogramBookingId(booking._id);
    };

    const CloseCancelprogramBooking = () => {
        setCancelprogramModel(false)
    }




    const CancelProgramBooking = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            let data = {
                reason,
                refundAmount
            }
            setIsUploading(true);
            const response = await apiPost(`api/coach/cancelProgramBooking/${programBookingId}`, data);
            console.log(response?.data)
            setreason('')
            setIsUploading(false);
            toast.success(response?.message)
            CloseCancelprogramBooking();
            GetAInstituteBooking();
        } catch (error) {
            toast.error(error?.response?.message)
            console.error('Error fetching data:', error);
        }
    }


    return (
        <>
            {
                IsUploading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <ClipLoader color="#31ED31" size={200} />
                    </div>
                    :
                    <>
                        <Navbar city={city} setcity={setcity} />
                        <br />
                        <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
                            <HorizontalCalendar handleDayPress={handleDayPress} />
                        </div>
                        <br />
                        <br />
                        <div className='bookingPadding'>
                            <div className='mybooking-heading'>
                                <h3>Institute All Bookings ({InstituteBookings?.length || 0})</h3>
                            </div>
                            <br />
                            <div className='all-bookings-div'>
                                {InstituteBookings?.map((slot, index) => {
                                    const [day, month, year] = slot?.date?.split('-');
                                    const bookingDate = new Date(`${year}-${month}-${day}`);
                                    const currentDate = new Date();
                                    const isPastBooking = bookingDate < currentDate;
                                    console.log(bookingDate, slot?.date)
                                    return (
                                        <>
                                            <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                                                <img
                                                     className='all-bookingsimg'
                                                    src={slot?.coach?.profileimage ? slot?.coach?.profileimage : img3}
                                                    alt="Coach Profile"
                                                />
                                                <div className="booking-text">
                                                    <h3>Institute - {slot?.Institute?.Name}</h3>
                                                    <h4>Program - {slot?.Program?.ProgramName}</h4>
                                                    <h4>Coach - {slot?.coach?.Name}</h4>
                                                    <p><img src={loc} alt="Location icon" />{slot?.coach?.Location}</p>
                                                    <h4>Rs {Number(slot?.Total || 0) + Number(slot?.ConvenienceFees || 0)}</h4>
                                                    <div className="booking-timing">
                                                        <p><img src={date} alt="Date icon" />{slot?.Program?.StartDate}</p>
                                                        <p><img src={pro} alt="Profile icon" />{!slot?.BookByOwner ? slot?.user?.Name : "Owner Booking"}</p>
                                                    </div>
                                                    <div className="booking-timing">
                                                        <p><img src={time} alt="Time icon" />Booked Date - {formatDate(slot?.createdAt)}</p>
                                                    </div>
                                                    {slot?.reason &&
                                                        <div className='booking-timing'>
                                                            <p>reason - {slot?.reason}  {slot?.IsCanceledbyInstituteOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                                        </div>
                                                    }
                                                    <div style={{ top: 30 }} className="booking-CRUD-btns">
                                                        <button
                                                            className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                                            disabled={isPastBooking || slot?.Cancelrequest !== "Notcreated"}
                                                            onClick={() => { OpenCancelprogramBooking(slot) }}
                                                        >
                                                            {slot?.Cancelrequest === "Notcreated" ? "Cancel Institute Booking" : "Cancel Request Pending"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <CancelprogramBookingModel
                            show={CancelprogramModel}
                            Close={CloseCancelprogramBooking}
                            reason={reason}
                            setreason={setreason}
                            refundMessage={refundMessage1}
                            CancelturfBooking={CancelProgramBooking}
                        />
                        <Footer />
                    </>
            }
        </>
    )
}

export default AllBookingsofAInstitute