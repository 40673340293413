import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import { toast } from 'react-toastify';
import { apiPostFormData, Get_Api } from '../../ApiUrls';
import { ClipLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import SelectLocation from '../../Components/SelectLocation';
import location from '../../Assets/location.svg';
import Select from 'react-select';


function AddTurf() {
  const navigate = useNavigate()
  const [selectedImages, setSelectedImages] = useState([]);
  const [Images, setImages] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [Name, setName] = useState('');
  const [Dimension, setDimension] = useState();
  const [Location, setLocation] = useState();
  const [Price, setPrice] = useState();
  const [Slots, setSlots] = useState([]);
  const [FaceBookLink, setFaceBookLink] = useState('');
  const [InstagramLink, setInstagramLink] = useState('');
  const [TwitterLink, setTwitterLink] = useState('');
  const [Hours, setHours] = useState();
  const [About, setAbout] = useState();
  const [AvailableSports, setAvailableSports] = useState([]);
  const [VenueRules, setVenueRules] = useState();
  const [latitude, setlatitude] = useState();
  const [longitude, setlongitude] = useState();
  const [position, setPosition] = useState(null);
  const [Amenities, setAmenities] = useState({
    changingRoom: false,
    Drinkingwater: false,
    FirstAid: false,
    Floodlight: false,
    seatingLounge: false,
    washroom: false
  });



  // const handleSportsChange = (e) => {
  //   const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
  //   setAvailableSports(selectedOptions.join(', '));
  // };

  
  const handleCheckboxAmenitiesChange = (event) => {
    const { name, checked } = event.target;
    setAmenities((prevAmenities) => ({
      ...prevAmenities,
      [name]: checked,
    }));
  };


  const handleCheckboxChange = (hour) => {
    setHours(hour);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setSelectedImages(imageUrls);
    setImages(files);
  };

  const removeImage = (index) => {
    const newImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(newImages);
  };



  const handleCreateSlots = async () => {
    console.log(Amenities)
    if (!startDate || !endDate || !Hours || timeSlots.length === 0) {
      toast.error("Please fill in all fields and add at least one time slot.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      toast.error("End date must be greater than start date.");
      return;
    }

    const slots = [];

    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      const date = new Date(d).toISOString();

      const dateTimeSlots = timeSlots.flatMap(slot => {
        const { startTime, endTime } = slot;

        if (new Date(`1970-01-01T${endTime}:00`) <= new Date(`1970-01-01T${startTime}:00`)) {
          toast.error("End time must be greater than start time for each time slot.");
          return [];
        }

        return generateHourlySlots(startTime, endTime, slot.Price, Hours);
      });

      slots.push({
        Date: date,
        Times: dateTimeSlots
      });
    }

    const isOverlapping = (newSlot) => {
      return slots.some(slot => {
        return slot.Times.some(existingSlot => {
          return (
            (newSlot.startTime >= existingSlot.startTime && newSlot.startTime < existingSlot.endTime) ||
            (newSlot.endTime > existingSlot.startTime && newSlot.endTime <= existingSlot.endTime)
          );
        });
      });
    };

    setSlots(slots);
  };



  const generateHourlySlots = (startTime, endTime, price, slotDuration) => {
    const slots = [];
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const durationInMs = slotDuration * 60 * 60 * 1000;

    while (start < end) {
      const nextSlot = new Date(start.getTime() + durationInMs);
      if (nextSlot > end) break;

      slots.push({
        startTime: start.toTimeString().slice(0, 5),
        endTime: nextSlot.toTimeString().slice(0, 5),
        Price: price,
      });

      start.setTime(nextSlot.getTime());
    }

    return slots;
  };



  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '', endTime: '', Price: '' }]);
  };

  const handleTimeChange = (index, field, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][field] = value;
    setTimeSlots(newTimeSlots);
  };

  const handleRemoveTimeSlot = (index) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots.splice(index, 1);
    setTimeSlots(newTimeSlots);
  };

  const today = new Date().toISOString().split('T')[0];


  const [IsUploading, setIsUploading] = useState(false);


  const CreateTurf = async () => {
    await handleCreateSlots()
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return;
      }
      if (!Name) return toast.error('Turf Name is required');
      if (!Dimension) return toast.error('Dimension is required');
      if (!Location) return toast.error('Location is required');
      if (!Price) return toast.error('Price is required');
      if (Slots.length === 0) return toast.error('Please add some Slots');
      if (selectedImages.length === 0) return toast.error('Venue Images are required');
      setIsUploading(true);
      const formData = new FormData();
      formData.append('Name', Name);
      formData.append('Dimension', Dimension);
      formData.append('Location', Location);
      formData.append('latitude', latitude);
      formData.append('longitude', longitude);
      formData.append('Price', Price);
      formData.append('FaceBookLink', FaceBookLink);
      formData.append('InstagramLink', InstagramLink);
      formData.append('TwitterLink', TwitterLink);
      formData.append('About', About);
      formData.append('AvailableSports', AvailableSports);
      formData.append('VenueRules', VenueRules);
      formData.append('Amenities', JSON.stringify(Amenities));
      formData.append('Slots', JSON.stringify(Slots));
      Images.forEach((image, index) => {
        formData.append(`Images`, image);
      });
      const response = await apiPostFormData('api/turf/CreateTurf', formData);
      console.log(response);
      setName('');
      setDimension('');
      setLocation('');
      setPrice('');
      setFaceBookLink('')
      setInstagramLink('')
      setTwitterLink('')
      setStartDate('');
      setEndDate('');
      setSlots([]);
      setImages([]);
      setSelectedImages([]);
      setTimeSlots([]);
      setIsUploading(false);
      toast.success(response?.message || 'Turf created successfully');
      navigate('/')
    } catch (error) {
      toast.error(error?.response?.message || 'Error creating turf');
      setIsUploading(false);
      console.error('Error creating turf:', error);
    }
  };


  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);


  const [cities, setcities] = useState([]);

  const [Sports, setSports] = useState([]);

  const getAllCity = async () => {
    try {
      setIsUploading(true);
      const response = await Get_Api(`api/admin/GetAllCity`);
      setcities(response.data);
    } catch (error) {
      toast.error('Please correct the Game ID');
      console.error('Error fetching data:', error);
    } finally {
      setIsUploading(false);
    }
  };
  const getAllSport = async () => {
    try {
      setIsUploading(true);
      const response = await Get_Api(`api/admin/GetAllSports`);
      setSports(response.data);
    } catch (error) {
      toast.error('Please correct the Game ID');
      console.error('Error fetching data:', error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    getAllCity();
    getAllSport();
  }, []);

  const [selectLocation, setselectLocation] = useState(false);

  const OpenLocation = () => {
    setselectLocation(true)
  }

  const CloseLocation = () => {
    setselectLocation(false)
  }

  const handleSportsChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setAvailableSports(selectedValues);
  };

  const sportsOptions = Sports.map((sport) => ({
    value: sport?.Name,
    label: sport?.Name,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: '47px', 
     }),
  };


  return (
    <>
      <Navbar city={city} setcity={setcity} />
      <div className='addturf-div'>
        <div className='addturf'>
          <h1> Add Turf</h1>
          <br />
          <br />
          <div>
            <div className='addtruf-ips'>
              <div className='addtruf-ip'>
                <label>Turf Name</label>
                <input
                  placeholder='Turf Name'
                  value={Name}
                  onChange={(e) => { setName(e.target.value) }}
                />

              </div>
              <div className='addtruf-ip'>
                <label>Dimension</label>
                <input
                  placeholder='Dimension'
                  value={Dimension}
                  onChange={(e) => { { setDimension(e.target.value) } }}
                />
              </div>
            </div>
            <br />
            <div className='addtruf-ips'>
              <div className='addtruf-ip'>
                <label>Location</label>
                <select value={Location} onChange={(e) => setLocation(e.target.value)}>
                  <option value="Select a city" defaultValue >Select a city</option>
                  {cities.map((city, index) => (
                    <option key={index} value={city?.Name}>
                      {city?.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='addtruf-ip'>
                <label>Price</label>
                <input
                  placeholder='Price'
                  value={Price}
                  onChange={(e) => (setPrice(e.target.value))}
                />
              </div>
            </div>
            <br />
            <div className='addtruf-ips'>
              <div className='addtruf-ip'>
                <label>About</label>
                <input
                  placeholder='About'
                  value={About}
                  onChange={(e) => setAbout(e.target.value)}
                />
              </div>
              {/* <div className='addtruf-ip'>
                <label>Available Sports</label>
                <select multiple={true} onChange={handleSportsChange}>
                  {Sports.map((sport, index) => (
                    <option key={index} value={sport?.Name}>
                      {sport?.Name}
                    </option>
                  ))}
                </select>
                <p>Selected Sports: {AvailableSports}</p>
              </div> */}
              <div className="addtruf-ip">
                <label>Available Sports</label>
                <Select
                  options={sportsOptions} 
                  isMulti 
                  onChange={handleSportsChange} 
                  placeholder="Select available sports"
                  className="sports-dropdown" 
                  classNamePrefix="sports-select" 
                  styles={customStyles}
                />
              </div>
            </div>
            <br />
            <div className='addtruf-ips'>
              <div className='addtruf-ip'>
                <label>VenueRules</label>
                <input
                  placeholder='VenueRules'
                  value={VenueRules}
                  onChange={(e) => setVenueRules(e.target.value)}
                />
              </div>
              <div className='addtruf-ip'>
                <label>Twitter Link</label>
                <input
                  placeholder='Twitter Link'
                  value={TwitterLink}
                  onChange={(e) => setTwitterLink(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div className='addtruf-ips'>
              <div className='addtruf-ip'>
                <label>FaceBook Link</label>
                <input
                  placeholder='FaceBook Link'
                  value={FaceBookLink}
                  onChange={(e) => setFaceBookLink(e.target.value)}
                />
              </div>
              <div className='addtruf-ip'>
                <label>Instagram Link</label>
                <input
                  placeholder='Instagram Link'
                  value={InstagramLink}
                  onChange={(e) => (setInstagramLink(e.target.value))}
                />
              </div>
            </div>

            <br />
            <br />
            <label>Select Amenities</label>
            <br />
            <br />
            <br />
            <div className="addtruf-ips" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {Object.keys(Amenities).map((amenity) => (
                <label key={amenity} style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name={amenity}
                    checked={Amenities[amenity]}
                    onChange={handleCheckboxAmenitiesChange}
                  />
                  <span style={{ marginLeft: '5px' }}>
                    {amenity.charAt(0).toUpperCase() + amenity.slice(1).replace(/([A-Z])/g, ' $1')}
                  </span>
                </label>
              ))}
            </div>
            {/* <div className='addtruf-ips'>
              <div className=''>
                <label>Amenities</label>
                <div className="checkbox-row">
                  {Object.keys(Amenities).map((amenity) => (
                    <div key={amenity}
                      className="checkbox-container"
                    >
                      <label>
                        <input
                          type='checkbox'
                          name={amenity}
                          checked={Amenities[amenity]}
                          onChange={handleCheckboxAmenitiesChange}
                          className='small-checkbox'
                        />
                        {amenity.charAt(0).toUpperCase() + amenity.slice(1).replace(/([A-Z])/g, ' $1')}
                      </label>
                    </div>
                  ))}

                </div>
              </div>
            </div> */}
            <br />
            <br />
            <br />
            <div>
              <h4 onClick={OpenLocation} style={{ display: 'flex', alignItems: 'center' }}>
                Select a Location on the Map
                <img src={location} className='sport-icon' style={{ marginLeft: '8px' }} />
              </h4>
              <br />
              <br />
              <div>
                <p>Latitude: {latitude}</p>
                <p>Longitude: {longitude}</p>
              </div>
            </div>
            <br />
            <br />
            <div className='addtruf-ips'>
              <div className='addtruf-ip'>
                <label>Select Turf Images</label>
                <div>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {selectedImages.map((image, index) => (
                      <div key={index} style={{ position: 'relative', margin: '10px' }}>
                        <img
                          src={image}
                          alt={`Selected ${index + 1}`}
                          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        />
                        <button
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                          }}
                          onClick={() => removeImage(index)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="addtruf-ips">
              <div className='addtruf-ip'>
                <label>Select Date range</label>
                <br />
                <div className="addtruf-ips">
                  <div className='addtruf-ip'>
                    <label>Start Date</label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      min={today}
                    />
                  </div>
                  <div className='addtruf-ip'>
                    <label>End Date</label>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      min={today}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <label>Select Hours</label>
            <br />
            <br />
            <div className="addtruf-ips" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {[0.5, 1, 1.5, 2, 3].map((time) => (
                <label key={time} style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={Hours === time}
                    onChange={() => handleCheckboxChange(time)}
                  />
                  <span style={{ marginLeft: '5px' }}>
                    {time === 0.5 ? '30 mins' : `${time} Hour${time > 1 ? 's' : ''}`}
                  </span>
                </label>
              ))}
            </div>
            <br />
            <br />
            <label>Select Time range (24 hr format)</label>
            <br />
            <br />
            {timeSlots?.map((timeSlot, index) => (
              <div className='addtruf-ips' key={index}>
                <div className='addtruf-ip'>
                  <label>Start Time</label>
                  <input
                    type="time"
                    value={timeSlot.startTime}
                    onChange={(e) => handleTimeChange(index, 'startTime', e.target.value)}
                  />
                </div>
                <div className='addtruf-ip'>
                  <label>End Time</label>
                  <input
                    type="time"
                    value={timeSlot.endTime}
                    onChange={(e) => handleTimeChange(index, 'endTime', e.target.value)}
                  />
                </div>
                <div className='addtruf-ip'>
                  <label>Price</label>
                  <input
                    type="number"
                    value={timeSlot.Price}
                    onChange={(e) => handleTimeChange(index, 'Price', e.target.value)}
                  />
                </div>
                <div className='delete-btn'>
                  <button onClick={() => handleRemoveTimeSlot(index)}>X</button>
                </div>
              </div>
            ))}
            <br />
            <button className='add-slot-btn' onClick={handleAddTimeSlot}>Add Time Slot</button>
            <br />
            <br />
            <br />
            {IsUploading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                <ClipLoader color="#31ED31" size={50} />
              </div>
            ) : (
              <button onClick={() => { CreateTurf() }}>Submit</button>
            )}
          </div>
        </div>
      </div>
      <SelectLocation
        isOpen={selectLocation}
        setIsOpen={setselectLocation}
        latitude={latitude}
        longitude={longitude}
        position={position}
        setlatitude={setlatitude}
        setlongitude={setlongitude}
        setPosition={setPosition}
      />
      <Footer />

    </>
  )
}

export default AddTurf