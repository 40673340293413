import React, { useState, useEffect } from 'react';

const AddMoreSlots = ({ show, Slots, setUpdatedSlots, UpdateAddSlot }) => {
    const [timeSlots, setTimeSlots] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [Hours, setHours] = useState();

    const handleCheckboxChange = (hour) => {
        setHours(hour);
    };


    useEffect(() => {
        if (Slots.length > 0) {
            const latestSlotDate = Slots.reduce((latest, current) => {
                return new Date(current.Date) > new Date(latest.Date) ? current : latest;
            }, Slots[0]).Date;

            const nextDay = new Date(new Date(latestSlotDate).setDate(new Date(latestSlotDate).getDate() + 1));
            setMinDate(nextDay.toISOString().split('T')[0]);
        }
    }, [Slots]);

    const handleCreateSlots = async () => {
        if (!startDate || !endDate || timeSlots.length === 0) {
            alert("Please fill in all fields and add at least one time slot.");
            return [];
        }

        const start = new Date(startDate);
        const end = new Date(endDate);
        if (start > end) {
            alert("End date must be greater than start date.");
            return [];
        }

        const slots = [];

        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
            const date = new Date(d).toISOString();

            const dateTimeSlots = timeSlots.flatMap(slot => {
                const { startTime, endTime } = slot;

                if (new Date(`1970-01-01T${endTime}:00`) <= new Date(`1970-01-01T${startTime}:00`)) {
                    alert("End time must be greater than start time for each time slot.");
                    return [];
                }

                return generateHourlySlots(startTime, endTime, slot.Price,Hours);
            });

            slots.push({
                Date: date,
                Times: dateTimeSlots
            });
        }

        setUpdatedSlots(slots);
        return slots;
    };


    const generateHourlySlots = (startTime, endTime, price, slotDuration) => {
        const slots = [];
        const start = new Date(`1970-01-01T${startTime}:00`);
        const end = new Date(`1970-01-01T${endTime}:00`);
        const durationInMs = slotDuration * 60 * 60 * 1000;

        while (start < end) {
            const nextSlot = new Date(start.getTime() + durationInMs);
            if (nextSlot > end) break;

            slots.push({
                startTime: start.toTimeString().slice(0, 5),
                endTime: nextSlot.toTimeString().slice(0, 5),
                Price: price,
            });

            start.setTime(nextSlot.getTime());
        }

        return slots;
    };

    const handleAddTimeSlot = () => {
        setTimeSlots([...timeSlots, { startTime: '', endTime: '', Price: '' }]);
    };

    const handleTimeChange = (index, field, value) => {
        const newTimeSlots = [...timeSlots];
        newTimeSlots[index][field] = value;
        setTimeSlots(newTimeSlots);
    };

    const handleRemoveTimeSlot = (index) => {
        const newTimeSlots = [...timeSlots];
        newTimeSlots.splice(index, 1);
        setTimeSlots(newTimeSlots);
    };

    const today = new Date().toISOString().split('T')[0];

    const UpdateSlots = async () => {
        try {
            const createdSlots = await handleCreateSlots();
            console.log(createdSlots)
            if (createdSlots.length > 0) {
                await UpdateAddSlot(createdSlots);
            }
        } catch (error) {
            console.error("Error updating slots:", error);
        }
    };

    if (!show) return null;
    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Add Turf Slots</h2>
                <br />
                <br />
                <div className="addtruf-ips">
                    <div className='addtruf-ip'>
                        <label>Select Date range</label>
                        <br />
                        <div className="addtruf-ips">
                            <div className='addtruf-ip'>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    min={minDate}
                                />
                            </div>
                            <div className='addtruf-ip'>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    min={minDate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <label>Select Hours</label>
                <br />
                <br />
                <div className="addtruf-ips" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    {[1, 2, 3, 4].map((hour) => (
                        <label key={hour} style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                checked={Hours === hour}
                                onChange={() => handleCheckboxChange(hour)}
                            />
                            <span style={{ marginLeft: '5px' }}>{hour} Hour{hour > 1 ? 's' : ''}</span>
                        </label>
                    ))}
                </div>
                <br />
                <br />

                <label>Select Time range (24 hr format)</label>
                <br />
                <br />
                {timeSlots?.map((timeSlot, index) => (
                    <div className='addtruf-ips' key={index}>
                        <div className='addtruf-ip'>
                            <label>Start Time</label>
                            <input
                                type="time"
                                value={timeSlot.startTime}
                                onChange={(e) => handleTimeChange(index, 'startTime', e.target.value)}
                            />
                        </div>
                        <div className='addtruf-ip'>
                            <label>End Time</label>
                            <input
                                type="time"
                                value={timeSlot.endTime}
                                onChange={(e) => handleTimeChange(index, 'endTime', e.target.value)}
                            />
                        </div>
                        <div className='addtruf-ip'>
                            <label>Price</label>
                            <input
                                type="number"
                                value={timeSlot.Price}
                                onChange={(e) => handleTimeChange(index, 'Price', e.target.value)}
                            />
                        </div>
                        <div className='delete-btn'>
                            <button onClick={() => handleRemoveTimeSlot(index)}>X</button>
                        </div>
                    </div>
                ))}
                <br />
                <button className='add-slot-btn' onClick={handleAddTimeSlot}>Add Time Slot</button>
                <br />
                <br />
                <br />
                <button onClick={() => { UpdateSlots() }}>Submit</button>
            </div>
        </div>
    );
};

export default AddMoreSlots;
