import axios from 'axios';
import api from './Api';

const Api_Url = "https://kreedabackend.vercel.app";
// const Api_Url = "http://localhost:8080";

const Get_Api = async (EndPoint) => {
    try {
        const response = await axios.get(`${Api_Url}/${EndPoint}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


const Delete_Api = async (EndPoint) => {
    try {
        const response = await axios.delete(`${Api_Url}/${EndPoint}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting data:', error);
        throw error;
    }
};


const formatDate = (isoString) => {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const formatDateonly = (isoString) => {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

const Update_Api = async (EndPoint, data) => {
    try {
        const response = await axios.put(`${Api_Url}/${EndPoint}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating data:', error);
        throw error;
    }
};
const Create_Api = async (EndPoint, data) => {
    try {
        const response = await axios.post(`${Api_Url}/${EndPoint}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error Creating data:', error);
        throw error;
    }
};


const apiGet = async (url) => {
    try {
        const response = await api.get(url);
        return response?.data;
    } catch (error) {
        if (error?.response) {
            return error.response;
        }
        return error;
    }
};

const apiDelete = async (url) => {
    try {
        const response = await api.delete(url);
        return response?.data;
    } catch (error) {
        if (error?.response) {
            return error.response;
        }
        return error;
    }
};



const apiPost = async (url, data) => {
    try {
        const response = await api.post(url, data);
        return response?.data;
    } catch (error) {
        if (error?.response) {
            return error.response?.data;
        }
        return error;
    }
};
const apiPostFormData = async (url, data) => {
    try {
        const response = await api.post(url, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response?.data;
    } catch (error) {
        if (error?.response) {
            return error.response?.data;
        }
        return error;
    }
};

const apiPut = async (url, data) => {
    try {
        const response = await api.put(url, data);
        return response?.data;
    } catch (error) {
        if (error?.response) {
            return error.response?.data;
        }
        return error;
    }
};

export { Get_Api, Delete_Api, formatDate, formatDateonly, Update_Api, Create_Api, apiGet, apiPost, apiPostFormData, apiPut, apiDelete };
