import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Registration() {

  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);

  return (
    <>
      <Navbar city={city} setcity={setcity} />
      <div className='reg-banner'>
        <div className='reg-banner-div'>
          <h2>GET Rs 300<br />
            <span>On 1st Time Registration</span></h2>
          <br />
          <button>Register Now</button>

        </div>
      </div>
      <div className='available-venues'>
        <div className='available-venues-div'>
          <div className='available-venues-heading'>

            <h2>Coaches (20)</h2>



          </div>
          <br />
          <div className='filters'>
            <div class="dropdown-container">

              <select id="options">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
                <option value="option4">Option 4</option>
              </select>
            </div>
          </div>
          <br />
          <br />

          <div className='game-cards'>
            <div>

              <div className='game-card'>
                <div className='game-card-img'>



                </div>
                <br />
                <div className='venue-title'>
                  <h5>Sport Turf</h5>
                  <h6>Rs. 240/- Per Hour</h6>

                </div>
                <p>Senior Cricket Caoch Delhi 2020, Dwarka, Delhi</p>
                <h4>Rs.1080 / 3 months</h4>
                <button>Book Now</button>

              </div>
            </div>

            <div>

              <div className='game-card'>
                <div className='game-card-img'>



                </div>
                <br />
                <div className='venue-title'>
                  <h5>Sport Turf</h5>
                  <h6>Rs. 240/- Per Hour</h6>

                </div>
                <p>Senior Cricket Caoch Delhi 2020, Dwarka, Delhi</p>
                <h4>Rs.1080 / 3 months</h4>
                <button>Book Now</button>

              </div>
            </div>
            <div>

              <div className='game-card'>
                <div className='game-card-img'>



                </div>
                <br />
                <div className='venue-title'>
                  <h5>Sport Turf</h5>
                  <h6>Rs. 240/- Per Hour</h6>

                </div>
                <p>Senior Cricket Caoch Delhi 2020, Dwarka, Delhi</p>
                <h4>Rs.1080 / 3 months</h4>
                <button>Book Now</button>

              </div>
            </div>
          </div>


        </div>
      </div>
      <Footer />


    </>
  )
}

export default Registration