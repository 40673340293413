import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Rectangle 22038.png'
import exp from '../Assets/experience-svgrepo-com 1.png'
import { apiPost, apiPut, Create_Api, Get_Api } from '../ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import ImageSlider from '../Components/ImageSlider';
function ProgramDetails() {
    const navigate = useNavigate()
    const { id } = useParams();

    const [ProgramDetails, setProgramDetails] = useState();
    const [IsUploading, setIsUploading] = useState();

    const GetAProgramDetails = async () => {
        try {
            setIsUploading(true);
            const response = await Get_Api(`api/user/GetAProgramDetails/${id}`);
            setProgramDetails(response.data);
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        GetAProgramDetails();
    }, [id]);


    const [selectedSlot, setselectedSlot] = useState(0);

    const addSlot = () => {
        setselectedSlot(prevSlot => prevSlot + 1);
    };

    const Removeslot = () => {
        setselectedSlot(prevSlot => (prevSlot > 0 ? prevSlot - 1 : 0));
    };
    const totalprice = selectedSlot * ProgramDetails?.Price
    const ConvenienceFees = 50


    const [IsUser, setIsUser] = useState(false);
    const [IsInstitute, setIsInstitute] = useState(false);
    const [IsTurfOwner, setIsTurfOwner] = useState(false);


    useEffect(() => {
        const userType = JSON.parse(localStorage.getItem("userType"));

        if (userType === "User") {
            setIsUser(true);
        }
        if (userType === "CoachingInstitute") {
            setIsInstitute(true);
        }
        if (userType === "TurfOwner") {
            setIsTurfOwner(true);
        }
    }, []);



    const BookSlots = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }
            if (!IsUser) {
                return toast.error('Please Login As User first....');
            }


            let data = {
                coach: ProgramDetails?.Coach?._id,
                Program: ProgramDetails?._id,
                Total: totalprice,
                ConvenienceFees: ConvenienceFees,
                Sports: 'Cricket',
                slots: selectedSlot
            }
            setIsUploading(true);
            const response = await apiPost(`api/user/bookCoachSlot`, data);
            console.log(response?.data)
            await PhonepeCalling(response?.data?._id)
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // const UpdatepaymentDetails = async (id) => {
    //     try {
    //         let data = {
    //             phonepe_orderID: "orderId12345",
    //             PaymentStatus: true,
    //             status: 'booked',

    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/user/UpdateCoachpaymentStatus/${id}`, data);
    //         console.log(response?.data)
    //         toast.success(response?.message);
    //         setIsUploading(false);
    //         navigate('/')
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message);
    //         console.error('Error fetching data:', error);
    //     }
    // }


    const TurfOwnerBookSlots = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            if (!IsTurfOwner) {
                return toast.error('Please Login As TurfOnwer....');
            }


            let data = {
                coach: ProgramDetails?.Coach?._id,
                Program: ProgramDetails?._id,
                Total: totalprice,
                ConvenienceFees: ConvenienceFees,
                Sports: 'Cricket',
                slots: selectedSlot
            }
            setIsUploading(true);
            const response = await apiPost(`api/turf/bookProgramSlot`, data);
            console.log(response?.data)
            await PhonepeCalling(response?.data?._id)
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // const TurfOwnerUpdatepaymentDetails = async (id) => {
    //     try {
    //         let data = {
    //             phonepe_orderID: "orderId12345",
    //             PaymentStatus: true,
    //             status: 'booked',

    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/turf/UpdateCoachpaymentStatus/${id}`, data);
    //         console.log(response?.data)
    //         toast.success(response?.message);
    //         setIsUploading(false);
    //         navigate('/')
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message);
    //         console.error('Error fetching data:', error);
    //     }
    // }


    const BookselfSlots = async () => {
        try {
            const token = localStorage.getItem("userToken");

            if (!token) {
                toast.error('Please Login First....');
                return
            }

            if (!IsInstitute) {
                return toast.error('Please Login As Institute....');
            }


            let data = {
                coach: ProgramDetails?.Coach?._id,
                Program: ProgramDetails?._id,
                Total: totalprice,
                ConvenienceFees: ConvenienceFees,
                Sports: 'Cricket',
                slots: selectedSlot
            }
            setIsUploading(true);
            const response = await apiPost(`api/coach/SelfbookProgramSlot`, data);
            console.log(response?.data)
            await PhonepeCalling(response?.data?._id)
            setIsUploading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // const UpdateselfpaymentDetails = async (id) => {
    //     try {
    //         let data = {
    //             phonepe_orderID: "orderId12345",
    //             PaymentStatus: true,
    //             status: 'booked',

    //         }
    //         setIsUploading(true);
    //         const response = await apiPut(`api/coach/UpdateCoachpaymentStatus/${id}`, data);
    //         console.log(response?.data)
    //         toast.success(response?.message);
    //         setIsUploading(false);
    //         navigate('/')
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message);
    //         console.error('Error fetching data:', error);
    //     }
    // }

    const [city, setcity] = useState('');

    const localcity = localStorage.getItem("city");

    useEffect(() => {
        setcity(localcity || 'Indore')
    }, []);


    const PhonepeCalling = async (id) => {
        try {
            const userid = localStorage.getItem("userid");
            let data = {
                Userid: userid,
                amount: totalprice + ConvenienceFees,
                merchantUserId: id,
            };
            setIsUploading(true);

            const response = await apiPost(`api/Phonepe/Instiutephoneperequest`, data);
            setIsUploading(false);

            const redirectUrl = response?.data?.data?.instrumentResponse?.redirectInfo?.url;

            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                toast.error("Failed to fetch PhonePe redirect URL");
            }
        } catch (error) {
            setIsUploading(false);
            toast.error(error?.response?.data?.message || "An error occurred");
            console.error("Error initiating payment:", error);
        }
    };


    return (
        <>
            {IsUploading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                    <ClipLoader color="#31ED31" size={200} />
                </div>
                :
                <>
                    <Navbar city={city} setcity={setcity} />
                    <div className='venue-detail'>
                        <div className='venue-info'>
                            {/* <img src={ProgramDetails?.profileimage ? img : img} /> */}
                            <ImageSlider images={ProgramDetails?.Institute?.Images.length > 0 ? ProgramDetails?.Institute?.Images : [img]} />
                            <div className='venue-text'>
                                <div className='venue-text-heading'>
                                    <h2>{ProgramDetails?.Coach?.Name}</h2>
                                    <div className='green-review'>
                                        <p>4.7 <i className="bi bi-star-fill"></i></p>
                                    </div>
                                    <h4>(650 reviews)</h4>
                                </div>

                                <div className='venue-text-heading2'>
                                    <div className='venue-text-heading2-text'>
                                        <p>{ProgramDetails?.CoachingInstitute?.Location}</p>
                                        <p><img src={exp} />Experience : 7 Years</p>

                                    </div>
                                    {/* <button>Book Now</button> */}
                                </div>
                                <div className='venue-text-heading3'>
                                    <h3>Rs {ProgramDetails?.Price}/Slot</h3>
                                    <div className='clock'>

                                        <i className="bi bi-clock"></i>
                                        <p>6:00 Am -  11:59 Pm</p>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className='venue-text-heading3'>
                                    <h3>Total Slots - {ProgramDetails?.TotalSlots}</h3>
                                    <p>(Available Slots - {ProgramDetails?.AvailableSlots})</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tab-container">
                        <div className="tabs">
                            <h3>
                                {ProgramDetails?.ProgramName}
                            </h3>
                        </div>
                        <div className="tabs">
                            <h3 style={{ paddingRight: 150 }}>Institute Name : </h3>
                            <h3 style={{ paddingRight: 150 }}>{ProgramDetails?.CoachingInstitute?.Name}</h3>
                        </div>
                        <div className="tabs">
                            <h3 style={{ paddingRight: 150 }}>Key Features :</h3>
                            <h3 style={{ paddingRight: 150 }}>{ProgramDetails?.KeyFeatures}</h3>
                        </div>
                        <br />
                        <br />
                    </div>
                    <br />
                    <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
                        <div className='tab1'>
                            <h3>Select No of Slots</h3>
                        </div>
                    </div>
                    <div style={{ paddingLeft: 50, paddingRight: 50, marginBottom: 50 }} className="tab-content">
                        <div className="tab1">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 40, marginTop: 20 }}>
                                <button
                                    onClick={Removeslot}
                                    style={{
                                        padding: '20px 40px',
                                        backgroundColor: 'rgba(49, 237, 49, 1)',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: 5
                                    }}
                                >
                                    -
                                </button>
                                <div>
                                    {selectedSlot}
                                </div>
                                <button
                                    onClick={addSlot}
                                    style={{
                                        padding: '20px 40px',
                                        backgroundColor: 'rgba(49, 237, 49, 1)',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: 5
                                    }}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                    {selectedSlot !== 0 && IsUser &&
                        <div className='venue-detail'>
                            <div className='venue-info'>
                                <div className='venue-text'>
                                    <div className='venue-text-heading'>
                                        <h2>Total Charge</h2> :<h2>{totalprice}</h2>
                                    </div>

                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <h2>ConvenienceFees</h2> : <h2>{ConvenienceFees}</h2>
                                        </div>
                                        <button onClick={() => { BookSlots() }}>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {selectedSlot !== 0 && IsTurfOwner &&
                        <div className='venue-detail'>
                            <div className='venue-info'>
                                <div className='venue-text'>
                                    <div className='venue-text-heading'>
                                        <h2>Total Charge</h2> :<h2>{totalprice}</h2>
                                    </div>

                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <h2>ConvenienceFees</h2> : <h2>{ConvenienceFees}</h2>
                                        </div>
                                        <button onClick={() => { TurfOwnerBookSlots() }}>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {selectedSlot !== 0 && IsInstitute &&
                        <div className='venue-detail'>
                            <div className='venue-info'>
                                <div className='venue-text'>
                                    <div className='venue-text-heading'>
                                        <h2>Total Charge</h2> :<h2>{totalprice}</h2>
                                    </div>

                                    <div className='venue-text-heading2'>
                                        <div className='venue-text-heading2-text'>
                                            <h2>ConvenienceFees</h2> : <h2>{ConvenienceFees}</h2>
                                        </div>
                                        <button onClick={() => { BookselfSlots() }}>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <Footer />
                </>
            }
        </>
    )
}

export default ProgramDetails